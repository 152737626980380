import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useShippingUpload = (_shippingObjAndOrderID) => {

    const [status, setStatus] = useState("idle");
    const [data, setData] = useState([]);
    const [error, setError] = useState("");

    const jwt = useSelector(state => state.app.jwt)

    useEffect(() => {

        if (Object.keys(_shippingObjAndOrderID).length > 0) {

            setStatus('loading')

            const uploadShipping = async () => {

                //setTimeout(async ()=>{

                try {

                    const response = await axios.post(
                        `${endpoint}/order/${_shippingObjAndOrderID.ID}/ship`,
                        _shippingObjAndOrderID,
                        { headers: 
                            {
                                Authorization: `Bearer ${jwt}` 
                            }
                        }
                    )

                    setData(response.data);
                    setStatus('done');

                }
                catch(e) {
                    setError('ERROR: ' + JSON.stringify(e));
                    setStatus('done');
                    throw "API ERROR – DATA COULD NOT BE UPLOADED"
                }

                //}, 3000);

            };

            uploadShipping();

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }


    }, [_shippingObjAndOrderID]);

    return { status, data, error };

};

export default useShippingUpload