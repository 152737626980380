import React, { useRef, useState, useEffect, Suspense } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import {
  useTexture,
  Text,
  Decal,
  RenderTexture,
  PerspectiveCamera,
  Sphere,
} from '@react-three/drei'


const itemRotation = {
  Front: [0,0,0],
  Back: [0,Math.PI,0]
}

const facePosition = {
  Front: [0,0,3],
  Back: [0,0,-3]
}


function CustomizedFaces(props) {

  const ballFront = props.ballFront
  const ballBack = props.ballBack
  const allColors = props.allColors

  //ballFrontTextArray = [ALL TEXT ITEMS NOT == ""]

  var returnArr = []

  if (ballFront.Type === 'LOGO') {
    returnArr.push(<LogoFace key={0} src={ballFront.src} face='Front' />)
  }
  if (ballFront.Type === 'TEXT') {
    returnArr.push(<TextFace key={0} allColors={allColors} text={[ballFront.Line1,ballFront.Line2,ballFront.Line3]} color={ballFront.Color} face='Front' />)
  }

  if (ballBack.Type === 'LOGO') {
    returnArr.push(<LogoFace key={1} src={ballBack.src} face='Back' />)
  }
  if (ballBack.Type === 'TEXT') {
    returnArr.push(<TextFace key={1} allColors={allColors} text={[ballBack.Line1,ballBack.Line2,ballBack.Line3]} color={ballBack.Color} face='Back' />)
  }

  return returnArr

}


function TextFace(props) {

  const textIn = props.text
  const color = props.color
  const face = props.face
  const allColors = props.allColors


  var colorConverted = {
      r: parseInt(allColors[color].HexValue.substr(1,2), 16),
      g: parseInt(allColors[color].HexValue.substr(3,2), 16),
      b: parseInt(allColors[color].HexValue.substr(5,2), 16)
  }


  //const colorTHREE = new THREE.Color(`rgb(${colorConverted.r}, ${colorConverted.g}, ${colorConverted.b})`);

  const textInFiltered = textIn.filter((textLine)=>{
    return textLine !== ""
  });

  const lineYPos = [ [0],[0.2,-0.2],[0.4,0,-0.4] ]

  const posArrToUse = lineYPos[textInFiltered.length - 1]

  var returnArr=[];

  textInFiltered.forEach((lineOfText, index)=>{

    const thisFacePos = [facePosition[face][0], posArrToUse[index], facePosition[face][2]]

    returnArr.push(
      <Decal key={index} position={thisFacePos} rotation={itemRotation[face]} scale={[3,1,3]}>
        <meshStandardMaterial roughness={0.6} transparent>
          <RenderTexture attach="map" anisotropy={64}>
            <PerspectiveCamera makeDefault manual aspect={0.9 / 0.25} position={[0, 0, 3]} />
            <Text rotation={[0,0,0]} fontSize={1.1} color={`rgb(${colorConverted.r}, ${colorConverted.g}, ${colorConverted.b})`}>
              {lineOfText}
            </Text>
          </RenderTexture>
        </meshStandardMaterial>
      </Decal>
    )

  })

  return returnArr

/*
  return textIn.forEach((lineOfText, index)=>{
      <><Decal debug position={[0, .4, -3]} rotation={[0, Math.PI, 0]} scale={[3,1,3]}>
        <meshStandardMaterial roughness={0.6} transparent>
          <RenderTexture attach="map" anisotropy={64}>
            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 5]} />
            <PerspectiveCamera makeDefault manual aspect={0.9 / 0.25} position={[0, 0, 3]} />
            <Text rotation={[0, 0, 0]} fontSize={1} color="red">
              BACK LINE 1
            </Text>
            <Sphere args={[3, 64]} />
          </RenderTexture>
        </meshStandardMaterial>
      </Decal>
      <Decal debug position={[0, 0, -3]} rotation={[0, Math.PI, 0]} scale={[3,1,3]}>
        <meshStandardMaterial roughness={0.6} transparent>
          <RenderTexture attach="map" anisotropy={64}>
            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 5]} />
            <PerspectiveCamera makeDefault manual aspect={0.9 / 0.25} position={[0, 0, 3]} />
            <Text rotation={[0, 0, 0]} fontSize={1} color="red">
              LINE 2
            </Text>
            <Sphere args={[3, 64]} />
          </RenderTexture>
        </meshStandardMaterial>
      </Decal>
      <Decal debug position={[0, -0.4, -3]} rotation={[0, Math.PI, 0]} scale={[3,1,3]}>
        <meshStandardMaterial roughness={0.6} transparent>
          <RenderTexture attach="map" anisotropy={64}>
            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 5]} />
            <PerspectiveCamera makeDefault manual aspect={0.9 / 0.25} position={[0, 0, 3]} />
            <Text rotation={[0, 0, 0]} fontSize={1} color="red">
              LINE 3
            </Text>
            <Sphere args={[3, 64]} />
          </RenderTexture>
        </meshStandardMaterial>
      </Decal></>  })
*/

}

/*
  return textIn.forEach((lineOfText, index)=>{
    <Decal key={index} debug position={facePosition[face]} rotation={itemRotation[face]} scale={[3,1,3]}>
      <meshStandardMaterial roughness={0.6} transparent>
        <RenderTexture attach="map" anisotropy={64}>
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} />
          <PerspectiveCamera makeDefault manual aspect={0.9 / 0.25} position={[0, 0, 3]} />
          <Text rotation={[0,0,0]} fontSize={1} color={color}>
            {lineOfText}
          </Text>
          <Sphere args={[3, 64]} />
        </RenderTexture>
      </meshStandardMaterial>
    </Decal>
  })
*/



function LogoFace(props) {

  const imageURL = props.src
  //const imageURL = 'images/3d/kb.jpg'
  
  const face = props.face

  // ImageURL in text was root relative in the public folder, not sure how full URL will work
  const logo = useTexture(imageURL)

  return (
    <Decal position={facePosition[face]} rotation={itemRotation[face]} scale={2.5} map={logo} map-anisotropy={32} />
  )
}



export default CustomizedFaces





