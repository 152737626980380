import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { setAuthenticated } from '../redux/App/app.actions'
import { useDispatch } from "react-redux"

//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useAuthenticator = ({username: _username, password: _password}) => {
    
    const [status, setStatus] = useState("idle")
    const [data, setData] = useState({})
    const [error, setError] = useState("")

    const dispatch = useDispatch()

    const adminGroups = {
        'root': 0,
        'GolftownPromotions': 1,
        'ProductionPartner': 2
    }

    useEffect(() => {

        if (_username != "" && _password != "") {

            setStatus('loading')

            const checkUser = async () => {

                try {

                    const response = await axios.post(
                        `${endpoint}/login`,
                        {username:_username,
                        password: _password }
                    )

                    setData(response.data);
                    dispatch(
                        setAuthenticated(
                            true,                   //isAuthenticated
                            adminGroups[_username], //adminGroup
                            response.data.token     //JWT
                        )
                    )
                    setStatus('done');

                }
                catch(e) {
                    setError('INVALID LOGIN CREDENTIALS');
                    setStatus('error');
                    throw "API ERROR – DATA COULD NOT BE UPLOADED"
                }


                /* FAKE RESPONSE
                setTimeout(async ()=>{
                    if (_username === "UN" && _password === "PW") {
                        dispatch(
                            setAuthenticated(
                                true,           //isAuthenticated
                                0,              //adminGroup
                                "JWT GOES HERE" //JWT
                            )
                        )
                        setStatus('done')
                    } else {
                        setError('BAD LOGIN');
                        setStatus('error');
                    }
                }, 3000);
                */

            };

            checkUser();

        } else {
            setStatus("idle")
            setData({})
            setError("")
        }


    }, [_username, _password]);

    return { status, data, error };

};

export default useAuthenticator;