import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { loadOrdersToState } from '../redux/Console/console.actions'
import { setError, clearError, setLoading, clearLoading } from '../redux/App/app.actions'
import { useNavigate } from "react-router-dom";

//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT


const useOrdersData = () => {

    const [status, setStatus] = useState('loading');
    const [data, setData] = useState([]);
    const [error, setError] = useState("");

    const jwt = useSelector(state => state.app.jwt)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {

        const fetchOrders = async () => {

            dispatch(setLoading())

            try {

                const {data:response} = await axios.get(
                    `${endpoint}/adm/orders`,
                    { headers: 
                        {
                            Authorization: `Bearer ${jwt}` 
                        }
                    }
                )
                
                dispatch(loadOrdersToState(response));
                setStatus('done');

            } catch {

                dispatch( setError( "Could not load orders", 
                  ()=>{
                    // Error Callback
                    dispatch(clearError())
                  } ) )

            }

        };

        fetchOrders()
        .then( dispatch(clearLoading()) )
        .then( navigate(`/console/dashboard/inbound`) )

    }, []);

    return { status, data, error }

};

export default useOrdersData