// helpers/currencyFormatter

export function currencyFormatter(i18n) {

    var currencyFormatter = new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: 'CAD', 
      currencyDisplay: 'narrowSymbol'
    });  

    return currencyFormatter
}