import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './Modal.scss';
import { useTranslation } from 'react-i18next'
import { connect } from "react-redux"

const mapStateToProps = (state) => ({
  errorMessage: state.app.errorMessage,
  errorCallback: state.app.errorCallback,
})


const ErrorModal = (props) => {
    const { t, i18n } = useTranslation()

    const closeBtn = <button className="close" onClick={()=>props.errorCallback()}>&times;</button>;

    return (
        <div>
            <Modal isOpen={props.errorMessage != ''} className={'product-price-modal modal-dialog-centered modal-lg modal-dialog-scrollable'} >
                <ModalHeader close={closeBtn}>
                    {t('OOPS, SOMETHING WENT WRONG')}
                </ModalHeader>
                <ModalBody>
                    <p>
                        {t(props.errorMessage)}
                    </p>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default connect(mapStateToProps)(ErrorModal)