import React, { createRef, useEffect, useState } from 'react'
import ballFront from '../customization/ball-front.png'
import ballBack from '../customization/ball-back.png'
import Button from '../Buttons.js';
import ColorButton from '../customization/ColorButton.js';
import CustomizationTextBox from '../customization/CustomizationTextBox';
import CustomizationLogoBox from '../customization/CustomizationLogoBox';
import CustomizationBoxes from '../customization/CustomizationBoxes';
import ChangeBallBox from '../ChangeBallBox';
import ImgSizeSlider from '../ImgSizeSlider';
import { Link } from 'react-router-dom';
import './EditCustomization.scss';

import Cropper from 'cropperjs';
import "cropperjs/dist/cropper.css";


import { connect, useDispatch   } from "react-redux"
import { lockInText, 
        setColor, 
        setBallTexts,
} from '../../redux/Order/order.actions'

const mapStateToProps = (state) => ({
  currentOrder: state.order,
  currentlyEditing: state.order.currentlyEditing,
  currentItem: state.order.product,
  allColors: state.product.colors,
})


function EditCustomizationText(props) {

  const { 
    changeStep, 
    toggleproductChangeModal, 
    toggleProductPriceModal } = props.events;

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const dispatch = useDispatch();

  const currentOrder = props.currentOrder;
  const currentItem = props.currentItem;
  const currentlyEditing = props.currentlyEditing;
  const allColors = props.allColors;

  const list = createRef();

  function changeColor(color, e) {
    dispatch(setColor(color));
    const li = list.current.querySelectorAll('li');
    for (let i = 0; i < li.length; i++) {
      li[i].classList.remove('active');
      li[i].style.backgroundColor = 'transparent';
    }
    e.currentTarget.classList.add('active');
    e.currentTarget.style.backgroundColor = allColors[color].HexValue;
  }

  //const image = products[currentItem].image;
  return (
    <div className='step-two mb-5 pb-lg-5'>
      <div className='container'>
        <h3 className='customize-title mt-4 mb-4 text-center text-md-start font-weight-bold'>
          <strong>EDIT YOUR CUSTOMIZATION</strong>
        </h3>
        {/* 
          ADD TEXT COMPONENT 
        */}
        <div className="step-two-second row mt-4">
          <div className="col-lg-6">
            <div className='customize-left ms-lg-5 pt-lg-3 position-relative'>
              <img src={currentItem[`${currentlyEditing}Image`]} alt="" />
              <div style={{color: allColors[currentOrder[currentlyEditing].Color].HexValue}} className='ball-text text-center'>
                <p>{currentOrder[currentlyEditing].Line1}</p>
                <p>{currentOrder[currentlyEditing].Line2}</p>
                <p>{currentOrder[currentlyEditing].Line3}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className='customize-right ps-lg-2'>
              <div className="row">
                <div className="col-md-6">
                  <div className="add-text">
                    <h4>Add Text</h4>
                    <input value={currentOrder[currentlyEditing].Line1} name='first' onChange={(e)=>dispatch(setBallTexts(e))} type="text" placeholder='Add Line 1' />
                    <input value={currentOrder[currentlyEditing].Line2} name='second' onChange={(e)=>dispatch(setBallTexts(e))} type="text" placeholder='Add Line 2' />
                    <input value={currentOrder[currentlyEditing].Line3} name='third' onChange={(e)=>dispatch(setBallTexts(e))} type="text" placeholder='Add Line 3' />
                  </div>
                </div>
                <div className="col-md-6 pb-3 mt-4 mt-md-0">
                  <div className="select-color d-flex align-items-center d-sm-block">
                    <h4>Select Colour</h4>
                    <div ref={list} className='d-flex justify-content-between'>
                      <ul className='d-flex d-sm-block'>
                        <ColorButton active={currentOrder[currentlyEditing].Color == 'black' ? "active" : "true" } changeColor={changeColor} color='black' allColors={allColors}  />
                        <ColorButton active={currentOrder[currentlyEditing].Color == 'blue' ? "active" : "true" } changeColor={changeColor} color='blue' allColors={allColors}  />
                        <ColorButton active={currentOrder[currentlyEditing].Color == 'green' ? "active" : "true" } changeColor={changeColor} color='green' allColors={allColors}  />
                      </ul>
                      <ul className='d-flex d-sm-block'>
                        <ColorButton active={currentOrder[currentlyEditing].Color == 'red' ? "active" : "true" } changeColor={changeColor} color='red' allColors={allColors}  />
                        <ColorButton active={currentOrder[currentlyEditing].Color == 'purple' ? "active" : "true" } changeColor={changeColor} color='purple' allColors={allColors}  />
                        <ColorButton active={currentOrder[currentlyEditing].Color == 'orange' ? "active" : "true" } changeColor={changeColor} color='orange' allColors={allColors}  />
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 mt-lg-4 mb-3'>
                  <div className='border-top pt-4'>
                    <button onClick={()=> {
                      dispatch(lockInText());
                      changeStep(3);
                    }} disabled={currentOrder[currentlyEditing].Line1 || currentOrder[currentlyEditing].Line2 || currentOrder[currentlyEditing].Line3 ? false : true} className='button red-button'>Continue</button>
                  </div>
                </div>
              </div>

              <br />
              <ChangeBallBox events={{
                toggleproductChangeModal,
                toggleProductPriceModal
              }} currentItem={currentItem} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EditCustomizationText)