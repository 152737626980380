import React, { createRef, useEffect, useLayoutEffect, useState } from 'react'
import ChangeBallBox from '../ChangeBallBox';
import ImgSizeSlider from '../ImgSizeSlider';
import { Link } from 'react-router-dom';
import './StepTwo.scss';
import { connect, useDispatch } from "react-redux"
import useCropUpload from '../../hooks/useCropUpload'
import useVectorUpload from '../../hooks/useVectorUpload'
import { useTranslation } from 'react-i18next'
import { setError, clearError, setLoading, clearLoading } from '../../redux/App/app.actions'
import { setContentModal } from '../../redux/App/app.actions'
import PageTitle from '../PageTitle'

import {
  setCurrentlyEditing, 
  setCroppedLogoSrc
} from '../../redux/Order/order.actions'

import Cropper from 'cropperjs';
import "cropperjs/dist/cropper.css";

const mapStateToProps = (state) => ({
  currentlyEditing:       state.order.currentlyEditing,
  currentItem:            state.order.product,
  order:                  state.order,
})


function PositionLogo(props) {

  const {  
    currentlyEditing,
    currentItem, 
    order,
    events  } = props;

  const { 
    changeStep, 
    toggleproductChangeModal, 
    toggleProductPriceModal } = events;

  const dispatch = useDispatch();

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [pngLogo, setPngLogo] = useState("");

  const { t, i18n } = useTranslation()

  let logoContinueButton = createRef();
  let checkbox1 = createRef();
  let checkbox2 = createRef();
  let notesbox = createRef();

  // SAMPLE OF HOOK USE AND STATE CONTROL
  const [croppedFileObj, setCroppedFileObj] = useState({file: "", ID: ""});
  const [cropper, setCropper] = useState();
  const {status: cropUploadStatus, data: cropUploadData, error: cropUploadError} = useCropUpload(croppedFileObj)

  useEffect(()=>{

    switch (cropUploadStatus) {

      case 'loading':
        dispatch(setLoading())
      break;

      case 'done':
        dispatch(clearLoading())
        currentlyEditing === 'Front' 
          ? (()=>{
              dispatch(setCurrentlyEditing('Back'))
              changeStep(2) 
            })() 
          : changeStep(3)
      break;
      
      case 'error':
        dispatch(clearLoading())
        dispatch( setError( cropUploadError, 
          ()=>{
            // Error Callback
            dispatch(clearError());
          } ) )
      break;

      default: 

    }

  },[cropUploadStatus])


  useEffect(()=>{

    dispatch(setContentModal({body: <><div dangerouslySetInnerHTML={
                    {__html: t('PANTONE MODAL', {interpolation: {escapeValue: false}})}
                } /></>, title: "Pantone Colours"}))

  },[])


  //var cropper;

  function getRoundedCanvas(sourceCanvas) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();
    return canvas;
  }

  function setCrop() {
    var result = document.getElementById('croppedLogo');

    var croppedCanvas;
    var roundedCanvas;
    var roundedImage;

    // Crop
    croppedCanvas = cropper.getCroppedCanvas();

    // Round
    roundedCanvas = getRoundedCanvas(croppedCanvas);

    // Show
    roundedImage = document.createElement('img');
    roundedImage.src = roundedCanvas.toDataURL();
    
    //Set State in App.js for croppedLogoX [x is 1 or 2 for front or back]
    //dispatch(setCroppedLogoSrc(roundedImage.src));
    setCroppedFileObj({file: roundedImage.src, ID: order[currentlyEditing].ID})
    dispatch(setCroppedLogoSrc(roundedImage.src, notesbox.current.value ));

    //result.innerHTML = '';
    //result.appendChild(roundedImage);
  };

  function changeImgScale(value) {
    cropper.zoom(value)
  }

  useLayoutEffect(()=> {
    if (order[currentlyEditing].logo) {

      const cropperImg = document.getElementById('cropperImage');

      setCropper( new Cropper(cropperImg, {
        aspectRatio: 1 / 1,
        guides: false,
        background: false,
        modal: false,
        moveable: true,
        scaleable: true,
        cropBoxMoveable:false,
        cropBoxResizable:false,
        zoomOnTouch: true,
        minContainerHeight: 234,
        minContainerWidth: 234,
        minCanvasWidth: 10,
        minCanvasHeight: 10,
        minCropBoxWidth: 234,
        minCropBoxHeight: 234,
        //autoCropArea: 1,
        dragMode: 'move',
        crop(event) {
        },
      }) )

    }
  },[order[currentlyEditing].logo]);

  return (
    <div className='step-two mb-5 pb-lg-5'>
      <div className='container'>
        <PageTitle title={t('CUSTOMIZE YOUR BALL')} startover={t('Start Over')} />
        {/* 
          ADD LOGO COMPONENT 
        */}
        <div className="step-two-third row mt-4">
          <div className="col-lg-5">
            <div className='customize-left ms-lg-4 pt-lg-3 position-relative'>
              <img src={currentItem[`${currentlyEditing}Image`]} alt="" />
              <div className='ball-image'>
                {/* THIS IS WHERE THE SCALABLE IMAGE GOES */}
                <div><img src={order[currentlyEditing].logo} alt="" id="cropperImage" /></div>
              </div>
              <ImgSizeSlider changeImgScale={changeImgScale} />
            </div>
          </div>
          <div className="col-lg-7 ps-lg-5">
            <div className='customize-right ps-lg-4'>
              <h4>{t('Adjust Logo')}</h4>
              <p className='text-center text-sm-start'>
                {t('FIT-DESCRIPTION-1')}
              </p>
              <div className='prod-notes-wrapper'>
                <p>{t('Production Notes:')}
                <textarea
                  id='prod-notes-input'
                  placeholder = {t('PantoneWarning')}
                  name='notes'
                  ref={notesbox}

                  //value={}
                  //onChange={}
                  rows={3}
                /></p>
              </div>
              <div className='logo-checkbox-wrapper'>
                <label htmlFor="logo-checkbox" className='d-flex align-items-start'>
                  <input ref={checkbox1} onChange={e => {
                    const button = logoContinueButton.current.querySelector('button');
                      if (e.target.checked) {
                        button.disabled = false
                        button.classList.add('red-button');
                      } else {
                        button.disabled = true
                        button.classList.remove('red-button');
                      }
                  }} type="checkbox" id='logo-checkbox' />
                  <p>{t('VERIFY-OWNERSHIP-1')}</p>
                </label>
              </div>
              <div ref={logoContinueButton} className='text-center text-sm-start'>
                <button onClick={e => {
                  if (!e.target.classList.contains('red-button')) return;
                  var _tempFront = order.Front.Type; 
                  setCrop();
                }} className='logo-continue'>
                  {t('Continue')}
                </button>
              </div>
              <div className='mt-5' style={{
                // display: (order[currentlyEditing].Line1 && order[currentlyEditing].Line2 && order[currentlyEditing].Line3) ? 'block' : 'none'
              }}>
              </div>
              <div className='mt-4'>
                <ChangeBallBox events={{
                  toggleproductChangeModal,
                  toggleProductPriceModal
                }} product={currentItem} />
                { /*<div className='logo position-absolute' id="croppedLogo" style={{mixBlendMode: "multiply"}}>
                  <img src={order.Front.logo} alt='' />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(PositionLogo)
