import './OrderSummary.scss'
import { useTranslation } from 'react-i18next'
import { currencyFormatter } from '../../helpers/currencyFormatter'
import vectorIdentifier from '../../helpers/vectorIdentifier'
import StatusControl from './StatusControl'




function OrderSummary({context, orderObj}) {

    const { t, i18n } = useTranslation()

	return (
		<div className='console-order-summary'>
		    Price/dz: {currencyFormatter(i18n).format( orderObj.Cost.CostPer ) }<br />
			Quantity: {orderObj.Quantity}<br />
			{orderObj.Cost.DigitizationFee ? <>Digitization: {currencyFormatter(i18n).format( orderObj.Cost.DigitizationFee ) }<br /></> : null}
			Subtotal: {currencyFormatter(i18n).format( orderObj.Cost.SubTotal ) }<br />
			Tax: {currencyFormatter(i18n).format( orderObj.Cost.Taxes ) }<br />
			Total: {currencyFormatter(i18n).format( orderObj.Cost.Total ) }<br />
			TD: {orderObj.BamboraPreAuth.ID}<br />

			{	(orderObj.Front.Type === 'LOGO' && !vectorIdentifier(orderObj.Front.OriginalFileName)) || (orderObj.Back.Type === 'LOGO' && !vectorIdentifier(orderObj.Back.OriginalFileName)) ?
				<span className="digitization-flag">Digitization Required<br/></span>
				: null
			}

			{/*<br />LOCAL ID:<br />{orderObj.ID}*/}
			{
				(context === 'INBOUND' || context === 'PAUSED') && orderObj.RiskDecision.Order.Status == 'declined'
				? 
				<div className='riskified_declined'>
					<span className="riskified_status">RISKIFIED {orderObj.RiskDecision?.Order?.Status}</span>
					<span className="riskified_description">{context !== 'PAUSED' ? <p>{orderObj.RiskDecision?.Order?.Description}</p> : null}</span>
				</div>
				: 
				null
			}
			{
				(context === 'INBOUND' || context === 'PAUSED') && orderObj.RiskDecision.Order.Status == ''
				? 
				<div className='riskified_warning'>
					<span className="riskified_status">RISKIFIED PENDING</span>
				</div>
				: 
				null
			}

			<StatusControl orderObj={orderObj} />
		</div>
	)
}

export default OrderSummary
