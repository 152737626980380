import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

//const axios = require('axios')
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useConsolePauseSwitch = (_pauseObj) => {
    
    const [status, setStatus] = useState("idle")
    const [data, setData] = useState([])
    const [error, setError] = useState("")

    const jwt = useSelector(state => state.app.jwt)

    useEffect(() => {

        if (_pauseObj.ID != "") {
            
            setStatus('loading')

            const switchPause = async () => {

                var pauseFormData = new FormData()
                pauseFormData.append('pausenote', _pauseObj.note)

                //setTimeout(async ()=>{

                    try {
 
                        const response = await axios.post(
                            `${endpoint}/adm/orders/${_pauseObj.ID}/pause`,
                            pauseFormData,
                            { headers: 
                                {
                                    Authorization: `Bearer ${jwt}` 
                                }
                            }
                        )

                        setData(response.data)
                        setStatus('done')
                    }
                    catch(e) {
                        setError('Pause could not be switched.')
                        setStatus('error')
                        throw "API ERROR – DATA COULD NOT BE UPLOADED"
                    }
                //}, 3000);

            };

            switchPause()

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }

    }, [_pauseObj.ID])

    return { status, data, error }

};

export default useConsolePauseSwitch