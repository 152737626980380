import React from 'react';
import { Link } from 'react-router-dom';
import './Steps.scss';
import { useTranslation } from 'react-i18next'

function Steps({currentStep,event}) { 
    const { t, i18n } = useTranslation()

    return (
        <div className={`d-flex step-wrapper align-items-center justify-content-between ${i18n.language === 'fr' ? 'fr-bg' : 'en-bg'}`}>
            <h2 className={`text-white font-secondary text-bold ${i18n.language}`}>
                {t('TITLE PART 1')} 
                <br className='d-lg-none' />   
                  {' '} {t('TITLE PART 2')}
            </h2>
            <div className="steps border-horizontal-middle d-none d-md-block">
                <div /*onClick={() => event(1)}*/ className={`no-link ${currentStep === 1 ?'active' : ''}`} to="/">
                    <span>
                        1
                    </span>
                    <span className={`${i18n.language}`}>
                        {t('Select Ball 1')}
                    </span>
                    <span className={`${i18n.language}`}>
                        {t('Select Ball 2')}
                    </span>
                </div>
                <div /*onClick={() => event(2)}*/ className={`no-link ${currentStep === 2 ?'active' : ''}`} to="step-2">
                    <span>
                        2
                    </span>
                    <span className={`${i18n.language}`}>
                        {t('Customize Ball 1')}
                    </span>
                    <span className={`${i18n.language}`}>
                        {t('Customize Ball 2')}
                    </span>
                </div>
                <div /*onClick={() => event(3)}*/ className={`no-link ${currentStep === 3 ?'active' : ''}`} to="step-3">
                    <span>
                        3
                    </span>
                    <span className={`${i18n.language}`}>
                        {t('Complete Order 1')}
                    </span>
                    <span className={`${i18n.language}`}>{t('Complete Order 2')}</span>
                </div>
            </div>
        </div>
    )
}

export default Steps