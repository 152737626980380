import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux"

//const axios = require('axios')
import axios from 'axios';

const useCanPostRetreive = (_id) => {

    const [status, setStatus] = useState('loading')
    const [data, setData] = useState([])
    const [error, setError] = useState("")

    useEffect(() => {

        const search = async () => {
                
            try {

                //Make call to Can with text to get addresses array

                const {data:response_retrieve} = await axios.get(
                    `https://ws1.postescanada-canadapost.ca/addresscomplete/interactive/retrieve/v2.11/json3.ws`,
                    { 
                        params: { 
                            provider: "AddressComplete",
                            package: "Interactive",
                            service: "Retrieve",
                            version: "2.11",
                            endpoint: "json3.ws",
                            Key: "DW11-NY86-CJ12-WP84",
                            Id: _id,
                        } 
                    }
                )

                setData(response_retrieve.Items)
                setStatus('done');

            } catch {

                setError("COULD NOT COMPLETE LOOKUP")
                throw "API ERROR – COULD NOT LOOKUP ADDRESS"

            }
            
        }


        setStatus('loading');
        search();

    }, [_id]);

    return { status, data, error }

};

export default useCanPostRetreive