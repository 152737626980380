import { useEffect } from 'react'
import OrderSummary from './OrderSummary'
import print from './../images/icons/print.svg';
import './Invoice.scss'
import { useTranslation } from 'react-i18next'
import { connect } from "react-redux"

const mapStateToProps = (state) => ({
  currentOrder: state.order,
})

function Invoice(props) {

    const { events, currentOrder } = props;

    const { 
    changeStep
    } = events;

    useEffect(()=>{
        // Unload ChangeStep and ThirdStep
        changeStep(0)
    },[])


    const { t, i18n } = useTranslation()

    return (
        <div className="invoice">
            <div className="container mt-4 ">
                <h2 className='text-uppercase text-center text-lg-start'>
                    {t('THANK YOU!')}
                </h2>
                <div className="row mt-4">
                    <div className="col-lg-6 order-1 order-lg-0">
                        <OrderSummary hideEdit={true} showExtra={true} subStepToShow="CHECKOUT-PLACEORDER" />
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className='invoice-right text-center text-lg-start ps-lg-3'>
                            <h4>
                                {t('Your Order Has Been Placed')} {currentOrder.BamboraID ? ` - #${currentOrder.BamboraID}`:null}
                            </h4>
                            <p>
                                {t('THANK-TOY-MESSAGE-1')} 
                            </p>
                        </div>
                        <div className="invoice-right repeat-order-box">
                            <h4>{t('REPEAT-ORDER-HEAD')}</h4>
                            <p>{t('REPEAT-ORDER-BODY')}</p>
                                <button onClick={()=>{
                                    changeStep(999)
                                }} className='button green-button'>
                                    {t('REPEAT-ORDER-BUTTON')}
                                </button>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-6 order-2 mb-4">
                        <div className="print-close-return">
                            <div className="text-center text-lg-start print-part">
                                <button className='d-inline-flex align-items-center print-button' onClick={()=>window.print()}>
                                    <img className='me-2' src={print} alt="" />
                                    <span className='text-docoration-underline text-green'>
                                        {t('Print this page')}
                                    </span>
                                </button>
                            </div>
                            <div className="text-start close-part">
                                <button onClick={()=>{
                                    window.open(`https://www.golftown.com/${i18n.language}-CA/homepage`)
                                }} className='button red-button'>
                                    {t('CLOSE & RETURN TO GOLF TOWN')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(Invoice)