// Order Types

export const DELETE_FACE = 'DELETE_FACE';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_CURRENTLY_EDITING = 'SET_CURRENTLY_EDITING';
export const SET_BALL_TEXTS = 'SET_BALL_TEXTS';
export const LOCK_IN_TEXT = 'LOCK_IN_TEXT';
export const RESET_LOGO_AND_LOGO_NAME_FOR_FACE = 'RESET_LOGO_AND_LOGO_NAME_FOR_FACE';
export const SET_COLOR = 'SET_COLOR';
export const SET_LOGO_AND_LOGO_NAME = 'SET_LOGO_AND_LOGO_NAME';
export const SET_CROPPED_LOGO_SRC = 'SET_CROPPED_LOGO_SRC';
export const SET_SAME_AS_FRONT = 'SET_SAME_AS_FRONT';
export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_VECTOR_LOGO_DETAILS_FOR_FACE = 'SET_VECTOR_LOGO_DETAILS_FOR_FACE';
export const RESET_VECTOR_LOGO_DETAILS_FOR_FACE = 'RESET_VECTOR_LOGO_DETAILS_FOR_FACE';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_BAMBORA_ID = 'SET_BAMBORA_ID';
export const ORDER_RESET_FOR_REPEAT = 'ORDER_RESET_FOR_REPEAT';
