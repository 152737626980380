import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './ContentModal.scss';
import { connect, useDispatch } from "react-redux"
import { setContentModal } from '../../redux/App/app.actions'

const mapStateToProps = (state) => ({
  content: state.app.contentModalContent,
})

const ContentModal = (props) => {

    const dispatch = useDispatch()

    const closeBtn = <button className="close" onClick={()=>dispatch(setContentModal({body:"", title:""}))}>&times;</button>;

    return (
        <div>
            <Modal isOpen={props.content.title !== ''} className={'content-modal modal-dialog-centered modal-lg modal-dialog-scrollable'} >
                <ModalHeader close={closeBtn}>
                </ModalHeader>
                <ModalBody>
                    {props.content.body}
                </ModalBody>
            </Modal>
        </div>
    );
}

export default connect(mapStateToProps)(ContentModal)