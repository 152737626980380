import './PauseControl.scss'
import { useEffect, useState } from 'react'
import { setError, clearError, setLoading, clearLoading } from '../../redux/App/app.actions'
import { Button, Popover, PopoverHeader, PopoverBody, Input } from 'reactstrap'
import { toggleOrderPause } from '../../redux/Console/console.actions'
import { useDispatch } from "react-redux"
import useConsolePauseSwitch from '../../hooks/useConsolePauseSwitch'
import { useForm } from 'react-hook-form'
import { setContentModal } from '../../redux/App/app.actions'

function PauseControl({context:_context, orderObj:_orderObj}) {

	const [pauseObj, setPauseObj] = useState({ID:"",note:""})
	const [popoverOpen, setPopoverOpen] = useState(false)
	const {status: pauseStatus, data: pauseReturn, error: pauseError} = useConsolePauseSwitch(pauseObj)
    const dispatch = useDispatch();
	const { register, watch, handleSubmit, reset, clearErrors, formState: { errors } } = useForm();

	const dateTime = new Date().toLocaleString()

	useEffect(()=>{

	    switch (pauseStatus) {

	      case 'loading':
	        dispatch( setLoading() )
	      break;

	      case 'done':
	        dispatch( clearLoading() )
	        dispatch(toggleOrderPause(_orderObj.ID, pauseObj.note));
	      break;
	      
	      case 'error':
	        dispatch( clearLoading() )
	        dispatch( setError( pauseError, 
	          ()=>{
	            // Error Callback
	            dispatch(clearError());
	          } ) )
	      break;

	      default: 

	    }

	},[pauseStatus])


	function triggerReasonPopover() {
		setPopoverOpen(true)
	}

	function actionPause(_note) {
		setPauseObj({ID: _orderObj.ID, note:_note})
	}

	function onPauseSubmit(data) {
		const dateTime = new Date().toLocaleString()
		setPopoverOpen(false)
		actionPause(`<span class='activity'>${dateTime} - PAUSED</span><br />${data.Reason}<br /><br />${_orderObj.Pausenote}`)
	}

	function unPause() {
		actionPause(`<span class='activity'>${dateTime} - UNPAUSED</span><br /><br />${_orderObj.Pausenote}`)
	}

	return (

      <span className='console-pause-control'>
      	<Button  id={'Popover-' + _orderObj.ID} color={ _orderObj.Paused ? 'success' : 'danger'} outline size="sm" onClick={()=>{
      		_orderObj.Paused 
      			? unPause()
      			: triggerReasonPopover()
      	}}>{ _orderObj.Paused 
      		? 'UNPAUSE' 
      		: 'PAUSE'}</Button>
		{ _orderObj.Paused 
      		? <div className='history-button'><a onClick={()=>{
      			dispatch(setContentModal({body: <div dangerouslySetInnerHTML={{__html: _orderObj.Pausenote}}></div>, title: "Pause History"
      		}))}}>Pause History</a></div>

      		: null}

        {_orderObj.Paused 
        	? null 
        	: <Popover placement='left' isOpen={popoverOpen} target={'Popover-' + _orderObj.ID} toggle={()=>triggerReasonPopover()} className='pause-popover'>
					<PopoverHeader>
					Reason for Pausing <button className="cancel" onClick={()=>setPopoverOpen(false)}>X</button></PopoverHeader>
					<PopoverBody>
					<form onSubmit={handleSubmit(onPauseSubmit)} className='console-pause-popover'>
						<input {...register("Reason", { required: true })} placeholder='Type the reason here' className='w-100 mb-1 form-control'/>
						<span className="error">{errors.Reason?.type === 'required' && "Reason is required"}</span>
						<button type="submit" className='button red'>PAUSE ORDER</button>
					</form>
					</PopoverBody>
                </Popover>
        }
      </span>

	)

}

export default PauseControl
