import edit from './../../images/icons/edit-line.svg';
import deleteIcon from './../../images/icons/trash.svg';
import lock from './../../images/icons/lock.svg';
import CustomizationTextBox from '../customization/CustomizationTextBox';
import BuildSummaryHead from '../BuildSummaryHead/BuildSummaryHead';
import CustomizationBoxes from '../customization/CustomizationBoxes';
import ChangeBallBox from '../ChangeBallBox';
import ViewFrontBack from '../ViewFrontBack';
import PricingTable from '../PricingTable';
import angleRight from './../../images/icons/arrow-right.svg';
import InputBox from '../InputBox';
import OrderSummary from '../OrderSummary';
import Address from '../Address';
import { useRef, useState, useEffect } from 'react';
import { BsCheckLg } from 'react-icons/bs'
import './StepThree.scss';
import BillingForm from './BillingForm';
import ShippingForm from './ShippingForm';
import BillingAddress from './BillingAddress';
import ShippingAddress from './ShippingAddress';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../../helpers/currencyFormatter'
import { orderLineItems } from '../../helpers/orderLineItems'
import useOrderCreate from '../../hooks/useOrderCreate'
import usePlaceOrder from '../../hooks/usePlaceOrder'
import { setError, clearError, setLoading, clearLoading } from '../../redux/App/app.actions'
import { setBamboraID } from '../../redux/Order/order.actions'
import PreviewPrintLOCALContainer from '../../console/parts/PreviewPrintLOCALContainer'
import { setContentModal } from '../../redux/App/app.actions'
import { FaPrint } from "react-icons/fa";
import PageTitle from '../PageTitle'

import { connect, useDispatch   } from "react-redux"
import { 
  setCurrentlyEditing,
  setQuantity,
  setOrderID, } from '../../redux/Order/order.actions'

const mapStateToProps = (state) => ({
  currentItem: state.order.product,
  ballFront: state.order.Front,
  ballBack: state.order.Back,
  quantity: state.order.Quantity,
  order: state.order,
  shipping : state.shipping
})


function StepThree(props) {

  /* SubSteps:
      COMPLETE-ORDER
      CHECKOUT-SHIPPING 
      CHECKOUT-BILLING 
      CHECKOUT-PLACEORDER 
      INVOICE 
  */


  const [subStepToShow, setSubStepToShow] = useState('COMPLETE-ORDER');

  const ballFront = props.ballFront;
  const ballBack = props.ballBack;
  const currentOrder = props.order
  const currentItem = props.currentItem
  const currentShipping = props.shipping
  const orderLineItemsObj = orderLineItems(currentItem, currentOrder, currentShipping)

  useEffect(()=>{
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  },[subStepToShow])

  const { 
    //croppedLogo1src, 
    //croppedLogo2src, 
    //creditCardInfo, 
    //billingAddress, 
    //shippingAddress, 
    //sameAsShipping, 
    orderCompleted, 
    //products, 
    //customizations, 
    //showAddTextColor,
  } = props.props

  const order = props.order


  const quantity = props.quantity
  const [inputQuantity, setInputQuantity] = useState(12);
  const [debounceDelay, setDebounceDelay] = useState(5000);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputQuantity >= 12) {
        dispatch(setQuantity(inputQuantity));
      } else {
          /* DISABLED ERROR IN QUANTITY CHANGE
          dispatch( setError( t("Minimum quantity 12 dozen."), 
          ()=>{
              dispatch(clearError());
              setInputQuantity(quantity)
          } ) ) */
      }
    }, debounceDelay)

    return () => clearTimeout(timer)
  }, [inputQuantity])


  const { 
    //setLogoUpload, 
    changeStep, 
    //setTextEdit, 
    setOrderCompleted, 
    toggleproductChangeModal, 
    toggleProductPriceModal,
    //setLogoAndLogoName,
    //resetLogoAndLogoNameForFace,
    //deleteFace
  } = props.events;

  const dispatch = useDispatch();

  const [orderObj, setOrderObj] = useState({});
  const {status: orderCreateStatus, data: orderCreateData, error: orderCreateError} = useOrderCreate(orderObj)

  useEffect(()=>{

    switch (orderCreateStatus) {

      case 'loading':
        dispatch( setLoading() )
      break;

      case 'done':
        dispatch( clearLoading() )
        dispatch(setOrderID(orderCreateData.ID))
        setSubStepToShow('CHECKOUT-SHIPPING')
      break;
      
      case 'error':
        dispatch( clearLoading() )
        dispatch( setError( orderCreateError, 
          ()=>{
            // Error Callback
            dispatch(clearError());
          } ) )
        setOrderObj({})
      break;

      default: 

    }

  },[orderCreateStatus])



  const [orderToPlace, setOrderToPlace] = useState("");
  const {status: orderPlacedStatus, data: orderPlacedData, error: orderPlacedError} = usePlaceOrder(orderToPlace)

  useEffect(()=>{

    switch (orderPlacedStatus) {

      case 'loading':
        dispatch( setLoading() )
      break;

      case 'done':
        dispatch( clearLoading() )
        dispatch( setBamboraID(orderPlacedData.BamboraPreAuth.ID) )
        /*

        orderPlacedData.BamboraID IS...
        "BamboraID": "10000075",
        "BamboraStatus": "Approved",
        "BamboraAuthCode": "TEST"

        REDUNDANT, I think so? setSubStepToShow('CHECKOUT-INVOICE')
        */
        setSubStepToShow('CHECKOUT-INVOICE')
        setOrderCompleted()
      break;
      
      case 'error':
        dispatch( clearLoading() )
        dispatch( setError( orderPlacedError, 
          ()=>{
            // Error Callback
            dispatch(clearError());
          } ) )
        setOrderObj({})
      break;

      default: 

    }

  },[orderPlacedStatus])


  const { t, i18n } = useTranslation();
  const curLanguage = i18n.language.toUpperCase();

  return (
    <div className='step-3'>
      <div className="container">
        <div className={`complete-order mb-5 pb-lg-3 ${subStepToShow == 'COMPLETE-ORDER' ? '' : 'd-none'}`}>
          <PageTitle title={t('SELECT QUANTITY')} startover={t('Start Over')} />
          <div className="row">
            <div className="col-lg-6">
                <ViewFrontBack product={currentItem} /*customizations={customizations} logo={customizations.front.logo} text={customizations.front.threeTexts} croppedLogosrc={customizations.front.src}*/ />

                  <p className='printable-summary-link'><a onClick={()=>{
                    dispatch(setContentModal({body: <PreviewPrintLOCALContainer obj={currentOrder} product={currentItem} context='PREVIEW' />, title: "Golfball Customizer"}))
                  }}><FaPrint /> {t('Printable Summary')}</a></p>


                <p className="view-caveat">{t('Illustration used for example purposes only. Actual production may vary.')}</p>
              <div className="modal-content customization-pricing d-none d-lg-block">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {t('Customization Pricing')} {t('(per dozen)')}&nbsp;-&nbsp; 
                    {currentItem.BrandName} {currentItem[`Name${curLanguage}`]}
                  </h5>
                  <button className="close">×</button>
                </div>
                <div className="modal-body">
                  <p>{t('CustomizationTableLeadin')} <a href="mailto:gtpromotions@golftown.com">{t('Contact us')}</a> {t('for more information.')}
                  </p>
                  <PricingTable product={currentItem} />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="quantity d-flex align-items-center mb-3">
                <h4 className='mb-0 me-3 me-sm-4 ms-auto'>{t('Quantity')}</h4>
                <div className='d-inline-flex position-relative'>
                  <button disabled={quantity <= 12} 
                   onClick={() => {
                    setDebounceDelay(0);
                    setInputQuantity(quantity > 12 ? quantity - 1 : quantity);
                  }}>-</button>
                  <input onChange={e => {
                     setDebounceDelay(1500);
                     setInputQuantity(+e.target.value);
                  }} value={inputQuantity} type="number" className={i18n.language}
                  onClick={()=>{setInputQuantity('')}}/>
                  <button onClick={() => {
                    setDebounceDelay(0);
                    setInputQuantity(quantity + 1)
                  }}>+</button>
                  <span className="dozen position-absolute">
                    {t('Dozen')}
                  </span>
                </div>
              </div>
              <div className='total-price-table'>
                <table>
                  <tbody>
                    <tr className='text-uppercase'>
                      <th>
                        {t('ITEM')}
                      </th>
                      <th>{t('COST')}</th>
                    </tr>
                    <tr>
                      <td className='emphasize'>
                        <span style={{ textTransform: 'uppercase'}}>{currentItem.BrandName} {currentItem[`Name${curLanguage}`]}</span>
                      </td>
                      <td className='emphasize'>
                        {currencyFormatter(i18n).format( orderLineItems(currentItem,currentOrder).Balls.Per )}{t('/dz')}
                      </td>
                    </tr>



                    { ballFront.Type != 'NONE' ?
                    <tr className="emphasizeLast">
                      <td className='emphasize'>
                        {t('Customization')}
                      </td>
                      <td className='emphasize'>
                        { orderLineItemsObj.Front.Per + orderLineItemsObj.Back.Per == 0
                          ? t('FREE') 
                          : `${currencyFormatter(i18n).format( orderLineItemsObj.Front.Per + orderLineItemsObj.Back.Per )}/dz` }
                      </td>
                    </tr> : null
                    }


                    <tr>
                      <td>
                        {quantity} {t('dozen balls')}
                      </td>
                      <td>
                        {currencyFormatter(i18n).format( orderLineItems(currentItem,currentOrder).Balls.SubTotal )}
                      </td>
                    </tr>
                    { ballFront.Type != 'NONE' && (orderLineItemsObj.Front.Per > 0 || orderLineItemsObj.Back.Per > 0) ?
                    <tr>
                      <td>
                        {t('Customization')}
                      </td>
                      <td>
                        {currencyFormatter(i18n).format( orderLineItemsObj.Front.SubTotal + orderLineItemsObj.Back.SubTotal )}
                      </td>
                    </tr> : null
                    }

                    { orderLineItemsObj.DigitizationFee > 0 ?
                      <tr>
                        <td>
                          {t('Digitization Fee')}
                        </td>
                        <td>
                          {currencyFormatter(i18n).format( orderLineItemsObj.DigitizationFee )}
                        </td>
                      </tr> 
                    : null }

                  </tbody>
                </table>
                <div className="total-price">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <p className='mb-0'>
                        {t('Price does not include shipping & applicable taxes.')}
                      </p>
                    </div>
                    <div className="col-6">
                      <div className='d-flex justify-content-between'>
                        <span>
                          {t('Total')}
                        </span>
                        <span>
                          {currencyFormatter(i18n).format( orderLineItemsObj.BeforeShippingAndTaxes )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="buttons mt-4 pt-1 ">
                  <div className="text-uppercase row align-items-center">
                    <div className="col-sm-6 order-sm-1 mb-2 mb-sm-0">
                      <button onClick={()=>{
                        setOrderObj(order)
                      }} className='justify-content-center w-100 button red-button d-flex align-items-center'>
                        <span>
                          {t('SECURE CHECKOUT')}</span> <img className='ms-2' src={lock} alt="" />
                      </button>
                    </div>
                    <div className="col-sm-6">
                    { /*   <button className="w-100 button">
                        APPLY COUPON CODE
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <BuildSummaryHead />
              <div>
                <ChangeBallBox events={
                  {
                    toggleProductPriceModal,
                    toggleproductChangeModal
                  }
                } product={currentItem} />
              </div>
              <div className='mt-2' style={{
                // display: (customizations[currentlyEditing].threeTexts.first && customizations[currentlyEditing].threeTexts.second && customizations[currentlyEditing].threeTexts.third) ? 'block' : 'none'
              }}>
                <CustomizationBoxes changeStep={changeStep} /*customizations={customizations}*/ />
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0 d-lg-none">
              <div className="modal-content customization-pricing">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {t('Customization Pricing')} <span>{t('(per dozen)')}</span>
                  </h5>
                  <button className="close">×</button>
                </div>
                <div className="modal-body">
                  <p>{t('CustomizationTableLeadin')} <a href="mailto:gtpromotions@golftown.com">{t('Contact us')}</a> {t('for more information.')}</p>
                  <PricingTable product={currentItem} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`checkout ${subStepToShow.substring(0, 8) == 'CHECKOUT' ? '' : 'd-none'}`}>
          {
            !orderCompleted ? <><PageTitle title={t('CHECKOUT')} startover={t('Start Over')} />
              <div className="steps mb-4 pb-lg-3">
                <button className={subStepToShow === 'CHECKOUT-SHIPPING' ? 'active' : null}>
                  <span className='text-uppercase'>
                    {t('step 1:')} {t('Shipping')}
                  </span>
                </button>
                <img src={angleRight} alt="" />
                <button className={subStepToShow === 'CHECKOUT-BILLING' ? 'active' : null}>
                  <span className='text-uppercase'>
                    {t('step 2:')} {t('Billing')}
                  </span>
                </button>
                <img src={angleRight} alt="" />
                <button className={subStepToShow === 'CHECKOUT-PLACEORDER' ? 'active' : null}>
                  <span className='text-uppercase'>
                    {t('step 3:')} {t('Place Order')}
                  </span>
                </button>
              </div> </> : ''

          }
          <div className={`shipping mb-5 ${subStepToShow === 'CHECKOUT-SHIPPING' ? '' : 'd-none'}`}>
            <div className="row">
              <div className="col-lg-6">
                <div className="shipping-left">

                  <ShippingForm setSubStepToShow={setSubStepToShow} />

                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0 mb-4">
                <OrderSummary showExtra={false} subStepToShow={subStepToShow} setSubStepToShow={setSubStepToShow} />
              </div>
            </div>
          </div>

            <div className={`billing mb-5 ${subStepToShow.substring(0, 16) === 'CHECKOUT-BILLING' ? '' : 'd-none'}`}>
            <div className="row">
              <div className="col-lg-6">
                <div className="billing-left">
                  <BillingForm setSubStepToShow={setSubStepToShow} subStepToShow={subStepToShow} />
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0 mb-4">
                <OrderSummary showExtra={false} subStepToShow={subStepToShow} setSubStepToShow={setSubStepToShow} />
                <br />
                <ShippingAddress setSubStepToShow={setSubStepToShow} />
              </div>
            </div>
          </div>

          <div className={`place-prder ${subStepToShow === 'CHECKOUT-PLACEORDER' ? '' : 'd-none'}`}>
            <div className="row">
              <div className="col-lg-6 mb-4 mb-md-5">
                <div className='place-order-left'>
                  <ViewFrontBack product={currentItem} /*customizations={customizations} logo={customizations.front.logo} text={customizations.front.threeTexts} croppedLogosrc={customizations.front.src}*/ />

                  <p className='printable-summary-link'><a onClick={()=>{
                    dispatch(setContentModal({body: <PreviewPrintLOCALContainer obj={currentOrder} product={currentItem} context='PREVIEW' />, title: "Golfball Customizer"}))
                  }}><FaPrint /> {t('Printable Summary')}</a></p>

                  <div className="text-center">
                    <button onClick={() => {
                      setOrderToPlace(currentOrder.ID)
                      /* 
                        SetOrderCompleted triggers the move to the invoice in app.js 
                        setSubStepToShow is irrelevant I believe.
                        
                        So, we have to trigger the hook with a local state
                        change here.

                        useProcessPayment is the hook setup to place the order.

                        On 'done' status in that hook we can call setOrderCompleted()
  
                      */
                    }} className='button red-button'>
                      {t('PLACE ORDER')} <span className="ms-1">{'>'}</span>
                    </button>
                  </div>
                </div>

              </div>
              <div className="col-lg-6 mb-5">
                <div>
                  <div className="mb-4">
                    <OrderSummary subStepToShow={subStepToShow} setSubStepToShow={setSubStepToShow} showExtra={true} />
                  </div>

                  <ShippingAddress />
                  <br />
                  <BillingAddress setSubStepToShow={setSubStepToShow} />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(StepThree)