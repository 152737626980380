import './StatusControl.scss'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { setError, clearError, setLoading, clearLoading } from '../../redux/App/app.actions'
import { setStatus } from '../../redux/Console/console.actions'
import useConsoleSetStatus from '../../hooks/useConsoleSetStatus'
import { useDispatch, useSelector } from "react-redux"


function StatusControl({context:_context, orderObj:_orderObj}) {

	const { register, watch } = useForm();
	const statusToSet = watch("statusSelector");

	const [orderID, setorderID] = useState("")
    const dispatch = useDispatch();
	const {status: changedStatusStatus, data: changedStatusReturn, error: changedStatusError} = useConsoleSetStatus({ID: orderID, Status: statusToSet})

	const adminGroupIndex = useSelector(state => state.app.adminGroup)

	useEffect(()=>{

	    switch (changedStatusStatus) {

	      case 'loading':
	        dispatch( setLoading() )
	      break;

	      case 'done':
	        dispatch( clearLoading() )
			dispatch( setStatus(orderID, statusToSet) )
	      break;
	      
	      case 'error':
	        dispatch( clearLoading() )
	        dispatch( setError( changedStatusError, 
	          ()=>{
	            // Error Callback
	            dispatch( clearError() );
	          } ) )
	      break;

	      default: 

	    }

	},[changedStatusStatus])


	useEffect(()=>{
		if (statusToSet) {
			console.log(statusToSet, _orderObj.ID)
			setorderID(_orderObj.ID)
		}

	},[statusToSet])


	return adminGroupIndex < 2 ? 
		<p className="console-status-control">Change Order to...<br />
				<select {...register('statusSelector')}>
					<option value="">Select Status</option>
					{_orderObj.Status !=='INBOUND' ? <option value="INBOUND">Inbound Order</option> : null}
					{/*_orderObj.Status !=='PRODUCTION' ? <option value="PRODUCTION">In Production</option> : null}
					{_orderObj.Status !=='SHIPPED' ? <option value="SHIPPED">Shipped</option> : null */}
					{_orderObj.Status !=='COMPLETED' ? <option value="COMPLETED">Completed</option> : null}
					{_orderObj.Status !=='ARCHIVED' ? <option value="ARCHIVED">Archived</option> : null}
				</select>
			</p>
			: <></>
}

export default StatusControl
