import './LaunchPage.scss';
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import { useRef, useState } from 'react'
import { Link } from "react-router-dom";
import Topbar from '../../components/Topbar/Topbar';
import Logo from '../../components/Logo/Logo';
import GTNav from '../../components/GTNav/GTNav';
import GTFooter from '../../components/GTFooter/GTFooter';



function LaunchPage() {

  // Change the url of <a> tagged clssed with: co_text_block_cta

  const [loading, setLoading] = useState(true);
  const gt_Iframe = useRef(null);


  function handleIframe() {
    setLoading(false);
    const iframeItem = gt_Iframe.current;
    const anchors = iframeItem.contentWindow.getElementsByClassName("co_text_block_cta");
    console.log("elements", anchors)
  }


  //var elements = document.getElementsByClassName("co_text_block_cta");


  //elements[0].href="cnn.com";


  //const {} = props.props

  const { t, i18n } = useTranslation();
  const curLanguage = i18n.language.toUpperCase();
  console.log("LANGUAGE:", curLanguage)

  return (
    <I18nextProvider i18n={i18n}>
      <div className='text-center d-md-none'>
        <Logo />
      </div>

      <Topbar />

      <div>
        <div className="container mt-sm-1 steps-wrapper">
          <div className="d-none d-md-block">
            <Logo />
          </div>
          <div className="d-none d-md-block">
            <GTNav />
          </div>
        </div>

      <div className="ginner-content">
        <div className={`ghero-section ${curLanguage}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="gtitle">{t('CUSTOMIZED GOLF BALLS')}</h1>
                <h4 className="gsubtitle">
                  {t('Add your logo, tagline or text to your golf balls for a personal touch!')}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="gcustomize-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="title">{t('Customize your golf balls for any occasion!')}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <ul className="bullet-group">
                  <li className="bullet-item">{t('Customize with your logo or with text')}</li>
                  <li className="bullet-item">{t('Minimum 12 dozen per order')}</li>
                  <li className="bullet-item">{t('No set up fees')}</li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="bullet-group">
                  <li className="bullet-item">{t('Single or double sided customization options')}</li>
                  <li className="bullet-item">{t('Free shipping on your order*')}</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-center py-md-5 py-3">
                <a href="/home" className="gprimary-btn">{t('START CUSTOMIZING NOW!')}</a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p className="text-legal">{t('LAUNCH SHIPPING LEGAL')}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="custom-options-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="title">{t('CUSTOM OPTIONS')}</h2>
                <p className="desc-text">{t('Choose your ball, colour, text or logo to make a customized golf ball for your tournament, gifting or other occasion.')}</p>
              </div>
            </div>
            <div className="row align-items-center image-content">
              <div className="col-lg-6">
                <div className="gimage-wrap">
                  <img src={`/images/launch/GT23_PZ_LP_Text_Image_${curLanguage}.gif`} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="gcontent-text">
                  <h4>{t('PERSONALIZED TEXT')}</h4>
                  <p>{t('Make a statement with your company tagline, corporate hashtag, best golf pun or heartfelt message.')}</p>
                  <a href="/home" className="gprimary-btn">{t('Order Now')}</a>
                </div>
              </div>
            </div>
            <div className="row align-items-center image-content">
              <div className="col-lg-6 order-lg-0 order-1">
                <div className="gcontent-text">
                  <h4>{t('LOGO\'D GOLF BALLS')}</h4>
                  <p>{t('Want to provide cool swag for customers, partners or employees? Upload and print your logo on your favourite golf balls.')}</p>
                  <a href="/home" className="gprimary-btn">{t('Order Now')}</a>
                </div>
              </div>
              <div className="col-lg-6 order-lg-1 order-0">
                <div className="gimage-wrap">
                  <img src="/images/launch/GT23_PZ_LP_Logo_Image_2_1.gif" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="help-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="title">{t('NEED HELP? WE\'RE HERE TO ASSIST!')}</h2>
                <p>
                  <div dangerouslySetInnerHTML={
                      {__html: t('LAUNCH ASSISTANCE TEXT', {interpolation: {escapeValue: false}})}
                  } />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="promotion-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <img src={`/images/launch/GTPromotions_logo_${curLanguage}.png`} alt="" />
                <p>{t('LAUNCH FURTHER ASSISTANCE')}
                </p>
                <a href="https://www.golftown.com/en-CA/gt-promotions/" className="gprimary-btn">{t('LEARN MORE')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-sm-1 steps-wrapper">
        <GTFooter />
      </div>

    </div>
    </I18nextProvider>
  )
}

export default LaunchPage


/*

        <iframe
          ref={gt_Iframe}
          src={curLanguage === 'EN' ? "https://www.golftown.com/en-CA/gt-logo-golf-balls/" : "https://www.golftown.com/fr-CA/gt-logo-golf-balls/"} 
          frameBorder={0}
          onLoad={handleIframe}
        ></iframe>

*/