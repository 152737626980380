import { connect, useDispatch   } from "react-redux"
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state) => ({
  companyname:     state.billing.CompanyName,
  name:     state.billing.Name,
  LastFour: state.billing.LastFour,
  month:    state.billing.Month,
  year:     state.billing.Year,
  cvc:      state.billing.CVC,
  first:    state.billing.First,
  last:     state.billing.Last,
  address1: state.billing.Address1,
  address2: state.billing.Address2,
  city:     state.billing.City,
  postcode: state.billing.PostCode,
  country:  state.billing.Country,
  province: state.billing.Province,
  phone:    state.billing.Phone,
})



function BillingAddress(props) {

  const { t, i18n } = useTranslation()

  return (
      <div className='order-summary address-box billing-info'>
        <div className="align-items-center d-flex justify-content-between">
          <h3>
            {t('Billing Information')}
          </h3>
          <button className="edit text-green text-decoration-underline"
          onClick={()=>props.setSubStepToShow('CHECKOUT-BILLING')}>
            {t('Edit')}
          </button>
        </div>
        <div className="gray-box">
          <ul className="address-list p-0 m-0">
            <li key={0}>{props.companyname}</li>
            <li key={1}>{props.first} {props.last}</li>
            <li key={2}>{props.address1}</li>
            <li key={3}>{props.address2}</li>
            <li key={4}>{props.city}</li>
            <li key={5}>{props.postcode}</li>
            <li key={6}>{props.country}</li>
            <li key={7}>{props.province}</li>
            <li key={8}>{props.phone}</li>
          </ul>
         <hr />
          <h6 className='text-uppercase'>
            {props.card_type}
          </h6>
          <ul className='address-list p-0 m-0'>
            <li>
              {props.name}
            </li>
            <li>
              {`**** **** **** ${props.LastFour}`}
            </li>
            <li>
              {t('Expiration and colon')} {props.month}/{props.year}
            </li>
          </ul>
        </div>
      </div>
    )
}


export default connect(mapStateToProps)(BillingAddress)