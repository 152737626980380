import { 
    SET_BILLING_INFO,
    SET_SAME_AS_SHIPPING,
    BILLING_RESET_FOR_REPEAT,
} from './billing.types';

const INITIAL_STATE = {
    Name:       '',
    Number:     '',
    Type:       '',
    Month:      '',
    Year:       '',
    CVC:        '',
    CompanyName:'',
    First:      '',
    Last:       '',
    Address1:   '',
    Address2:   '',
    City:       '',
    PostCode:   '',
    Country:    '',
    Province:   '',
    Phone:      '',
};

const billingReducer = (state = INITIAL_STATE, action) => {

    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {

        case SET_BILLING_INFO:

            return {
                ...state, 
                ...action.payload
            };

        break;


        case SET_SAME_AS_SHIPPING:

/*
            var turnedAroundPayload = {
                First:            action.payload.First,
                Last:             action.payload.Last,
                Address1:         action.payload.Address1,
                Address2:         action.payload.Address2,
                City:             action.payload.City,
                PostCode:         action.payload.PostCode,
                Country:          action.payload.Country,
                Province:         action.payload.Province,
                Phone:            action.payload.Phone,
                Email:            action.payload.Email,
            }
*/

            return {
                ...state, 
                ...action.payload
            };

        break;


        case BILLING_RESET_FOR_REPEAT:

            updatedState.Name = ""
            updatedState.Number = ""
            updatedState.Type = ""
            updatedState.Month = ""
            updatedState.Year = ""
            updatedState.CVC = ""
            updatedState.ID = ""
            updatedState.LastFour = ""

            return {
                ...updatedState,
            };

        break;

        default: return state;

    }

};

export default billingReducer;