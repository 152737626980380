import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//const axios = require('axios')
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useConsoleSetStatus = ({ID:_ID, Status:_status}) => {
    
    /*
        NOTE: Do not be confused...
        'status' is used in hooks as the status of the hook (idle, loading, etc.) 
        and also in this particular hook as the _status of the order (production, completed, etc... 
        'status' in the order OBJ)
    */

    const [status, setStatus] = useState("idle")
    const [data, setData] = useState([])
    const [error, setError] = useState("")

    const jwt = useSelector(state => state.app.jwt)

    useEffect(() => {

        if (_ID != "") {
            
            setStatus('loading')

            const setNewOrderStatus = async () => {

                var completedFormData = new FormData()
                completedFormData.append('status', _status)

                //setTimeout(async ()=>{
                    try {
 
                        const response = await axios.post(
                            `${endpoint}/adm/orders/${_ID}/status`,
                            completedFormData,
                            { headers: 
                                {
                                    Authorization: `Bearer ${jwt}` 
                                }
                            }
                        )

                        setData(response.data)
                        setStatus('done')
                        console.log("SET STATUS OF", _ID, " to ", _status)
                    }
                    catch(e) {
                        setError('Order Status could not be changed.')
                        setStatus('error')
                        throw "API ERROR – ORDER STATUS COULD NOT BE CHANGED"
                    }
                //}, 3000);

            };

            setNewOrderStatus()

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }

    }, [_ID])

    return { status, data, error }

};

export default useConsoleSetStatus