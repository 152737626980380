import React, { useEffect, useState } from 'react'
import { useSelector, dispatch } from 'react-redux'
import { setLogoAndLogoName } from '../redux/Order/order.actions'
import { setVectorLogoDetailsForFace } from '../redux/Order/order.actions'
import { useDispatch } from "react-redux"

//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useVectorUpload = ({file, fileName}) => {
    
    const [status, setStatus] = useState("idle");
    const [data, setData] = useState([]);
    const [error, setError] = useState("");

    const dispatch = useDispatch()
    
    const jwt = useSelector(state => state.app.jwt)


    useEffect(() => {

        if (typeof fileName === 'string' && fileName.length > 0) {

            setStatus('loading')

            let formData = new FormData();
            formData.append("file", file, fileName);

            const uploadVector = async () => {

                try {
                    const {data:response} = await axios.post(
                        `${endpoint}/logo/create`,
                        formData,
                        {
                            headers: {Authorization: `Bearer ${jwt}`},
                            'Content-Type': `multipart/form-data; boundary=${file._boundary}`,
                        }
                    )

                    await fetch(`https://${response.PngURL}`)
                      .then(res => res.blob()) // Gets the response and returns it as a blob
                      .then(blob => {
                        let objectURL = URL.createObjectURL(blob);
                        dispatch(setLogoAndLogoName(objectURL, fileName))
                        dispatch(setVectorLogoDetailsForFace(response))
                        setStatus('done');
                    });

                    return response
                }
                catch (error) {
                    setError('File must be a vector file in AI, PDF, SVG or EPS format.');
                    setStatus('error');
                    throw new Error(error)
                }

            };

            uploadVector()          

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }


    }, [file, fileName]);

    return { status, data, error };

};

export default useVectorUpload;