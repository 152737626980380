import { useState, Component } from 'react';
import Topbar from './components/Topbar/Topbar';
import Logo from './components/Logo/Logo';
import GTNav from './components/GTNav/GTNav';
import GTFooter from './components/GTFooter/GTFooter';
import Steps from './components/Steps/Steps';
//import { BrowserRouter } from 'react-router-dom';
import EditCustomizationText from './components/EditCustomization/EditCustomizationText';
import EditCustomizationLogo from './components/EditCustomization/EditCustomizationLogo';
import StepOne from './components/StepOne/StepOne';
import ChooseCustomization from './components/StepTwo/ChooseCustomization';
import PositionLogo from './components/StepTwo/PositionLogo';
import ChooseAddText from './components/StepTwo/ChooseAddText';
import SelectVector from './components/StepTwo/SelectVector';
import StepThree from './components/StepThree/StepThree';
import Invoice from './components/Invoice';
import ProductPriceModal from './components/Modal/ProductPriceModal.js';
import ProductChangeModal from './components/Modal/ProductChangeModal.js';
import ErrorModal from './components/Modal/ErrorModal.js';
import LoadingSpinner from './components/LoadingSpinner';
import ContentModal from './components/Modal/ContentModal.js'
import RepeatOrder from './components/RepeatOrder.js'
import './index.scss'; 

// Localization
//import { I18nextProvider } from "react-i18next";
//import i18n from "./i18n";


class App extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 1,
      consideringProduct: null,
      productPriceModal: false,
      productChangeModal: false,
      orderCompleted: false,
      textEdit: false,
      logoUpload: false,
    }
  }
  setTextEdit = () => {
    this.setState({
      textEdit: true
    });
  }
  setLogoUpload = () => {
    this.setState({
      logoUpload: true
    });
  }
///* TO MOVE TO REDUCER
  setOrderCompleted = () => {
    this.setState({
      orderCompleted: true
    })
  }
  setOrderCompletedFalse = () => {
    this.setState({
      orderCompleted: false
    })
  }
//*/
  toggleProductPriceModal = (product) => {
    if (this.state.productPriceModal) {
      this.setState({
        productPriceModal: !this.state.productPriceModal
      })
    } else {
      this.setState({
        productPriceModal: !this.state.productPriceModal,
        consideringProduct: product
      })
    }
  }
  toggleproductChangeModal = () => {
    this.setState({
      productChangeModal: !this.state.productChangeModal
    })
  }
  changeStep = (step) => {
    this.setState({
      currentStep: step
    })
  }


  render() {
    return <><LoadingSpinner />
      { /*
      <div style={{fontFamily:'helvetica,sans',fontWeight:'bold',textAlign:'center',height:30,postion:'absolute',backgroundColor:'#FF1493',zIndex:1000,paddingTop:4,color:'#ccc'}}>
      CLIENT PREVIEW <span style={{fontSize:13,color:'#fff',fontWeight:300}}>(build 1.3.23)</span>
      </div>
      */ }
      <ErrorModal />
      <ContentModal />
      <ProductPriceModal className="product-price-modal modal-dialog-centered modal-lg modal-dialog-scrollable" event={this.toggleProductPriceModal} product={this.state.consideringProduct} modal={this.state.productPriceModal} />
      <ProductChangeModal className="product-change-modal modal-dialog-scrollable modal-lg" /*products={ballProducts}*/ events={{
        toggleproductChangeModal: this.toggleproductChangeModal,
        //setCurrentItem: this.setCurrentItem
      }} modal={this.state.productChangeModal} />
      <div className='text-center d-md-none'>
        <Logo />
      </div>
      <Topbar />
      <div>
        <div className="container mt-sm-1 steps-wrapper">
          <div className="d-none d-md-block">
            <Logo />
          </div>
          <div className="d-none d-md-block">
            <GTNav />
          </div>
          {
            !this.state.orderCompleted && <Steps currentStep={this.state.currentStep} event={this.changeStep} />
          }

        </div>
        {
          this.state.currentStep === 1 ? <StepOne events={{
            changeStep: this.changeStep,
            toggleProductPriceModal: this.toggleProductPriceModal,
          }} /> : ''
        }
        {
          this.state.currentStep === 2 ?
            <ChooseCustomization 
              events={
              {
                toggleProductPriceModal: this.toggleProductPriceModal,
                toggleproductChangeModal: this.toggleproductChangeModal,
                changeStep: this.changeStep,
              }} /> :
            ''
        }
        {
          this.state.currentStep === 21 ?
            <ChooseAddText 
              events={
              {
                toggleProductPriceModal: this.toggleProductPriceModal,
                toggleproductChangeModal: this.toggleproductChangeModal,
                changeStep: this.changeStep,
              }} /> :
            ''
        }
        {
          this.state.currentStep === 22 ?
            <SelectVector 
              events={
              {
                toggleProductPriceModal: this.toggleProductPriceModal,
                toggleproductChangeModal: this.toggleproductChangeModal,
                changeStep: this.changeStep,
              }} /> :
            ''
        }
        {
          this.state.currentStep === 23 ?
            <PositionLogo 
              events={
              {
                toggleProductPriceModal: this.toggleProductPriceModal,
                toggleproductChangeModal: this.toggleproductChangeModal,
                changeStep: this.changeStep,
              }} /> :
            ''
        }
        {
          this.state.currentStep === 3 ?
            <StepThree
              events={{
                toggleProductPriceModal: this.toggleProductPriceModal,
                toggleproductChangeModal: this.toggleproductChangeModal,
                setOrderCompleted: this.setOrderCompleted,
                setTextEdit: this.setTextEdit,
                changeStep: this.changeStep,
                setLogoUpload: this.setLogoUpload,
              }}
              props={{
                orderCompleted: this.state.orderCompleted,
              }} /> :
            ''
        }
        {
          this.state.currentStep === 4 ?
            <EditCustomizationText 
              logoUpload={this.state.logoUpload} 
              sameAsFront={this.state.sameAsFront} 
              events={
              {
                toggleProductPriceModal: this.toggleProductPriceModal,
                toggleproductChangeModal: this.toggleproductChangeModal,
                changeStep: this.changeStep,
              }} /> :
            ''
        }
        {
          this.state.currentStep === 5 ?
            <EditCustomizationLogo 
              logoUpload={this.state.logoUpload} 
              events={
              {
                toggleProductPriceModal: this.toggleProductPriceModal,
                toggleproductChangeModal: this.toggleproductChangeModal,
                changeStep: this.changeStep,
              }} /> :
            ''
        }
        {
          this.state.currentStep === 999 ?
            <RepeatOrder
              events={{
                toggleProductPriceModal: this.toggleProductPriceModal,
                toggleproductChangeModal: this.toggleproductChangeModal,
                setOrderCompleted: this.setOrderCompleted,
                setTextEdit: this.setTextEdit,
                changeStep: this.changeStep,
                setLogoUpload: this.setLogoUpload,
                setOrderCompletedFalse: this.setOrderCompletedFalse,
              }}
              props={{
                orderCompleted: this.state.orderCompleted,
              }} /> :
            ''
        }
        {
          this.state.orderCompleted && <Invoice
              events={{
                changeStep: this.changeStep,
              }} />
        }
        <GTFooter />
      </div></>
  }


}

export default App;