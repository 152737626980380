import { useEffect, useState } from 'react';
import { connect, useDispatch} from "react-redux"
import { setShippingInfo } from '../../redux/Shipping/shipping.actions'
import { setError, clearError, setLoading, clearLoading } from '../../redux/App/app.actions'
import { setBillingInfoSameAsShipping } from '../../redux/Billing/billing.actions'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'
import useShippingUpload from '../../hooks/useShippingUpload'
import useCanPostSearch from '../../hooks/useCanPostSearch'
import useCanPostRetrieve from '../../hooks/useCanPostRetrieve'
import TypeAheadDropDown from '../TypeAheadDropDown'

const mapStateToProps = (state) => ({
  fullShippingObj:        state.shipping,
  orderID:                state.order.ID,
})



function ShippingForm(props) {

  const { t, i18n } = useTranslation();
  const orderID = props.orderID;

  //props.sameAsShipping

  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

  const dispatch = useDispatch();

  const [sameBillingAddress, setSameBillingAddress] = useState(false);
  const [shippingObj, setShippingObj] = useState({});
  const {status: shippingUploadStatus, data: shippingEndpointReturn, error: shippingUploadError} = useShippingUpload(shippingObj)

  useEffect(()=>{

    switch (shippingUploadStatus) {

      case 'loading':
        dispatch( setLoading() )
      break;

      case 'done':
        dispatch( clearLoading() )
        dispatch(setShippingInfo(shippingEndpointReturn.Billing.ShippingAddress));

        if (sameBillingAddress) {
          props.setSubStepToShow('CHECKOUT-BILLING-SAME-ADDRESS');
          dispatch(setBillingInfoSameAsShipping(shippingEndpointReturn.Billing.ShippingAddress));
        } else {
          props.setSubStepToShow('CHECKOUT-BILLING');
        }

      break;
      
      case 'error':
        dispatch( clearLoading() )
        dispatch( setError( shippingUploadError, 
          ()=>{
            // Error Callback
            dispatch(clearError());
          } ) )
      break;

      default: 

    }

  },[shippingUploadStatus])


  const [canPostSearchText, setCanPostSearchText] = useState("");
  const {status: canPostSearchStatus, data: canPostSearchReturn, error: canPostSearchError} = useCanPostSearch(canPostSearchText)
  const [addressArr, setAddressArr] = useState([]);

  useEffect(()=>{

    switch (canPostSearchStatus) {

      case 'loading':
          setAddressArr([])
      break;

      case 'done':
          setAddressArr(Object.values(canPostSearchReturn))

      break;
      
      case 'error':

      break;

      default: 

    }

  },[canPostSearchStatus])


  const [canPostRetrieveId, setCanPostRetrieveId] = useState(null);
  const {status: canPostRetrieveStatus, data: canPostRetrieveReturn, error: canPostRetrieveError} = useCanPostRetrieve(canPostRetrieveId)
  const [retreivedAddress, setRetreivedAddress] = useState({});

  useEffect(()=>{

    switch (canPostRetrieveStatus) {

      case 'loading':
          setAddressArr([])
      break;

      case 'done':
        const resetObj = {
          Address1: canPostRetrieveReturn[0].Line1,
          Address2: canPostRetrieveReturn[0].Line2,
          City: canPostRetrieveReturn[0].City,
          Country: canPostRetrieveReturn[0].CountryName,
          PostCode: canPostRetrieveReturn[0].PostalCode,
          Province: canPostRetrieveReturn[0].ProvinceCode,
        }
        reset(resetObj)
      break;
      
      case 'error':

      break;

      default: 

    }

  },[canPostRetrieveStatus])


  const onSubmit = data => {

    if (data.same_address) {
      setSameBillingAddress(true)
    }

    setShippingObj({...data, ID: orderID})

    reset({...props.fullShippingObj});

  };


  useEffect(()=>{
    reset({...props.fullShippingObj});
  },[props])


  return (

          <div className="billing mb-5">
            <h4 className='mb-3'>
              {t('Enter Shipping Address')}
            </h4>
            <p className="text-red">
              {t('We only ship within Canada')}
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-3'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="CompanyName">
                      {t('COMPANY NAME')}
                  </label>
                  <input {...register("CompanyName", { required: false })} className='w-100' />
                  <span className="error">{errors.CompanyName?.type === 'required' && "Company name is required"}</span>
                </div>
              </div>
              <div className='mb-3'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="First">
                      {t('FIRST NAME')}
                  </label>
                  <input {...register("First", { required: true })} className='w-100' />
                  <span className="error">{errors.First?.type === 'required' && "First name is required"}</span>
                </div>
              </div>
              <div className='mb-3'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="Last">
                      {t('LAST NAME')}
                  </label>
                  <input {...register("Last", { required: true })} className='w-100' />
                  <span className="error">{errors.Last?.type === 'required' && "Last name is required"}</span>
                </div>
              </div>
              <div className='mb-3'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="Address1">
                      {t('ADDRESS LINE 1')}
                  </label>
                  <input {...register("Address1", { 
                    onChange: (e) => {
                      setCanPostSearchText(e.target.value)
                    }, required: true })} defaultValue={props.Address1} className='w-100' autoComplete="off" />
                  <TypeAheadDropDown arr={addressArr} selectAction={(selectedId)=>{
                    setCanPostSearchText("")
                    setCanPostRetrieveId(selectedId)
                  }} />
                  <span className="error">{errors.Address1?.type === 'required' && "Address is required"}</span>
                </div>
              </div>
              <div className='mb-3'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="Address2">
                      {t('ADDRESS LINE 2')}
                  </label>
                  <input {...register("Address2")} className='w-100' />
                </div>
              </div>
              <div className='mb-3'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="City">
                      {t('CITY')}
                  </label>
                  <input {...register("City", { required: true })} className='w-100' />
                  <span className="error">{errors.City?.type === 'required' && "City is required"}</span>
                </div>
              </div>
              <div className='mb-3'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="PostCode">
                      {t('POSTAL CODE')}
                  </label>
                  <input {...register("PostCode", { required: true })} className='w-100' />
                  <span className="error">{errors.PostCode?.type === 'required' && "Postal Code is required"}</span>
                </div>
                <span className='example'>{t('Example: 1A2B3C')}</span>
              </div>
              <div className='mb-3'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="Country">
                      {t('COUNTRY')}
                  </label>
                  <input {...register("Country", { required: true })} className='w-100' />
                  <span className="error">{errors.Country?.type === 'required' && "Country is required"}</span>
              </div>
              </div>
              <div className='mb-3'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="Province">
                      {t('PROVINCE')}
                  </label>
                  <select {...register("Province", { required: true })} className='w-100'>
                    <option hidden disabled selected value=""></option>
                    <option value="AB">{t('Alberta')}</option>
                    <option value="BC">{t('British Columbia')}</option>
                    <option value="MB">{t('Manitoba')}</option>
                    <option value="NB">{t('New Brunswick')}</option>
                    <option value="NL">{t('Newfoundland and Labrador')}</option>
                    <option value="NT">{t('Northwest Territories')}</option>
                    <option value="NS">{t('Nova Scotia')}</option>
                    <option value="NU">{t('Nunavut')}</option>
                    <option value="ON">{t('Ontario')}</option>
                    <option value="PE">{t('Prince Edward Island')}</option>
                    <option value="QC">{t('Quebec')}</option>
                    <option value="SK">{t('Saskatchewan')}</option>
                    <option value="YT">{t('Yukon')}</option>
                  </select>
                  <span className="error">{errors.Province?.type === 'required' && "Province is required"}</span>
                </div>
              </div>
              <div className='mb-3 d-flex flex-column'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="Phone">
                      {t('PHONE')}
                  </label>
                  <input {...register("Phone", { required: true,pattern: /[0-9]/ })} className='w-100' />
                  <span className="error">{errors.Phone?.type === 'required' && "Phone is required"}</span>
                </div>
                <span className='example'>{t('Example: 1112223333')}</span>
              </div>
              <div className='mb-4'>
                <div className={`input-box`}>
                  <label className='text-bold text-uppercase' htmlFor="Email">
                      {t('EMAIL')}
                  </label>
                  <input {...register("Email", { required: true, pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i })} className='w-100' />
                  <span className="error">{errors.Email?.type === 'required' && "Email is required"}</span>
                  <span className="error">{errors.Email?.type === 'pattern' && "Invalid email"}</span>
               </div>
              </div>

              <div className='mb-4'>
                <label className="d-flex" htmlFor="same_address">
                  <p><input name="same_address" type="checkbox" {...register('same_address')} id="same_address" />
                   &nbsp;{t('USE THIS ADDRESS FOR BILLING')}</p>
                </label>
              </div>


              <input type="submit" className='button red-button' value='Continue'/>
            </form>


          </div>

  )
}


export default connect(mapStateToProps)(ShippingForm)