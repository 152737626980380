import { useSelector, useDispatch } from "react-redux"
import "./ViewFace.scss"

const endpoint = process.env.REACT_APP_API_ENDPOINT

function ViewFace({obj: _obj, product: _product, face: _face, context: _context}) {

    const colors = useSelector(state => state.product.colors)

    /*
        The 3 available contexts are:
        PRODNOTES - From console
        EXTERNAL - from email
        PREVIEW - from consumer interface

        Main difference between them is the state parameter names
        i.e. use of ID vs logo vs Logo
    */

    _product.FrontImage = '/images/products/GENERIC-FRONT-BACK.png'
    _product.BackImage = '/images/products/GENERIC-FRONT-BACK.png'

    return (
        <div className='view-face'>
            <div className="customize row">
              <div>
                <div className='customize-left'>
                    <div>
                        {(_obj[_face].Type === 'TEXT') || (_obj[_face].Type === 'LOGO')
                            ? <div>
                                  <img src={_face === 'Front' ? _product.FrontImage : _product.BackImage} alt='' />
                                  { _obj[_face].Type === 'LOGO' ?
                                  <div className={`logo ${_context === 'PRODNOTES' ? 'keyline' : null }`} id="croppedLogo" style={{mixBlendMode: "multiply"}}>
                                    <img src={`${endpoint}/logos/${_context === 'PRODNOTES' || _context === 'EXTERNAL' ? _obj[_face].Logo : _obj[_face].ID }/cropped`} alt='' />
                                  </div> : null }
                                  { _obj[_face].Type === 'TEXT' ?
                                  <p className="ball-demo-text" style={{color: colors[_obj[_face].Color].HexValue}}>
                                    <span>{_obj[_face].Line1}</span>
                                    <span>{_obj[_face].Line2}</span>
                                    <span>{_obj[_face].Line3}</span>
                                  </p> : null }
                                </div>
                            : <div><img src={_face === 'Front' ? _product.FrontImage : _product.BackImage} alt='' /></div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default ViewFace