import './RetailProControl.scss'
import { useState, useEffect } from 'react'
import { setError, clearError, setLoading, clearLoading } from '../../redux/App/app.actions'
import { setOrderRetailPro } from '../../redux/Console/console.actions'
import { useDispatch } from "react-redux"
import useConsoleCreateProductionOrder from '../../hooks/useConsoleCreateProductionOrder'
import { useForm } from 'react-hook-form'

function RetailProControl({context:_context, orderObj:_orderObj}) {

	const [rpObj, setRpObj] = useState({ID:_orderObj.ID, RpRct:"",RpCust:""})
    const dispatch = useDispatch();

	const {status: rtproStatus, data: rtproReturn, error: rtproError} = useConsoleCreateProductionOrder(rpObj)

	const { register, watch, handleSubmit, reset, clearErrors, formState: { errors } } = useForm();

	useEffect(()=>{

	    switch (rtproStatus) {

	      case 'loading':
	        dispatch( setLoading() )
	      break;

	      case 'done':
	        dispatch( clearLoading() )
	        dispatch(setOrderRetailPro(_orderObj.ID, rpObj.RpRct, rpObj.RpCust))
	      break;
	      
	      case 'error':
	        dispatch( clearLoading() )
	        dispatch( setError( rtproError, 
	          ()=>{
	            // Error Callback
	            dispatch(clearError());
	          } ) )
	      break;

	      default: 

	    }

	},[rtproStatus])







/*

	useEffect(()=>{


		if (rpObj.RpRct && rpObj.RpCust) {
			dispatch(setOrderRetailPro(_orderObj.ID, rpObj.RpRct, rpObj.RpCust))
		}

	},[rpObj.RpRct])

*/

	const onRPSubmit = data => {
		setRpObj({ID:_orderObj.ID, RpRct:data[`RpRct`], RpCust:data[`RpCust`]})
	};

	return (

      	<div className="formBody">
			<form onSubmit={handleSubmit(onRPSubmit)} className='console-retail-pro-num-control'>
				<input {...register("RpRct", { required: true })} placeholder='RP #' className='w-100 mb-1 form-control'/>
				<span className="error">{errors.RpRct?.type === 'required' && "RP Rct # is required"}</span>
				<div className='input-group'>
					<input {...register("RpCust", { required: true })} placeholder='RP Customer #' className='form-control'/>
					<button type="submit" className='btn-connected red'>SAVE</button>
				</div>
				<span className="error">{errors.RpCust?.type === 'required' && "RP Cust # is required"}</span>
			</form>
		</div>

	)

}

export default RetailProControl
