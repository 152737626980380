// Console actions

import { 
  LOAD_ORDERS_TO_STATE,
  SET_TRACKING,
  SET_RETAILPRO,
  TOGGLE_ORDER_PAUSE,
  SET_STATUS,
} from './console.types';


export const loadOrdersToState = (_orders) => {
	return {
	    type: LOAD_ORDERS_TO_STATE,
	    payload: _orders
	}
}

export const setOrderTracking = (_orderID, _tracking) => {
	return {
	    type: SET_TRACKING,
	    payload: {id: _orderID, tracking: _tracking}
	}
}

export const setStatus = (_orderID, _status) => {
	return {
	    type: SET_STATUS,
	    payload: {
	    	id: _orderID,
	    	status: _status,
	    }
	}
}

export const setOrderRetailPro = (_orderID, _rpRct, _rpCust) => {
	return {
	    type: SET_RETAILPRO,
	    payload: {
	    	id: _orderID, 
	    	RP_Rct: _rpRct,
	    	RP_Cust: _rpCust,
	    }
	}
}

export const toggleOrderPause = (_orderID, _note) => {
	return {
	    type: TOGGLE_ORDER_PAUSE,
	    payload: {
	    	id: _orderID, 
	    	note: _note
	    }
	}
}


