import React, { createRef, useEffect, useState } from 'react'
import ballFront from '../customization/ball-front.png'
import ballBack from '../customization/ball-back.png'
import Button from '../Buttons.js';
import ColorButton from '../customization/ColorButton.js';
import ChangeBallBox from '../ChangeBallBox';
import ImgSizeSlider from '../ImgSizeSlider';
import { Link } from 'react-router-dom';
import useVectorUpload from '../../hooks/useVectorUpload'
import './StepTwo.scss';
import './SelectVector.scss';
import { useTranslation } from 'react-i18next'
import { setError, clearError, setLoading, clearLoading } from '../../redux/App/app.actions'
import PageTitle from '../PageTitle'

import { connect, useDispatch   } from "react-redux"



const mapStateToProps = (state) => ({
  currentlyEditing:       state.order.currentlyEditing,
  currentItem:            state.order.product,
  order:                  state.order,
})


function SelectVector(props) {

  const {  
    currentlyEditing,
    currentItem, 
    order,
    events  } = props;

  const { 
    changeStep, 
    toggleproductChangeModal, 
    toggleProductPriceModal } = events;

  const [vectorFileObj, setVectorFileObj] = useState({file: "", fileName: ""});
  const {status: vectorUploadStatus, data: vectorUploadData, error: vectorUploadError} = useVectorUpload(vectorFileObj)

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation()

  useEffect(()=>{

    switch (vectorUploadStatus) {

      case 'loading':
        dispatch(setLoading())
      break;

      case 'done':
        dispatch(clearLoading())
        changeStep(23)
        setVectorFileObj({file: "", fileName: ""})
      break;
      
      case 'error':
        dispatch(clearLoading())
        dispatch( setError( t(vectorUploadError), 
          ()=>{
            // Error Callback
            dispatch(clearError());
          } ) )
      break;

      default: 

    }

  },[vectorUploadStatus])



  return (
    <div className='step-two mb-5 pb-lg-5 select-vector'>
      <div className='container'>
        <PageTitle title={t('CUSTOMIZE YOUR BALL')} startover={t('Start Over')} />
        <div className="step-two-first one row">
          <div className="col-lg-6">
            <div className='customize-right one'>
              <h4 className='customize-subtitle text-center text-md-start'>
                {t('Select Logo File')}
              </h4>
              <p className='text-center text-md-start mt-3 pt-1 mb-4 pb-2'>
                <div dangerouslySetInnerHTML={
                    {__html: t('VECTOR-SELECT-DESCRIPTION', {interpolation: {escapeValue: false}})}
                } />
              </p>
              <img className='d-md-none' src={currentItem.FrontImage} alt="" />
              <input className='d-none' onChange={e => {
                setVectorFileObj({file:e.target.files[0],fileName:e.target.files[0].name})
              }} id='upload-logo' type='file' />
              <div className='center-buttons-mobile'>
                <label htmlFor="upload-logo" className='button red-button text-center'>
                  {t('Add Logo')}
                </label>
                <br /><button onClick={()=> { changeStep(2) }} className='button cancel-button'>{t('Cancel')}</button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 vector-explanation">
            <div className='customize-right one'>
              <h4 className='customize-subtitle text-center text-md-start'>
                {t('VECTOR-EXPLANATION-HEAD')}
              </h4>
              <p className='text-center text-md-start mt-0 pt-0 mb-4 pb-2'>
                {t('VECTOR-EXPLANATION-BODY')}
              </p>
              <div className="vector-diagrams">
                <div className="one">
                  <img className="me-1 me-sm-2" src="/images/app/vector-example.png" alt="" />
                  <p>{t('Vector')}</p>
                </div>
                <div className="two">
                  <img className="me-1 me-sm-2" src="/images/app/bitmap-example.png" alt="" />
                  <p>{t('Bitmap')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(SelectVector)