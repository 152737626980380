import './TrackingNumControl.scss'
import { useState, useEffect } from 'react'
import { setError, clearError, setLoading, clearLoading } from '../../redux/App/app.actions'
import { setOrderTracking } from '../../redux/Console/console.actions'
import { useDispatch } from "react-redux"
import useConsoleAddTracking from '../../hooks/useConsoleAddTracking'
import { useForm } from 'react-hook-form'

function TrackingNumControl({context:_context, orderObj:_orderObj}) {

	const [trackingNum, setTrackingNum] = useState("")
    const dispatch = useDispatch();
	const {status: trackingStatus, data: trackingReturn, error: trackingError} = useConsoleAddTracking({trackingNum: trackingNum, ID:_orderObj.ID})

	const { register, watch, handleSubmit, reset, clearErrors, formState: { errors } } = useForm();

	useEffect(()=>{

	    switch (trackingStatus) {

	      case 'loading':
	        dispatch( setLoading() )
	      break;

	      case 'done':
	        dispatch( clearLoading() )
			dispatch(setOrderTracking(_orderObj.ID, trackingNum))
	      break;
	      
	      case 'error':
	        dispatch( clearLoading() )
	        dispatch( setError( trackingError, 
	          ()=>{
	            // Error Callback
	            dispatch(clearError());
	          } ) )
	      break;

	      default: 

	    }

	},[trackingStatus])


	const onTrackingSubmit = data => {
		setTrackingNum(data.Tracking)
	};

	return (

		<form onSubmit={handleSubmit(onTrackingSubmit)} className='console-tracking-num-control'>
			<div className='input-group'>
				<input {...register("Tracking", { required: true })} placeholder='Tracking #' className='form-control'/>
				<button type="submit" className='btn-connected red'>MARK SHIPPED</button>
			</div>
		</form>

	)
}

export default TrackingNumControl
