import React, { useState } from 'react'
import './GTFooter.scss';
import { useTranslation } from 'react-i18next'


const GTFooter = (props) => {

    const { t, i18n } = useTranslation()

    const curLanguage = i18n.language.toUpperCase();

    if (curLanguage === 'EN') {
    	
    	return (

			<div className="gt-footer">

				<a href="https://www.golftown.com/en-CA/gt-promotions/">Golf Town Promotions</a><span> | </span>
				<a href="https://www.golftown.com/en-CA/privacy-policy.html">Privacy Policy</a><span> | </span>
				<a href="https://www.golftown.com/en-CA/accessibility.html">Accessibility</a><span> | </span>
				<a href="https://www.golftown.com/en-CA/terms-and-conditions.html">Terms & Conditions</a> 

			</div>

    	)

    } else {

    	return (

			<div className="gt-footer">

				<a href="https://www.golftown.com/fr-CA/promotion-gt/">Promotions Golf Town</a><span> | </span>
				<a href="https://www.golftown.com/fr-CA/privacy-policy.html">Politique de vie privée</a><span> | </span>
				<a href="https://www.golftown.com/fr-CA/accessibility.html">Accessibilité</a><span> | </span>
				<a href="https://www.golftown.com/fr-CA/terms-and-conditions.html">Programme d'affiliation</a> 

			</div>

    	)

	}

}

export default GTFooter



