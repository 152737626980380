import React, { useState } from 'react'
import './BuildSummaryHead.scss';
import { useTranslation } from 'react-i18next'


const BuildSummaryHead = (props) => {

    const { t, i18n } = useTranslation()

    const curLanguage = i18n.language.toUpperCase();

    	
	return (

		<div className="build-summary-head mt-5 mb-2">
			<table>
				<tbody>
					<tr className="text-uppercase">
						<th>{t('BUILD SUMMARY')}</th>
						<th className='d-none'><button onClick={()=>window.location.reload()} className="start-over-button">{t('Start Over')}</button></th>
					</tr>
				</tbody>
			</table>
		</div>

	)

}

export default BuildSummaryHead



