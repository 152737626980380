import React from 'react' 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ModalProduct(props) {
    const { events, changeSelectedProduct, product } = props;
    const { t, i18n } = useTranslation();
    const curLanguage = i18n.language.toUpperCase();

    return (
        <div className='col-6'>
            <div className='modal-product product-item'>
                <img src={`${product[`BoxImage${curLanguage}`]}`} alt="" />
                <h4>{product.BrandName}</h4>
                <h3>{product[`Name${curLanguage}`]}</h3>
                <p>
                    ${product.Price}
                </p>
                {product.CustCostFront12to23 === 0 ? <div className="free-customization-snipe">FREE<br/>CUSTOMIZATION</div> : null}
                <button className='button red-button' onClick={() => {
                    events.toggleproductChangeModal();
                    changeSelectedProduct(product);
                }}>
                {t('Select Ball')}
                </button>
            </div>
        </div>
    )
}

export default ModalProduct