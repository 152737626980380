import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PricingTable from '../PricingTable';
import './Modal.scss';
import { useTranslation } from 'react-i18next'

const ProductPriceModal = (props) => {
    const { t, i18n } = useTranslation()

    const curLanguage = i18n.language.toUpperCase();

    const {
        className,
        modal, 
        event,
        product
    } = props;

    const productNulCol = product ?? {BrandName: "", NameEN: "", NameFR: "", CustomizationPricing: {}}

    const closeBtn = <button className="close" onClick={event}>&times;</button>;

    return (
        <div>
            <Modal isOpen={modal} className={className}>
                <ModalHeader close={closeBtn}>
                    {t('Customization Pricing')} {t('(per dozen)')}&nbsp;-&nbsp; 
                    {productNulCol.BrandName} {productNulCol[`Name${curLanguage}`]}
                </ModalHeader>
                <ModalBody>
                    <p>
                        {t('CustomizationTableLeadin')} <a href="mailto:gtpromotions@golftown.com">{t('Contact us')}</a> {t('for more information.')}
                    </p>
                    <PricingTable product={product} />
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ProductPriceModal;