// Shipping Actions

import { 
    SET_SHIPPING_INFO,
} from './shipping.types';


export const setShippingInfo = (data) => {

  return {
    type: SET_SHIPPING_INFO,
    payload: data
  };

}
