import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useCropUpload = ({file, ID}) => {
    
    const [status, setStatus] = useState("idle");
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    
    const jwt = useSelector(state => state.app.jwt)

    useEffect(() => {

        if (typeof file === 'string' && file.trim().length > 0) {

            setStatus('loading')

            const uploadCrop = async () => {

                //setTimeout(async ()=>{
                    try {
 
                        const response = await axios.post(
                            `${endpoint}/logo/${ID}`,
                            { 'file': file },
                            { headers: 
                                {
                                    Authorization: `Bearer ${jwt}` 
                                }
                            }
                        )

                        setData(response.data.CroppedURL);
                        setStatus('done');

                    }
                    catch(e) {
                        setError('ERROR: ' + JSON.stringify(e));
                        setStatus('done');
                        throw "API ERROR – FILE COULD NOT BE COPIED"
                    }
                //}, 3000);

            };

            uploadCrop();

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }


    }, [file, ID]);

    return { status, data, error };

};

export default useCropUpload;