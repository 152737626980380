import React, { useState } from 'react'
import './GTNav.scss';
import { useTranslation } from 'react-i18next'


const GTNav = (props) => {

    const { t, i18n } = useTranslation()

    const curLanguage = i18n.language.toUpperCase();

    if (curLanguage === 'EN') {
    	
    	return (

			<nav id="gtnavigation">
				<ul className="menu-category level-1">

					<li>
						<a href="https://www.golftown.com/en-CA/new/">NEW</a>
					</li>

					<li>
						<a href="https://www.golftown.com/en-CA/clubs-1/">CLUBS</a>
					</li>

					<li>
						<a href="https://www.golftown.com/en-CA/balls/">BALLS</a>
					</li>

					<li>
						<a href="https://www.golftown.com/en-CA/shoes/">SHOES</a>
					</li>

					<li>
						<a href="https://www.golftown.com/en-CA/clothing/">CLOTHING</a>
					</li>

					<li>
						<a href="https://www.golftown.com/en-CA/bags-carts-1/">BAGS &amp; CARTS</a>
					</li>

					<li>
						<a href="https://www.golftown.com/en-CA/golf-tech/">GOLF TECH</a>
					</li>

					<li>
						<a href="https://www.golftown.com/en-CA/accessories-3/">ACCESSORIES</a>
					</li>


					<li>
						<a className="red-link" href="https://www.golftown.com/en-CA/demo-clubs/">DEMO CLUBS</a>
					</li>

					<li>
						<a className="red-link" href="https://www.golftown.com/en-CA/sale/">SALE</a>
					</li>

					<li>
						<a href="https://www.golftown.com/en-CA/custom-fit/">EXPERT SERVICES</a>
					</li>

				</ul>
			</nav>

    	)

    } else {

    	return (

			<nav id="gtnavigation">
				<ul className="menu-category level-1">

					<li>
						<a href="https://www.golftown.com/fr-CA/nouveautes/">NOUVEAUTÉS</a>
					</li>

					<li>
						<a href="https://www.golftown.com/fr-CA/b%C3%A2tons-1/">BÂTONS</a>
					</li>

					<li>
						<a href="https://www.golftown.com/fr-CA/balles-1/">BALLES</a>
					</li>

					<li>
						<a href="https://www.golftown.com/fr-CA/chaussures-3/">CHAUSSURES</a>
					</li>

					<li>
						<a href="https://www.golftown.com/fr-CA/v%C3%AAtements-1/">VÊTEMENTS</a>
					</li>

					<li>
						<a href="https://www.golftown.com/fr-CA/sacs-et-chariots-1/">SACS ET CHARIOTS</a>
					</li>

					<li>
						<a href="https://www.golftown.com/fr-CA/appareils-2/">APPAREILS</a>
					</li>

					<li>
						<a href="https://www.golftown.com/fr-CA/accessoires-3/">ACCESSOIRES</a>
					</li>

					<li>
						<a className="red-link" href="https://www.golftown.com/fr-CA/b%C3%A2tons-demo/">BÂTONS DÉMO</a>
					</li>

					<li>
						<a className="red-link" href="https://www.golftown.com/fr-CA/aubaines/">AUBAINES</a>
					</li>

					<li>
						<a href="https://www.golftown.com/fr-CA/ajustements-personnalises/">SERVICES D'EXPERTS</a>
					</li>

				</ul>
			</nav>
    	)

	}

}

export default GTNav



