import React, { useState, useEffect } from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { connect } from "react-redux";
import './Sidebar.scss'
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => ({
  products:               state.product.balls,
})


function Sidebar(props) {
  const [brandsArr, setBrandsArr] = useState(props.products.map(item => item.BrandID).filter((value, index, self) => self.indexOf(value) === index).map( id=>props.products.find(obj => obj.BrandID === id) ).map(obj => {return {BrandID: obj.BrandID,BrandName:obj.BrandName}}))
  const [filter, setFilter] = React.useState(0);
  const [sort, setSort] = React.useState(0);
  const { t, i18n } = useTranslation();

  const {
      filterProducts,
  } = props;

  const brands = props.products.map(item => item.BrandID).filter((value, index, self) => self.indexOf(value) === index).map( id=>props.products.find(obj => obj.BrandID === id) ).map(obj => {return {BrandID: obj.BrandID,BrandName:obj.BrandName}})

  useEffect(()=>{
    brands.sort((a,b)=>{
      var textA = a.BrandName.toUpperCase();
      var textB = b.BrandName.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
    setBrandsArr(brands)
  },[])



  function affectAllBrandButStates(on) {

    brandsArr.map(item => {
        var elementName = `manufacturer${item.BrandID}`;
        document.getElementById(elementName).checked = on;
    })

  }

  function changeOemArr(checkbox) {

    setTimeout(() => {
      setFilter(!filter);  
    }, 1000);

    if (checkbox != 'selectall') {
      document.getElementById('selectall').checked = false;
    }

    var newArr = [];

    newArr = brandsArr.map(item => {
        var elementName = `manufacturer${item.BrandID}`;
        if (document.getElementById(elementName).checked || document.getElementById("selectall").checked) {
          return item.BrandID;
        }
    })

    newArr = newArr.filter(function( element ) {
     return element !== undefined;
    });

    // If nothing in choices array, set all in array
    if (newArr.length === 0) {
      newArr = brandsArr.map(item => {
            return item.BrandID;
      })
      document.getElementById('selectall').checked = true;
      affectAllBrandButStates(true);
    }

    if (newArr.length === brandsArr.length) {
      document.getElementById('selectall').checked = true;
    }

    filterProducts(newArr);

  }

  function changeSort(order) {
    setTimeout(() => {
      setSort(!sort);  
    }, 1000);
    document.getElementById(order === 'lowtohigh' ? 'hightolow' : 'lowtohigh').checked = false;
    props.orderProducts(order);
  }

  return (
    <div className='left-sidebar'>
      <div className="category-filter">
        <h4 onClick={() => setFilter(!filter)}>{t('Filter by Brand')} {
          filter ? <FaAngleUp className='d-md-none ms-1 text-green' /> : <FaAngleDown className='text-green d-md-none ms-1' />
        } </h4>
        <form action="#">
          <ul className={`text-uppercase ${filter ? 'show-filter' : ''}`}>
            { brandsArr.map((brandObj, index)=>    
              <li className='d-flex align-items-center' key={index}>
                <label className='d-flex' htmlFor={`manufacturer${brandObj.BrandID}`}>
                  <input className='d-none' type="checkbox" id={`manufacturer${brandObj.BrandID}`} onClick={(e)=>changeOemArr(brandObj.BrandID)} />
                  <span>
                    <i>✓</i>
                  </span>
                  <span>
                    {brandObj.BrandName}
                  </span>
                </label>
              </li>
            ) }
            <li className='d-flex align-items-center'>
              <label className='d-flex' htmlFor="selectall">
                <input className='d-none' type="checkbox" id='selectall' onClick={(e)=>changeOemArr('selectall')} defaultChecked={true} />
                <span>
                  <i>✓</i>
                </span>
                <span>
                  {t('SHOW ALL')}
                </span>
              </label>
            </li>
          </ul>
        </form>
      </div>

      <div className="product-sort">
        <h4 onClick={() => setSort(!sort)}>{t('Sort by Price')} {
          sort ? <FaAngleUp className='d-md-none ms-1 text-green' /> : <FaAngleDown className='text-green d-md-none ms-1' />
        } </h4>
        <form action="#">
          <ul className={`text-uppercase ${sort ? 'show-sort' : ''}`}>

            <li className='d-flex align-items-center' key={0}>
            <label className='d-flex' htmlFor="hightolow">
              <input className='d-none' type="checkbox" id='hightolow' onClick={(e)=>changeSort('hightolow')} defaultChecked={true} />
              <span>
                <i>✓</i>
              </span>
              <span>
                {t('High to Low')}
              </span>
            </label>
            </li>

            <li className='d-flex align-items-center' key={1}>
            <label className='d-flex' htmlFor="lowtohigh">
              <input className='d-none' type="checkbox" id='lowtohigh' onClick={(e)=>changeSort('lowtohigh')} />
              <span>
                <i>✓</i>
              </span>
              <span>
                {t('Low to High')}
              </span>
            </label>
            </li>

          </ul>
        </form>
      </div>

    </div>
  )
}

export default connect(mapStateToProps)(Sidebar)