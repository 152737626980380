import React, { createRef, useEffect, useState } from 'react'
import Button from '../Buttons.js';
import ColorButton from '../customization/ColorButton.js';
import CustomizationBoxes from '../customization/CustomizationBoxes';
import ChangeBallBox from '../ChangeBallBox';
import BuildSummaryHead from '../BuildSummaryHead/BuildSummaryHead';
import { Link } from 'react-router-dom';
import './StepTwo.scss';
import { connect, useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import PageTitle from '../PageTitle'

import {
  setCurrentProduct, 
  setCurrentlyEditing, 
  setBallTexts,
  setColor,
  lockInText,
} from '../../redux/Order/order.actions'


const mapStateToProps = (state) => ({
  currentlyEditing:       state.order.currentlyEditing,
  currentItem:            state.order.product,
  allColors:              state.product.colors,
  order:                  state.order,
})


function ChooseAddText(props) {

  const {  
    currentlyEditing,
    currentItem, 
    order,
    allColors,
    events  } = props;

  const { 
    changeStep, 
    toggleproductChangeModal, 
    toggleProductPriceModal } = events;

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation()

  const list = createRef();

  function changeColor(color, e) {
    dispatch(setColor(color));
    const li = list.current.querySelectorAll('li');
    for (let i = 0; i < li.length; i++) {
      li[i].classList.remove('active');
      li[i].style.backgroundColor = 'transparent';
    }
    e.currentTarget.classList.add('active');
    e.currentTarget.style.backgroundColor = allColors[color].HexValue;
  }

  function resetColorButtons() {
    const li = list.current.querySelectorAll('li');
    for (let i = 0; i < li.length; i++) {
      li[i].classList.remove('active');
      li[i].style.backgroundColor = 'transparent';
    }
    li[0].classList.add('active');
    li[0].style.backgroundColor = allColors['black'].HexValue;
  }

  return (
    <div className='step-two mb-5 pb-lg-5'>
      <div className='container'>
        <PageTitle title={t('CUSTOMIZE YOUR BALL')} startover={t('Start Over')} />
        {/* 
          ADD TEXT COMPONENT 
        */}
        <div className="step-two-second row mt-4">
          <div className="col-lg-6">
            <div className='customize-left ms-lg-5 pt-lg-3 position-relative'>
              <img src={currentItem[`${currentlyEditing}Image`]} alt="" />
              <div style={{color: allColors[order[currentlyEditing].Color].HexValue}} className='ball-text text-center'>
                <p>{order[currentlyEditing].Line1}</p>
                <p>{order[currentlyEditing].Line2}</p>
                <p>{order[currentlyEditing].Line3}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className='customize-right ps-lg-2'>
              <div className="row">
                <div className="col-md-6">
                  <div className="add-text">
                    <h4>{t('Add Text')}</h4>
                    <input value={order[currentlyEditing].Line1} name='Line1' onChange={(e)=>dispatch(setBallTexts(e))} type="text" placeholder={t('Add Line 1')} />
                    <input value={order[currentlyEditing].Line2} name='Line2' onChange={(e)=>dispatch(setBallTexts(e))} type="text" placeholder={t('Add Line 2')} />
                    <input value={order[currentlyEditing].Line3} name='Line3' onChange={(e)=>dispatch(setBallTexts(e))} type="text" placeholder={t('Add Line 3')} />
                  </div>
                </div>
                <div className="col-md-6 pb-3 mt-4 mt-md-0">
                  <div className="select-color d-flex align-items-center d-sm-block">
                    <h4>{t('Select Colour')}</h4>
                    <div ref={list} className='d-flex justify-content-between'>
                      <ul className='d-flex d-sm-block'>
                        <ColorButton active="active" changeColor={changeColor} color='black' allColors={allColors} />
                        <ColorButton changeColor={changeColor} active={true} color='blue' allColors={allColors} />
                        <ColorButton changeColor={changeColor} active={true} color='green' allColors={allColors} />
                      </ul>
                      <ul className='d-flex d-sm-block'>
                        <ColorButton changeColor={changeColor} active={true} color='red' allColors={allColors} />
                        <ColorButton changeColor={changeColor} active={true} color='purple' allColors={allColors} />
                        <ColorButton changeColor={changeColor} active={true} color='orange' allColors={allColors} />
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 mt-lg-4 mb-3'>
                  <div className='border-top pt-4'>
                    <button onClick={()=> {
                      dispatch(lockInText())
                      currentlyEditing === 'Front' 
                        ? (()=>{
                            dispatch(setCurrentlyEditing('Back'))
                            changeStep(2) 
                          })() 
                        : changeStep(3)
                    }} disabled={order[currentlyEditing].Line1 || order[currentlyEditing].Line2 || order[currentlyEditing].Line3 ? false : true} className='button red-button'>{t('Continue')}</button>
                    <button onClick={()=> { changeStep(2) }} className='button cancel-button'>{t('Cancel')}</button>
                  </div>
                </div>
              </div>

              <BuildSummaryHead />
              <ChangeBallBox events={{
                toggleproductChangeModal,
                toggleProductPriceModal
              }} product={currentItem} />
              <CustomizationBoxes />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(ChooseAddText)
