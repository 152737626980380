import { Spinner } from 'reactstrap';
import { connect, useDispatch   } from "react-redux"

const mapStateToProps = (state) => ({
  loading:	state.app.loading,
})

function LoadingSpinner(props) {

	const isLoading = props.loading

	return (<div style={{
	              zIndex:99999,
	              position:'fixed',
	              padding:0,
	              margin:0,
	              top:0,
	              left:0,
	              width:'100%',
	              height:'100%',
	              background:'rgba(0,0,0,0.25)',
	              display: isLoading ? 'block' : 'none'
	            }}><Spinner color="green" style={{
	              position:'fixed',
	              top:'50%',
	              left:'50%',
	              marginTop:'-10px',
	              marginLeft:'-10px'
	            }}/></div>
	        )
}


export default connect(mapStateToProps)(LoadingSpinner)