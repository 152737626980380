import { Table, InputGroup, Button, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import Pagination from './parts/Pagination'
import OrderSummary from './parts/OrderSummary'
import Contact from './parts/Contact'
import PauseControl from './parts/PauseControl'
import CustomizationSummary from './parts/CustomizationSummary'
import { formatDate } from './parts/helperFunctions'
import { FaSort, FaDownload } from "react-icons/fa";
import './Paused.scss';


function Paused(props) {

	const products = useSelector(state => state.product.balls)
	const orders = useSelector(state => state.console.orders)
				   .filter(p => p.Paused === true)

	const [retailProNums, setRetailProNums] = useState({})

	const handleNewUpdate = (e) => {
      e.preventDefault();
	  const formData = new FormData(e.target);
	  const formProps = Object.fromEntries(formData);
	}

	const statusHash = {
		"INBOUND": "Inbound",
		"PRODUCTION": "In Production",
		"COMPLETE": "Complete"
	}

	const [dateSortForward, setDateSortForward] = useState(true)
	const [sortedOrders, setSortedOrders] = useState([])

	const sortOrders = () => {
		setSortedOrders ( orders.sort(function(a, b) {
		    var c = new Date(a.Updated);
		    var d = new Date(b.Updated);
		    return dateSortForward ? c-d : d-c;
		}) )
	}

	useEffect(()=>{
		sortOrders()
	},[dateSortForward, orders.length])

	return (
	    <div className="dashboard-paused">

			<Table className="paused-table" responsive striped >
			  <thead>
			    <tr>
			      <th className='sortable' onClick={()=>{setDateSortForward(!dateSortForward)}}>
			        Date <FaSort className='icons' />
			      </th>
			      <th>
			        Order Details
			      </th>
			      <th>
			        Shipping
			      </th>
			      <th>
			        Product
			      </th>
			      <th>
			        Customizations
			      </th>
			      <th>
			        Status
			      </th>
			      <th>
			      </th>
			    </tr>
			  </thead>

			  <tbody>
			  { sortedOrders.map((orderObj) => {

		  		const product = products.filter(product => product.ID === orderObj.ProductID)[0]

		  		return (
				    <tr key={orderObj.ID}>
				      <td>
				        {formatDate(orderObj.Updated)}
				      </td>
				      <td>
				        <OrderSummary orderObj={orderObj} context="PAUSED" />
				      </td>
				      <td>
				      	<Contact contactObj={orderObj.Billing.ShippingAddress} context="INBOUND" />
				      </td>
				      <td>
				        <p>
					        <b>{product.BrandName} {product.NameEN}</b>
						</p>
				      </td>
				      <td>
				      	<CustomizationSummary orderObj={orderObj} product={product} context="INBOUND" />
				      </td>
				      <td>
				        <p>
					        <b>{statusHash[orderObj.Status]}</b>
						</p>
				      </td>
				      <td>
				      	<PauseControl orderObj={orderObj} context="PAUSED" />
				      </td>
				    </tr>

					) })
			  }
			  </tbody>
			</Table>
			<Pagination />
		</div>
	)
}

export default Paused