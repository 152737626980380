import { useSearchParams } from 'react-router-dom'
import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import { Button } from 'reactstrap'
import ReactToPrint from 'react-to-print'
import OrderPreview from './OrderPreview'
import { FaPrint } from 'react-icons/fa'
import useConsoleProductLoader from '../../hooks/useConsoleProductLoader'
import useOpenOrderLoader from '../../hooks/useOpenOrderLoader'

import LoadingSpinner from '../../components/LoadingSpinner'
import './PreviewPrintContainer.scss'

function PreviewPrintContainer({obj: _obj, product: _product}) {

	var _context = 'EXTERNAL'

	const [product, setProduct] = useState(_product)
	const [order, setOrder] = useState(_obj)

	const [searchParams, setSearchParams] = useSearchParams()
	const colors = useSelector(state => state.product.colors)
	const products = useSelector(state => state.product.balls)

	const {skuStatus: _prodStatus, data: _prodData, error: _prodError} = useConsoleProductLoader()
	const {status: _orderStatus, data: _orderData, error: _orderError} = useOpenOrderLoader(searchParams.get("id"))

	/* 
		If nothing passed to the _obj parameter
		assume that this is coming from the query string
	*/


/* SAMPLE CALL 

http://localhost:3000/preview?frontType=LOGO&frontLine1=Test&frontLine2=Test2&frontLine3=Test3&frontLogo=be83f2bd-684d-4e80-9e32-a9a5900c1f38&frontColor=red&frontNotes=FRONTNOTESHERE&backType=TEXT&backLine1=Test&backLine2=Test2&backLine3=Test3&backLogo=5e57a5cc-9181-4f9b-86bc-b44eb80bb56e&backColor=red&backNotes=BACKNOTESHERE&src=&prodID=5&quantity=12&c=p

*/

/*
	if (searchParams.get("c") == 'p') {

		_context = 'EXTERNAL'

		_obj = {
			Front:{
				Type: searchParams.get("frontType"),
				src: `/api/v1/logos/${searchParams.get("frontLogo")}/cropped`,
				Line1: searchParams.get("frontLine1"),
				Line2: searchParams.get("frontLine2"),
				Line3: searchParams.get("frontLine3"),
				Color: searchParams.get("frontColor"),
				Logo: searchParams.get("frontLogo"),
				Notes: searchParams.get("frontNotes"),
				ID: searchParams.get("frontLogo")
			},
			Back:{
				Type: searchParams.get("backType"),
				src: `/api/v1/logos/${searchParams.get("backLogo")}/cropped`,
				Line1: searchParams.get("backLine1"),
				Line2: searchParams.get("backLine2"),
				Line3: searchParams.get("backLine3"),
				Color: searchParams.get("backColor"),
				Logo: searchParams.get("backLogo"),
				Notes: searchParams.get("backNotes"),
				ID: searchParams.get("backLogo")
			},
			Quantity: searchParams.get("quantity")
		}
		
	}
*/
/*
	useEffect(()=>{

			if (_prodStatus == 'done' && _orderStatus == 'done') {
				setProduct(
					products.filter((p)=>{
						return p.ID == _obj?.ProductID
					})[0]
				)
				_context = 'EXTERNAL'
			}

	},[_orderStatus, _prodStatus])
*/

	useEffect(()=>{

		if (_orderStatus == 'done') {
			setOrder(_orderData)
			setProduct(_orderData.Product)
		}

	},[_orderStatus])


	const printAreaRef = useRef()


	return (
	    <div className='preview'>
			<ReactToPrint
				trigger={() => <Button className='print-button'><FaPrint /> Print</Button>}
				content={() => printAreaRef.current}
			/>
			{product && "BrandName" in product
				? <OrderPreview ref={printAreaRef} obj={order} product={product} context={_context} />
				: <LoadingSpinner /> 
			}
	    </div>
	)

}

export default PreviewPrintContainer