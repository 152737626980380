// Billing Actions

import { 
    SET_BILLING_INFO,
    SET_SAME_AS_SHIPPING,
    BILLING_RESET_FOR_REPEAT,
} from './billing.types';


export const setBillingInfo = (data) => {

	return {
		type: SET_BILLING_INFO,
		payload: data
	};

}

export const setBillingInfoSameAsShipping = (data) => {

  return {
    type: SET_SAME_AS_SHIPPING,
    payload: data
  };

}

export const billingResetForRepeat = () => {

  return {
    type: BILLING_RESET_FOR_REPEAT,
  };

}