import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar';
import Products from '../Products/Products.js' 
import { connect, useDispatch   } from "react-redux";
import { useTranslation } from 'react-i18next';
import './StepOne.scss'

const mapStateToProps = (state) => ({
  currentlyEditing:       state.order.currentlyEditing,
  currentItem:            state.order.product,
  products:               state.product.balls,
})

function StepOne(props) {

  const {events,products} = props;
  const [triggerRerender, setTriggerRerender] = React.useState(0);

  const [filteredProds, setFilteredProds] = useState(products);
  const { t, i18n } = useTranslation();

  const curLanguage = i18n.language.toUpperCase();

  useEffect(()=>{

    orderProducts('hightolow')

  },[])

  function filterProducts(oemArr) {
      setFilteredProds( products.filter(prod => oemArr.includes(prod.BrandID)) );
  }

  function orderProducts(order) {
    // Need to sort all viewable products by price according to 'order' and alphabetically by name after that

    let sortedProducts = filteredProds.sort(
      (a, b) => {
          var textA = a[`Name${curLanguage}`].toUpperCase();
          var textB = b[`Name${curLanguage}`].toUpperCase();

          if (order === 'lowtohigh') {
              if (a.Price === b.Price){
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              } else {
                return a.Price - b.Price
              }
          } else {
              if (a.Price === b.Price){
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              } else {
                return b.Price - a.Price
              }
          }
        }
    )

    setFilteredProds(sortedProducts)

    setTriggerRerender(!triggerRerender)

  }

  return <div>
    <div className="container">
      <div className="row">
        <div className="col-lg-3 desktop-sidebar">
          <h3 className="text-uppercase">{t('Select Your Ball')}</h3>
          <Sidebar filterProducts={filterProducts} orderProducts={orderProducts} />
        </div>
        <div className="col-lg-9">
          <Products triggerRerender={triggerRerender} events={events} products={filteredProds} />
        </div>
      </div>
    </div>
  </div>

}

export default connect(mapStateToProps)(StepOne)