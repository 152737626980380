import { Navigate } from "react-router-dom"
import { useSelector } from 'react-redux'

function ProtectedRoute({ children })  {

  const isAuthenticated = useSelector(state => state.app.isAuthenticated)

  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/console" />
  }
  return children;
}

export default ProtectedRoute