import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

//const axios = require('axios')
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useConsoleCreateProductionOrder = ({ID:_ID, RpRct:_RpRct,RpCust:_RpCust}) => {
    
    const [status, setStatus] = useState("idle")
    const [data, setData] = useState([])
    const [error, setError] = useState("")

    const jwt = useSelector(state => state.app.jwt)

    useEffect(() => {

        if (_RpRct != "" && _RpCust != "") {
            
            setStatus('loading')

            const createProductionOrder = async () => {

                const sendData = JSON.stringify( { "RpRct": _RpRct, "RpCust": _RpCust } )

                //setTimeout(async ()=>{
                    try {
 
                        const response = await axios.post(
                            `${endpoint}/adm/orders/${_ID}/retailpro`,
                            sendData,
                            { headers: 
                                {
                                    Authorization: `Bearer ${jwt}` 
                                }
                            }
                        )

                        setData(response.data)
                        setStatus('done')
                    }
                    catch(e) {
                        setError('Retail Pro info could not be added.')
                        setStatus('error')
                        throw "API ERROR – RETAIL PRO DATA COULD NOT BE UPLOADED"
                    }
                //}, 3000);

            };

            createProductionOrder()

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }

    }, [_RpRct])

    return { status, data, error }

};

export default useConsoleCreateProductionOrder