import { connect, useDispatch } from "react-redux"
import useAuthenticator from '../hooks/useAuthenticator'
import { useState, useEffect } from 'react'
import { setError, clearError, setLoading, clearLoading } from '../redux/App/app.actions'
import ErrorModal from '../components/Modal/ErrorModal.js'
import LoadingSpinner from '../components/LoadingSpinner'
import { useForm } from 'react-hook-form';
import './Console.scss';
import Dashboard from './Dashboard'
import { useNavigate } from "react-router-dom";
import Logo from '../components/Logo/Logo';

const mapStateToProps = (state) => ({
  isAuthenticated: state.app.isAuthenticated,
})


function Login(props) {

	const [loginObj, setLoginObj] = useState({username:"",password:""})
	const {status: authenticationStatus, data: authenticationData, error: authenticationError} = useAuthenticator(loginObj)

	const dispatch = useDispatch()

  let navigate = useNavigate();


	const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

	const onSubmit = data => {
		setLoginObj({username:data.Username,password:data.Password})
	};

	useEffect(()=>{

		switch (authenticationStatus) {

		  case 'loading':
		    dispatch( setLoading() )
		  break;

		  case 'done':
		    dispatch( clearLoading() )
		    navigate(`/console/dashboard/inbound`)
		  break;
		  
		  case 'error':
		    dispatch( clearLoading() )
		    dispatch( setError( authenticationError, 
		      ()=>{
		        // Error Callback
		        dispatch( clearError() );
		      } ) )
		    setLoginObj({username:"",password:""})
		  break;

		  default: 

		}

	},[authenticationStatus])

	return (

        <div className="loginForm mb-2">
        	<Logo />
          <form onSubmit={handleSubmit(onSubmit)}>
          	<div className="formBody">
                <h4 className='mb-3 mt-3'>
                  Enter Login Details
                </h4>
							<div className='mb-3'>
								<div className={`input-box`}>
								  <label className='text-bold text-uppercase' htmlFor="Username">
								      Username
								  </label>
								  <input {...register("Username", { required: true })} className='w-100' />
								  <span className="error">{errors.Username?.type === 'required' && "Username is required"}</span>
								</div>
							</div>
							<div className='mb-3'>
								<div className={`input-box`}>
								  <label className='text-bold text-uppercase' htmlFor="Username">
								      Password
								  </label>
								  <input type="password" {...register("Password", { required: true })} className='w-100' />
								  <span className="error">{errors.Password?.type === 'required' && "Password is required"}</span>
								</div>
							</div>
						</div>
						<input type="submit" className='button red-button' value='Login' />
          </form>
        </div>

	)
		
}


export default connect(mapStateToProps)(Login)