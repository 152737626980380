import { combineReducers } from 'redux';
import billingReducer from './Billing/billing.reducer';
import shippingReducer from './Shipping/shipping.reducer';
import orderReducer from './Order/order.reducer';
import productReducer from './Product/product.reducer';
import appReducer from './App/app.reducer';
import consoleReducer from './Console/console.reducer';


const rootReducer = combineReducers({

    billing: billingReducer,
    shipping: shippingReducer,
    order: orderReducer,
    product: productReducer,
    app: appReducer,
    console: consoleReducer,

});

export default rootReducer;
