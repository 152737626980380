import {   
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  Badge
 } from 'reactstrap';
import { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { NavLink as RRNavLink } from 'react-router-dom';
import { useSelector } from "react-redux"
import './Tabbar.scss'


function Tabbar(props) {

  const [dropdownOpen, setDropdownOpen] = useState(true)
  const toggle = () => setDropdownOpen(!dropdownOpen)
  const location = useLocation()

	const adminGroupIndex = useSelector(state => state.app.adminGroup)

	const orders = useSelector(state => state.console.orders)

	const groupByStatus = orders.reduce((group, order) => {
	    const { Status, Paused } = order
	    if (!Paused && order.BamboraPreAuth.ID ) {
		    group[Status] = group[Status] ?? [];
		    group[Status].push(order);
	    } else {
		    group['Paused'] = group['Paused'] ?? [];
		    group['Paused'].push(order);
	    }
	    return group
	}, {});

	const numPaused = orders.filter(order=>order.Paused == true).length
	const numInbound = orders.filter(o => (o.Status === 'INBOUND' || o.Status === 'RISKIFIED_DECLINED') && (o.Paused === false) && (o.BamboraPreAuth.Type === 'PAC')).length

	const numberStatus = {
		"PRODUCTION" : 'PRODUCTION' in groupByStatus ? groupByStatus["PRODUCTION"]?.length : 0,
		//"INBOUND" : 'INBOUND' in groupByStatus ? groupByStatus["INBOUND"]?.length + groupByStatus["RISKIFIED_DECLINED"]?.length : 0,
		"INBOUND" : numInbound,
		"SHIPPED" : 'SHIPPED' in groupByStatus ? groupByStatus["SHIPPED"]?.length : 0,
		"ARCHIVED" : 'ARCHIVED' in groupByStatus ? groupByStatus["ARCHIVED"]?.length : 0,
		"COMPLETED" : 'COMPLETED' in groupByStatus ? groupByStatus["COMPLETED"]?.length : 0,
		"PAUSED" : numPaused
	}

	return (
    <div className="tabbar mt-1">
	    <Nav tabs>
	      <NavItem>
	        <NavLink to="/console/dashboard/inbound" active={location.pathname === '/console/dashboard/inbound' ? true : null} tag={RRNavLink}>
	          Inbound Orders{numberStatus['INBOUND'] > 0 ? <span>&nbsp;&nbsp;<Badge color="secondary">{numberStatus['INBOUND']}</Badge></span>:null}

	        </NavLink>
	      </NavItem>
	      <NavItem>
	        <NavLink to="/console/dashboard/production" active={location.pathname === '/console/dashboard/production' ? true : null} tag={RRNavLink}>
	          Production Queue{numberStatus['PRODUCTION'] > 0 ? <span>&nbsp;&nbsp;<Badge color="primary">{numberStatus['PRODUCTION']}</Badge></span>:null}
	        </NavLink>
	      </NavItem>
	      <NavItem>
	        <NavLink to="/console/dashboard/shipped" active={location.pathname === '/console/dashboard/shipped' ? true : null} tag={RRNavLink}>
	          Shipped Orders{numberStatus['SHIPPED'] > 0 ? <span>&nbsp;&nbsp;<Badge color="info">{numberStatus['SHIPPED']}</Badge></span>:null}
	        </NavLink>
	      </NavItem>
	      <NavItem>
	        <NavLink to="/console/dashboard/completed" active={location.pathname === '/console/dashboard/completed' ? true : null} tag={RRNavLink}>
	          Completed Orders{numberStatus['COMPLETED'] > 0 ? <span>&nbsp;&nbsp;<Badge color="success">{numberStatus['COMPLETED']}</Badge></span>:null}
	        </NavLink>
	      </NavItem>
	      <NavItem>
	        <NavLink to="/console/dashboard/paused" active={location.pathname === '/console/dashboard/paused' ? true : null} tag={RRNavLink}>
	          Paused Orders{numPaused > 0 ? <span>&nbsp;&nbsp;<Badge color="danger">{numPaused}</Badge></span>:null}
	        </NavLink>
	      </NavItem>
	      <NavItem>
	        <NavLink to="/console/dashboard/archived" active={location.pathname === '/console/dashboard/archived' ? true : null} tag={RRNavLink}>
	          Archived Orders{numberStatus['ARCHIVED'] > 0 ? <span>&nbsp;&nbsp;<Badge color="dark">{numberStatus['ARCHIVED']}</Badge></span>:null}
	        </NavLink>
	      </NavItem>
	      {adminGroupIndex === 0 ? <NavItem>
	        <NavLink to="/console/dashboard/products" active={location.pathname === '/console/dashboard/products' ? true : null} tag={RRNavLink}>
	          Products
	        </NavLink>
	      </NavItem> : null }
	    </Nav>
    </div>

	)
	
}

export default Tabbar