import './PricingTable.scss';
import { useTranslation } from 'react-i18next'
import { currencyFormatter } from '../helpers/currencyFormatter'

function PricingTable(props) {
    const { t, i18n } = useTranslation()

    const product = props.product

    /*const productNulCol = product ?? {
      BrandName: "", 
      NameEN: "", 
      NameFR: "", 
      CustomizationPricing: {
        Poles: [
          {
            Base: null,
            Break1: null,
            Break2: null,
            Break3: null,
          },
          {
            Base: null,
            Break1: null,
            Break2: null,
            Break3: null,
          }

        ]
      }
    }
    */


    /*

      Need to be aware of current ball selection (if any) or 
      be passed a product ID via props.


    */



    return (
        <div className='pricing-table'>
        <table>
          <tbody>
          <tr className='text-uppercase'>
              <th>
                  {t('Quantity (p/dz)')}
              </th>
              <th>
                  {t('Front')}
              </th>
              <th>
                  {t('Back')}
              </th>
          </tr>
          <tr>
              <td>
                  12-23
              </td>
              <td>
                  {product.CustCostFront12to23 === 0 ? t('FREE') : currencyFormatter(i18n).format( product.CustCostFront12to23 )}
              </td>
              <td>
                  {product.CustCostBack12to23 === 0 ? t('FREE') : currencyFormatter(i18n).format( product.CustCostBack12to23 )}
              </td>
          </tr>
          <tr>
              <td>
                  24-47
              </td>
              <td>
                  {product.CustCostFront24to47 === 0 ? t('FREE') : currencyFormatter(i18n).format( product.CustCostFront24to47 )}
              </td>
              <td>
                  {product.CustCostBack24to47 === 0 ? t('FREE') : currencyFormatter(i18n).format( product.CustCostBack24to47 )}
              </td>
          </tr>
          <tr>
              <td>
                  48-119
              </td>
              <td>
                  {product.CustCostFront48to119 === 0 ? t('FREE') : currencyFormatter(i18n).format( product.CustCostFront48to119 )}
              </td>
              <td>
                  {product.CustCostBack48to119 === 0 ? t('FREE') : currencyFormatter(i18n).format( product.CustCostBack48to119 )}
              </td>
          </tr>
          <tr>
              <td>
                  119+
              </td>
              <td>
                  {product.CustCostFrontOver120 === 0 ? t('FREE') : currencyFormatter(i18n).format( product.CustCostFrontOver120 )}
              </td>
              <td>
                  {product.CustCostBackOver120 === 0 ? t('FREE') : currencyFormatter(i18n).format( product.CustCostBackOver120 )}
              </td>
          </tr>
          {/*<tr>
              <td>
                  48-119
              </td>
              <td>
                  {currencyFormatter(i18n).format( product.CustomizationPricing.Poles[0].Break4 )}
              </td>
              <td>
                  {currencyFormatter(i18n).format( product.CustomizationPricing.Poles[1].Break4 )}
              </td>
          </tr>
          <tr>
              <td>
                  48-119
              </td>
              <td>
                  {t('FREE')}
              </td>
              <td>
                  {currencyFormatter(i18n).format( 5.00 )}
              </td>
          </tr>
          <tr>
              <td>
                  120+
              </td>
              <td colSpan={3} className='contactUs'>
                  <a href="mailto:gtpromotions@golftown.com">Contact us</a> for product and customization pricing.
              </td>
          </tr>*/}
          </tbody>
        </table>
        </div>
    )
}

export default PricingTable