import { Table, InputGroup, Button, Input, Container, Row, Col } from 'reactstrap';
import { useState, useRef, forwardRef } from 'react'
import { useSelector } from "react-redux"
import ViewFace from '../../components/ViewFace'
import { currencyFormatter } from '../../helpers/currencyFormatter'
import { formatDate } from '../parts/helperFunctions'
import Logo from '../../components/Logo/Logo';
import './OrderPreview.scss';

const endpoint = process.env.REACT_APP_API_ENDPOINT


const OrderPreview = forwardRef((props, ref) => {

	const _obj = props.obj
	const _product = props.product
	const _context = props.context

	const colors = useSelector(state => state.product.colors)

	return (
		<div ref={ref}>
		<Container className='preview'>
		  <Row>
		  	<Col >
			  	<Logo />
			  	<h2>PREVIEW</h2>
		  	</Col>
		  </Row>
		  <Row>
		    <Col>
		      <h3>Product Details</h3>
		      <p>
		      {_obj.Quantity} Dozen<br />
		      {_product.BrandName} {_product.NameEN}
		      </p>
		    </Col>
		  </Row>

		  <Row md="2" sm="1" xs="1">
		    <Col className='text-center'>
		    	<h3>Preview - Front</h3>
			    <ViewFace context={_context} obj={_obj} face='Front' product={_product} />
				{ _obj.Front.Notes != "" ? <p>{_obj.Front.Notes}</p> : null }
		    </Col>
		    <Col className='text-center'>
		    	<h3>Preview - Back</h3>
				<ViewFace context={_context} obj={_obj} face='Back' product={_product} />
				{ _obj.Back.Notes != "" ? <p>{_obj.Back.Notes}</p> : null }
		    </Col>
		  </Row>
		</Container>
		</div>

)});

export default OrderPreview