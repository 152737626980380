import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import { loadSkusToState } from '../redux/Product/product.actions'
import { setError, clearError, setLoading, clearLoading } from '../redux/App/app.actions'

//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT


const useConsoleProductLoader = () => {

    const [skuStatus, setSkuStatus] = useState('loading');
    const [data, setData] = useState([]);
    const [error, setError] = useState("");

    const dispatch = useDispatch()

    useEffect(() => {

        const fetchSkus = async () => {

            dispatch(setLoading())

            try {

                const {data:response} = await axios.get(
                    `${endpoint}/sku`,
                    { 
                        headers: { } 
                    }
                )

                dispatch(loadSkusToState(response));
                setSkuStatus('done');
                setData(response);

            } catch {

                dispatch( setError( "Could not load products", 
                  ()=>{
                    // Error Callback
                    dispatch(clearError())
                  } ) )
                throw "API ERROR – COULD NOT GET SKUS"

            }

        };

        fetchSkus()
        .then( dispatch(clearLoading()) )


    }, []);

    return { skuStatus, data, error }

};

export default useConsoleProductLoader