import React from 'react'
import { useTranslation } from 'react-i18next'

function ColorButton({ changeColor, color, allColors, active }) {
    const { t, i18n } = useTranslation()

    return (
        <li onClick={changeColor.bind(null,color)} className={`d-flex ${active}`} style={active === 'active' ? { background: allColors[color].HexValue }: null}>
            <span style={{ background: allColors[color].HexValue }}></span>
            <span className='pt-1 text-uppercase text-bold align-self-center ps-2'>
                {allColors[color][`Name${i18n.language.toUpperCase()}`]}
            </span>
        </li>
    )
}

export default ColorButton
