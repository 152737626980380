// App actions

import { 
	SET_CONTENTMODAL,
	SET_AUTHENTICATED,
	SET_JWT,
	SET_ERROR,
	CLEAR_ERROR,
	SET_LOADING,
	CLEAR_LOADING,
} from './app.types'

export const setJWT = (jwt) => {

	return {
		type: SET_JWT,
		payload: jwt
	};

}


export const setContentModal = (_content) => {

	return {
		type: SET_CONTENTMODAL,
		payload: {
			body: _content.body !== undefined ? _content.body : "",
			title: _content.title !== undefined ? _content.title : ""
		}
	};

}

export const setAuthenticated = (isAuthenticated,adminGroup,jwt) => {

	return {
		type: SET_AUTHENTICATED,
		payload: {
			isAuthenticated: isAuthenticated,
			adminGroup: adminGroup,
			jwt: jwt
		}
	};

}



export const setError = (errorMessage,errorCallback) => {

	return {
		type: SET_ERROR,
		payload: {
			errorMessage: errorMessage,
			errorCallback: errorCallback
		}
	};

}



export const clearError = () => {

	return {
		type: CLEAR_ERROR
	};

}



export const setLoading = () => {

	return {
		type: SET_LOADING,
	};

}



export const clearLoading = () => {

	return {
		type: CLEAR_LOADING
	};

}