import React, { createRef, useEffect, useState } from 'react'
import ballFront from '../customization/ball-front.png'
import ballBack from '../customization/ball-back.png'
import Button from '../Buttons.js';
import ColorButton from '../customization/ColorButton.js';
import BuildSummaryHead from '../BuildSummaryHead/BuildSummaryHead';
import ChangeBallBox from '../ChangeBallBox';
import ImgSizeSlider from '../ImgSizeSlider';
import { Link } from 'react-router-dom';
import './EditCustomization.scss';
import Cropper from 'cropperjs';
import "cropperjs/dist/cropper.css";


import { connect, useDispatch   } from "react-redux"
import { //setCurrentProduct, 
  //setCurrentlyEditing,
  //setLogoAndLogoName,
  setCroppedLogoSrc
} from '../../redux/Order/order.actions'

const mapStateToProps = (state) => ({
  currentlyEditing:       state.order.currentlyEditing,
  currentItem:            state.order.product,
  allColors:              state.product.colors,
  order:                  state.order,
})


function EditCustomizationLogo(props) {

  const {  
    currentlyEditing,
    currentItem, 
    order,
    allColors,
    events  } = props;

  const { 
    changeStep, 
    toggleproductChangeModal, 
    toggleProductPriceModal } = events;

  const dispatch = useDispatch();

  const list = createRef();
  let stepTwoThird = createRef();
  let stepTwoFirst = createRef();
  let logoContinueButton = createRef();

  const [uploadTriggered, setUploadTriggered] = useState(false);

  function showAddLogo() {
    stepTwoThird.current.classList.remove('d-none');
    stepTwoFirst.current.classList.add('d-none');
  }

  const cropperImg = document.getElementById('cropperImage');
  let cropper;

  function getRoundedCanvas(sourceCanvas) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();
    return canvas;
  }

  function setCrop() {
    var result = document.getElementById('croppedLogo');

    var croppedCanvas;
    var roundedCanvas;
    var roundedImage;

    // Crop
    croppedCanvas = cropper.getCroppedCanvas();

    // Round
    roundedCanvas = getRoundedCanvas(croppedCanvas);

    // Show
    roundedImage = document.createElement('img');
    roundedImage.src = roundedCanvas.toDataURL();
    
    //Set State in App.js for croppedLogoX [x is 1 or 2 for front or back]
    dispatch(setCroppedLogoSrc(roundedImage.src));

    //result.innerHTML = '';
    //result.appendChild(roundedImage);
  };

  function changeImgScale(value) {
    cropper.zoom(value)
  }

  useEffect(()=> {

    if (order[currentlyEditing].logo) {

      cropper = new Cropper(cropperImg, {
        aspectRatio: 1 / 1,
        guides: false,
        background: false,
        modal: false,
        moveable: true,
        scaleable: true,
        cropBoxMoveable:false,
        cropBoxResizable:false,
        zoomOnTouch: true,
        minContainerHeight: 234,
        minContainerWidth: 234,
        minCanvasWidth: 10,
        minCanvasHeight: 10,
        minCropBoxWidth: 234,
        minCropBoxHeight: 234,
        //autoCropArea: 1,
        dragMode: 'move',
        crop(event) {
          //var canvasData = cropper.getCanvasData();
        },
      });


    }

  },[order[currentlyEditing].logo]);

  //const image = products[currentItem].image;
  return (
    <div className='step-two mb-5 pb-lg-5'>
      <div className='container'>
        <h3 className='customize-title mt-4 mb-4 text-center text-md-start font-weight-bold'>
          <strong>CUSTOMIZE YOUR BALL</strong>
        </h3>
        <div ref={stepTwoFirst} className="step-two-first one row align-items-center">
          <div className="col-lg-6">
            <div className='customize-right one'>
              <h4 className='customize-subtitle text-center text-md-start'>
                Replace Logo
              </h4>
              <p className='text-center text-md-start mt-3 pt-1 mb-4 pb-2'>
                Select a new logo file.
              </p>
              <img className='d-md-none' src={currentItem.FrontImage} alt="" />
                      <input className='d-none' onChange={e => {
                        //const logoURL = URL.createObjectURL(e.target.files[0]);
                        //dispatch(setLogoAndLogoName(logoURL, e.target.files[0].name))
/**************** THIS NEEDS TO BE FIXED
                        dispatch(vectorSend(e.target.files[0], e.target.files[0].name));
***************/
                        showAddLogo();
                      }} id='upload-logo' type='file' />
                      <button className='button red-button'>
                        <label htmlFor="upload-logo">
                          Add Logo
                        </label>
                      </button>
            </div>
          </div>
        </div>
        {/* 
          EDIT LOGO COMPONENT 
        */}
        <div ref={stepTwoThird} className="step-two-third row mt-4 d-none">
          <div className="col-lg-5">
            <div className='customize-left ms-lg-4 pt-lg-3 position-relative'>
              <img src={currentItem[`${currentlyEditing}Image`]} alt="" />
              <div className='ball-image'>
                {/* THIS IS WHERE THE SCALABLE IMAGE GOES */}
                <div><img src={order[currentlyEditing].logo} alt="" id="cropperImage" /></div>
              </div>
              <ImgSizeSlider changeImgScale={changeImgScale} />
            </div>
          </div>
          <div className="col-lg-7 ps-lg-5">
            <div className='customize-right ps-lg-4'>
              <h4>Adjust Logo</h4>
              <p className='text-center text-sm-start'>
                Size your logo to fit in the circular area.
              </p>
              <div className='logo-checkbox-wrapper'>
                <label htmlFor="logo-checkbox" className='d-flex align-items-start'>
                  <input onChange={e => {
                    const button = logoContinueButton.current.querySelector('button');
                    button.disabled = !e.target.checked;
                    button.classList.toggle('red-button');
                  }} type="checkbox" id='logo-checkbox' />
                  <p>I verify that I own the copyright and/or have permission to use the uploaded image.</p>
                </label>
              </div>
              <div ref={logoContinueButton} className='text-center text-sm-start'>
                <button onClick={e => {
                  if (!e.target.classList.contains('red-button')) return;
                  var _tempFront = order.Front.Type; 
                  setCrop();
                  changeStep(3);
                }} className='logo-continue'>
                  Continue
                </button>
              </div>
              <div className='mt-4'>
                <BuildSummaryHead />
                <ChangeBallBox events={{
                  toggleproductChangeModal,
                  toggleProductPriceModal
                }} product={currentItem} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EditCustomizationLogo)