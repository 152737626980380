import React, { createRef, useEffect, useState } from 'react'
import ballFront from '../customization/ball-front.png'
import ballBack from '../customization/ball-back.png'
import Button from '../Buttons.js';
import BuildSummaryHead from '../BuildSummaryHead/BuildSummaryHead';
import ChangeBallBox from '../ChangeBallBox';
import CustomizationBoxes from '../customization/CustomizationBoxes';
import { Link } from 'react-router-dom';
import './StepTwo.scss';
import { connect, useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import PageTitle from '../PageTitle'

import {
  setCurrentlyEditing, 
  setSameAsFront
} from '../../redux/Order/order.actions'

const mapStateToProps = (state) => ({
  currentlyEditing:       state.order.currentlyEditing,
  currentItem:            state.order.product,
  allColors:              state.product.colors,
  order:                  state.order,
})


function ChooseCustomization(props) {

  const {  
    currentlyEditing,
    currentItem, 
    order,
    allColors,
    events  } = props;

  const { 
    changeStep, 
    toggleproductChangeModal, 
    toggleProductPriceModal } = events;

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation()

  return (
    <div className='step-two mb-5 pb-lg-5'>
      <div className='container'>
        <PageTitle title={t('CUSTOMIZE YOUR BALL')} startover={t('Start Over')} />
        <div className="step-two-first customize-back-side row">
          <div className="col-xl-6 d-none d-md-block">
            <div className='customize-left'>
              <div className='mx-auto mx-xl-0'>
                <div>
                  <img src={currentItem.BackImage} alt='' />
                  { currentlyEditing === 'Front' 
                  ? <p className="ball-demo-text">
                    <span>{t('SAMPLE-TEXT-1')}</span>
                    <span>{t('SAMPLE-TEXT-2')}</span>
                    <span>{t('SAMPLE-TEXT-3')}</span>
                  </p> 
                  : null }
                </div>
                <div>
                  <img src={currentItem.FrontImage} alt='' />
                  { order.Front.Type == 'LOGO' ?
                  <div className='logo' id="croppedLogo" style={{mixBlendMode: "multiply"}}>
                    <img src={order.Front.src} alt='' />
                  </div> : null }
                  { order.Front.Type == 'TEXT' ?
                  <p className=" ball-demo-text" style={{color: allColors[order.Front.Color].HexValue}}>
                    <span>{order.Front.Line1}</span>
                    <span>{order.Front.Line2}</span>
                    <span>{order.Front.Line3}</span>
                  </p> : null }
                  { order.Front.Type === 'NONE' && order.Back.Type === 'NONE' 
                  ? <div className='logo'>
                      <img src={`/images/app/your-logo-${i18n.language}.png`} alt='Logo' />
                    </div> 
                  : null }
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className='customize-right one'>
              <h4 className='customize-subtitle text-center text-md-start'>
                { order.Front.Type === 'NONE' && order.Back.Type === 'NONE' 
                ? t('Choose Customization') 
                : t('OPTIONAL – Customize the Back Side') }
              </h4>
              <p className='text-center text-md-start mt-3 pt-1 mb-4 pb-2'>
                { order.Front.Type === 'NONE' && order.Back.Type === 'NONE' 
                ? t('PAGE2-BODY-1')
                : t('OPTIONAL-DESCRIPTION-1') }
                <strong className='d-block'>{t('OPTIONAL-DESCRIPTION-2')}</strong>
              </p>
              <div className="add-text-logo">
                <div className="row">
                  <div className="col-6 col-md-12 mb-2 pb-1">
                    <div>
                      <div className='mobile position-relative d-md-none'>
                        <img src={currentItem.FrontImage} alt='' />
                        { order.Front.Type === 'NONE' && order.Back.Type === 'NONE' 
                        ? <div className='logo'>
                            <img src={`/images/app/your-logo-${i18n.language}.png`} alt='Logo' />
                          </div> 
                        : null }
                      </div>
                      <div className='d-none d-md-block'>
                        <Button onClick={()=>{changeStep(21)}} type="red-button" text={t('Add Text')} />
                      </div>

                    </div>
                  </div>
                  <div className="col-6 col-md-12">
                    <div>
                      <div className='mobile position-relative d-md-none'>
                        <img src={currentItem.BackImage} alt='' />
                        { order.Front.Type == 'LOGO' ?
                        <div className='logo position-absolute' id="croppedLogo" style={{mixBlendMode: "multiply"}}>
                          <img src={order.Front.src} alt='' />
                        </div> : null }
                        { order.Front.Type == 'TEXT' ?
                        <p className='position-absolute ball-demo-text' style={{color: allColors[order.Front.Color].HexValue}}>
                          <span>{order.Front.Line1}</span>
                          <span>{order.Front.Line2}</span>
                          <span>{order.Front.Line3}</span>
                        </p> : null }
                        { order.Front.Type === 'NONE' && order.Back.Type === 'NONE' ?
                        <p className="position-absolute ball-demo-text">
                          <span>{t('SAMPLE-TEXT-1')}</span>
                          <span>{t('SAMPLE-TEXT-2')}</span>
                          <span>{t('SAMPLE-TEXT-3')}</span>
                        </p> : null }
                      </div>
                      <button onClick={()=>changeStep(22)} className='button red-button d-none d-md-block'>
                        <label htmlFor="upload-logo2">
                          {t('Add Logo')}
                        </label>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='text-center text-center text-md-start'>
                  <div className='mt-2 pt-1'>
                    <div className='d-md-none'>
                      <div className='mb-2 pb-1'>
                        <Button onClick={()=>{changeStep(21)}} type="red-button" text={t('Add Text')} />
                      </div>
                      <div className="mb-2 pb-1 ">
                        <button onClick={()=>{changeStep(22)}} className='button red-button'>
                          <label htmlFor="upload-logo2">
                            {t('Add Logo')}
                          </label>
                        </button>
                      </div>
                    </div>
                    { order.Front.Type != 'NONE' && order.Back.Type === 'NONE' ?
                    <>
                    <div className="mb-2 pb-1 ">
                      <button onClick={() => {
                        dispatch(setSameAsFront());
                        dispatch(setCurrentlyEditing('Back'));
                        changeStep(3);
                      }} className='button red-button'>
                        {t('Same As Front')}
                      </button>
                    </div>
                    <div>
                      <button onClick={() => {
                        changeStep(3);
                      }} className='button red-button mb-2'>
                        {t('No further customizations required')}
                      </button>
                    </div>
                    </> : null }
                  </div>
                </div>
              </div>
              <br />

              <div className='mt-4 mb-4' style={{
                display: (order[currentlyEditing].Line1 || order[currentlyEditing].Line2 || order[currentlyEditing].Line3) ? 'block' : 'none'
              }}>
              </div>
              <BuildSummaryHead />
              <ChangeBallBox events={{
                toggleproductChangeModal,
                toggleProductPriceModal
              }} product={currentItem} />
              <CustomizationBoxes />
            </div>
          </div>
        </div>
      </div>
    </div>



  )
}

export default connect(mapStateToProps)(ChooseCustomization)