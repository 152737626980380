import React, { useState } from 'react'
import info from './info.svg';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../../helpers/currencyFormatter'

function Product(props) {
    const { events, changeSelectedProduct, product } = props;
    const [select, setSelect] = useState(false);
    const { t, i18n } = useTranslation();
    const curLanguage = i18n.language.toUpperCase();

    return (
        <div className="col-6 col-lg-4">
            <div className={`product-item ${select ? 'selected' : ''}`} onClick={select ? setSelect.bind(null, false) : null} >
                <div>
                    {
                        !select && <button onClick={setSelect.bind(null, !select)} className='button green-button text-center'>
                            {t('View Description')}
                        </button>
                    }

                    <img src={`${product[`BoxImage${curLanguage}`]}`} alt="" />
                </div>

                <h4>{product.BrandName}</h4>
                <h3>{product[`Name${curLanguage}`]}</h3>
                {
                    select && <p>
                        {product[`Description${curLanguage}`]}
                    </p>
                }
                <button onClick={()=>events.toggleProductPriceModal(product)} className='info-button d-flex text-black align-items-center'>
                    <img className='me-1 me-sm-2' src={info} alt="" />
                    <span className='ps-1 pt-1'>
                        {currencyFormatter(i18n).format( product.Price )} <span className='text-green-2'>
                            + {t('Customization')}
                        </span>
                    </span>
                </button>

                {product.CustCostFront12to23 === 0 ? <div className="free-customization-snipe">FREE<br/>CUSTOMIZATION</div> : null}

                <button onClick={() => {
                    changeSelectedProduct(product);
                    events.changeStep(2);
                }
                } className='button red-button text-center'>
                    {t('Customize')}
                </button>


            </div>
        </div>

    )
}

export default Product