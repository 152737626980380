import React, { useEffect, useState } from 'react'
import { setJWT } from '../redux/App/app.actions'
import { useDispatch } from "react-redux"

//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT
const riskifiedBeaconURL = process.env.REACT_APP_RISKIFIED_BEACON_URL
const riskifiedBeaconShop = process.env.REACT_APP_RISKIFIED_BEACON_SHOP


const useSkuLoader = () => {

    const [skuStatus, setSkuStatus] = useState('loading');
    const [data, setData] = useState([]);
    const [error, setError] = useState("");

    const dispatch = useDispatch()
    var carttoken = "";
    var jwt = "";

    useEffect(() => {

        console.log("THIS IS THE ENDPOINT", endpoint)

        const getJWTandCartToken = async () => {

            try {

                const {data:response_1} = await axios.post(
                    `${endpoint}/cust/create`,
                    {}
                )

                const _jwtAndCartToken = (response_1)

                jwt = _jwtAndCartToken.token
                carttoken = _jwtAndCartToken.cart_token

                return _jwtAndCartToken

            } catch {

                setError("COULD NOT GET JWT")
                throw "API ERROR – COULD NOT GET JWT"

            }

        };


        function riskifiedBeaconLoad(){
            var url = ('https:' == document.location.protocol ? 'https://' : 'http://') + "beacon.riskified.com?shop=" + riskifiedBeaconShop + "&sid=" + carttoken;
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = url;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s,x);
        }


        const callRiskifiedBeacon = async () => {

            //console.log(`BEACON CALL: ${riskifiedBeaconURL}?shop=${riskifiedBeaconShop}&sid=${carttoken}`)

            try {

                const {data:response_3} = await axios.get(
                    `${riskifiedBeaconURL}?shop=${riskifiedBeaconShop}&sid=${carttoken}`,
                    { 
                        headers: { Authorization: `Bearer ${jwt}` } 
                    }
                )


            } catch {

                console.log(`BEACON ERROR`)

            }

        };

        const fetchSkus = async () => {

            try {

                const {data:response_2} = await axios.get(
                    `${endpoint}/sku`,
                    { 
                        headers: { Authorization: `Bearer ${jwt}` } 
                    }
                )

                setData(response_2);
                setSkuStatus('done');

            } catch {

                setError("COULD NOT GET SKUS")
                throw "API ERROR – COULD NOT GET SKUS"

            }

        };

        getJWTandCartToken()
        .then( (_jwtAndCartToken)=>dispatch(setJWT(_jwtAndCartToken)) )
        .then( (_jwtAndCartToken)=>{
            riskifiedBeaconLoad();
            fetchSkus();
        } )

    }, []);

    return { skuStatus, data, error };

};

export default useSkuLoader