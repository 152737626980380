import { Container, Row, Col } from 'reactstrap';
import Tabbar from './Tabbar'
import Account from './parts/Account'
import Logo from '../components/Logo/Logo'
import ProtectedRoute from './ProtectedRoute'
import { Outlet } from "react-router-dom"
import useConsoleOrdersLoader from '../hooks/useConsoleOrdersLoader'
import './Dashboard.scss'


function Dashboard(props) {

	const {status: _ordersStatus, data: _ordersData, error: _ordersError} = useConsoleOrdersLoader()
	return (
    <ProtectedRoute>
	    <div className='dashboard mb-5 pb-lg-5'>
			<div className='container'>
					<Row xl="2" lg="2" md="2" sm="1" xs="1">
						<Col><Logo /></Col>
						<Col><Account /></Col>
					</Row>
				<Tabbar />
		        <div className='tab-content-container'>
			        { _ordersStatus == 'done' ? <Outlet /> : null}
		        </div>
			</div>
		</div>
    </ProtectedRoute>
	)
	
}

export default Dashboard