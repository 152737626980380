import {useState, useEffect, Suspense, useRef, useLayoutEffect, useCallback} from 'react';
import { connect } from "react-redux"
import './ViewFrontBack.scss';
import ThreeDBall from './ThreeD/ThreeDBall';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state) => ({
  currentItem: state.order.product,
  ballFront: state.order.Front,
  ballBack: state.order.Back,
  allColors: state.product.colors,
})


function ViewFrontBack(props) {
    const { t, i18n } = useTranslation()
    const [faceToShow,setFaceToShow] = useState('None');

    const ballFront = props.ballFront;
    const ballBack = props.ballBack;
    const currentItem = props.currentItem;
    const allColors = props.allColors;
    const [resetView,setResetView] = useState(0);

    var timers

    useEffect(()=>{
        setFaceToShow('Front');
        clearTimeout(timers)
        timers = setTimeout(()=>{ 
            setFaceToShow('Rotate')
        }, 3000)
    },[currentItem])

    function Placeholder() {
        return (
            <div style={{
                backgroundColor:'#ffffff',
                height:341,
                display:'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}><Spinner type="grow" color="green"/></div>
        )
    }

    return (
            <div className="front-back-wrapper" reset={resetView}>
                <div className='front-back'>
                    <Suspense  fallback={<Placeholder />}><ThreeDBall currentItem={currentItem} allColors={allColors} faceToShow={faceToShow} ballFront={ballFront} ballBack={ballBack} /></Suspense>
                </div>
                <div className="front-back-buttons text-uppercase mt-2 pt-lg-3">
                    <button id="frontButton" 
                    onClick={e => {
                        //e.currentTarget.nextElementSibling.classList.remove('onstate');
                        //e.currentTarget.classList.add('onstate');
                        setFaceToShow('Front')
                        clearTimeout(timers)
                        setTimeout(()=>{ 
                            setFaceToShow('Rotate')
                            //ballBack.Type === 'none' ? setFaceToShow('None') : setFaceToShow('Rotate')
                        }, 3000)
                    }}
                    className={`button ms-2 me-2 ${faceToShow === 'Front' ? 'onstate' : ''}`} >
                        {faceToShow === 'Front' ? t('FRONT') : t('VIEW FRONT')}
                    </button>
                    { ballBack.Type !== 'NONE' ?
                    <button id="backButton"
                    onClick={e => {
                        //e.currentTarget.previousElementSibling.classList.remove('onstate');
                        //e.currentTarget.classList.add('onstate');
                        setFaceToShow('Back')
                        clearTimeout(timers)
                        setTimeout(()=>{ 
                            setFaceToShow('Rotate')
                            //ballBack.Type === 'none' ? setFaceToShow('None') : setFaceToShow('Rotate')
                        }, 3000)
                    }}
                    className={`button ms-2 me-2 ${faceToShow === 'Back' ? 'onstate' : ''}`} >
                        {faceToShow === 'Back' ? t('BACK') : t('VIEW BACK')}
                    </button> : null }
                </div>
            </div>
    )
}

export default connect(mapStateToProps)(ViewFrontBack)