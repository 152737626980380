// App reducer

import { 
  SET_CONTENTMODAL,
  SET_AUTHENTICATED,
  SET_JWT,
  SET_ERROR,
  CLEAR_ERROR,
  SET_LOADING,
  CLEAR_LOADING } from './app.types'


const INITIAL_STATE = {
  jwt: '', // 'en' OR 'fr'
  errorMessage: '',
  errorCallback: ()=>{},
  loading: false,
  isAuthenticated: false,
  adminGroup: null, // Can be 0 = GOD, 1 = ADMIN, 2 = CUSTOMER SERVICE, 3 = PRODUCTION PARTNER
  contentModalContent: {
    title: "",
    body: "",
  }
};

const appReducer = (state = INITIAL_STATE, action) => {

    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {

      case SET_CONTENTMODAL:
          return { 
            ...state, 
            contentModalContent: action.payload
          };
      break;

      case SET_AUTHENTICATED:
          return { 
            ...state, 
            isAuthenticated: action.payload.isAuthenticated,
            adminGroup: action.payload.adminGroup,
            jwt: action.payload.jwt
          };
      break;

      case SET_JWT:
          console.log("ADDING TO STATE: ", { 
            jwt: action.payload.token,
            cart_token: action.payload.cart_token 
          })
          return { 
            ...state, 
            jwt: action.payload.token,
            cart_token: action.payload.cart_token 
          };
      break;

      case SET_ERROR:
          return { 
            ...state, 
            errorMessage: action.payload.errorMessage,
            errorCallback: action.payload.errorCallback 
          };
      break;

      case CLEAR_ERROR:
          return { 
            ...state, 
            errorMessage: "",
            errorCallback: ()=>{} 
          };
      break;

      case SET_LOADING:
          return { 
            ...state, 
            loading: true
          };
      break;

      case CLEAR_LOADING:
          return { 
            ...state, 
            loading: false
          };
      break;

      default: return state;
      
    }

};

export default appReducer;