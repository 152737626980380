import { useEffect } from 'react'
import App from './App'
import { connect, useDispatch } from "react-redux"
import { loadSkusToState } from './redux/Product/product.actions'
import useSkuLoader from './hooks/useSkuLoader'
import { Spinner } from 'reactstrap';
import { setError, clearError, setLoading, clearLoading } from './redux/App/app.actions'


function Preloader(props) {

  const {skuStatus, data, error} = useSkuLoader()

  const dispatch = useDispatch()

  if (skuStatus !== 'done') {
    return (<div style={{
              zIndex:99999,
              position:'fixed',
              padding:0,
              margin:0,
              top:0,
              left:0,
              width:'100%',
              height:'100%',
              background:'rgba(0,0,0,0.25)'
            }}><Spinner color="green" style={{
              position:'fixed',
              top:'50%',
              left:'50%',
              marginTop:'-10px',
              marginLeft:'-10px'
            }}/></div>)
  } else {
    dispatch(loadSkusToState(data))
    return <App />
  }

}

export default Preloader