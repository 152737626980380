import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

//const axios = require('axios')
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useConsoleAddTracking = ({ID:_ID, trackingNum:_trackingNum}) => {
    
    const [status, setStatus] = useState("idle")
    const [data, setData] = useState([])
    const [error, setError] = useState("")

    const jwt = useSelector(state => state.app.jwt)

    useEffect(() => {

        if (_trackingNum != "") {
            
            setStatus('loading')

            const addTracking = async () => {

                var trackingFormData = new FormData()
                trackingFormData.append('tracking', _trackingNum)

                //setTimeout(async ()=>{
                    try {
 
                        const response = await axios.post(
                            `${endpoint}/adm/orders/${_ID}/tracking`,
                            trackingFormData,
                            { headers: 
                                {
                                    Authorization: `Bearer ${jwt}` 
                                }
                            }
                        )

                        setData(response.data)
                        setStatus('done')
                    }
                    catch(e) {
                        setError('Tracking info could not be added.')
                        setStatus('error')
                        throw "API ERROR – TRACKING DATA COULD NOT BE UPLOADED"
                    }
                //}, 3000);

            };

            addTracking()

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }

    }, [_trackingNum])

    return { status, data, error }

};

export default useConsoleAddTracking