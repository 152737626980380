import { connect, useDispatch   } from "react-redux"
import {
  setCurrentlyEditing, 
  deleteFace,
} from '../../redux/Order/order.actions'
import { useTranslation } from 'react-i18next'


const mapStateToProps = (state) => ({
  allColors: state.product.colors,
})


function CustomizationTextBox(props) {

    const { t, i18n } = useTranslation()
    const dispatch = useDispatch();

    const allColors = props.allColors;
    const face = props.face;
    const faceDetails = props.faceDetails;
    const changeStep = props.changeStep;

    return (
        <div className='text-center text-sm-start customization-box customization-boxes'>
            <div className='d-sm-flex justify-content-between'>
                <div>
                    <span className='text-bold'>
                        {t(`${face} Customization`)} – {t('TEXT')}
                    </span>
                    <p className='ball-demo-text' style={{color:allColors[faceDetails.Color].HexValue}}>
                        {faceDetails.Line1} {faceDetails.Line1 ? <br /> : null}
                        {faceDetails.Line2} {faceDetails.Line2 ? <br /> : null}
                        {faceDetails.Line3}
                    </p>
                </div>
                <div>
                    <button onClick={()=>{
                        dispatch(deleteFace(face));
                        changeStep(2);
                        //dispatch(setCurrentlyEditing(face));
                        //changeStep(4);
                    }} className='button pt-2'>{t('Change')}</button>
                    <br />
                    <button className='button pt-2 delete' onClick={()=>{
                        // DELETE FACE CUSTOMIZATION GOES HERE
                        dispatch(deleteFace(face));
                    }}>
                        <label htmlFor='delete'>
                            {t('DELETE')}
                        </label>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(CustomizationTextBox)