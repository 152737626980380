import { connect, useDispatch   } from "react-redux"
import {
  deleteFace,
  resetLogoAndLogoNameForFace,
  setCurrentlyEditing,
} from '../../redux/Order/order.actions'
import { useTranslation } from 'react-i18next'


function CustomizationLogoBox({ face, faceDetails, changeStep }) {

    const { t, i18n } = useTranslation()
    const dispatch = useDispatch();

    return (
        <div className='text-center text-sm-start customization-box customization-boxes' >
            <div className='d-sm-flex align-items-top justify-content-between'>
                <div>
                    <span className='text-bold text-uppercase'>
                        {t(`${face} Customization`)} – {t('LOGO')}
                    </span>
                    <p>
                        {faceDetails.logoName}
                    </p>
                </div>
                <div>
                    <button className='button pt-2' onClick={()=>{
                        dispatch(deleteFace(face));
                        changeStep(2);
                        //dispatch(setCurrentlyEditing(face));
                        //dispatch(resetLogoAndLogoNameForFace(face));
                        //changeStep(5);
                    }}>
                        <label htmlFor='upload-logo'>
                            {t('Change')}
                        </label>
                    </button>
                    <br />
                    <button className='button pt-2 delete' onClick={()=>{
                        // DELETE FACE CUSTOMIZATION GOES HERE
                        dispatch(deleteFace(face));
                    }}>
                        <label htmlFor='delete'>
                            {t('DELETE')}
                        </label>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default connect()(CustomizationLogoBox)