import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link } from "react-router-dom";
import App from './App';
import Preloader from './preloader';
import { Provider } from 'react-redux'
import store from './redux/store'
import Console from './console/Console'
import Production from './console/Production'
import PreviewPrintContainer from './console/parts/PreviewPrintContainer'
import Shipped from './console/Shipped'
import Archived from './console/Archived'
import Completed from './console/Completed'
import Paused from './console/Paused'
import Login from './console/Login'
import Inbound from './console/Inbound'
import Dashboard from './console/Dashboard'
import Products from './console/Products'
import PageNotFound from './components/PageNotFound'
import LaunchPage from './components/LaunchPage/LaunchPage'

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";


// As of React 18
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<LaunchPage />} />
          <Route path="/home" element={<Preloader />} />
          <Route path="preview" element={<PreviewPrintContainer />} />
          <Route path="console" element={<Console />}>
            <Route index element={<Login />} />
            <Route path="dashboard" element={<Dashboard />}>
              <Route index element={<Inbound />} />
              <Route path="inbound" element={<Inbound />} />
              <Route path="production" element={<Production />} />
              <Route path="shipped" element={<Shipped />} />
              <Route path="completed" element={<Completed />} />
              <Route path="archived" element={<Archived />} />
              <Route path="paused" element={<Paused />} />
              <Route path="products" element={<Products />} />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </Provider>
  </I18nextProvider>
)



/*

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);


/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/