import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';


//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useOrderCreate = (_orderObj) => {
    
    const [status, setStatus] = useState("idle");
    const [data, setData] = useState([]);
    const [error, setError] = useState("");

    const jwt = useSelector(state => state.app.jwt)
    const cart_token = useSelector(state => state.app.cart_token)

    const { t, i18n } = useTranslation();
    const language = i18n.language

    useEffect(() => {

        if (Object.keys(_orderObj).length > 0) {

            // Added this in because the "logo" property should be the ID of the saved logo.
            
            const logoFrontID = _orderObj.Front.hasOwnProperty('ID') ? _orderObj.Front.ID : ""
            const logoBackID = _orderObj.Back.hasOwnProperty('ID') ? _orderObj.Back.ID : ""

            const logoFrontFilename = _orderObj.Front.hasOwnProperty('logoName') ? _orderObj.Front.logoName : ""
            const logoBackFilename = _orderObj.Back.hasOwnProperty('logoName') ? _orderObj.Back.logoName : ""

            const _orderReadyObj = {
                productid: _orderObj.product.ID,
                quantity: _orderObj.Quantity,
                front: {
                    ..._orderObj.Front,
                    logo: logoFrontID,
                    originalfilename: logoFrontFilename,
                },
                back: {..._orderObj.Back,
                    logo: logoBackID,
                    originalfilename: logoBackFilename,
                },
                language: language,
                CartToken: cart_token,
            }

            console.log("ORDER READY OBJECT: ", _orderReadyObj)

            setStatus('loading')

            const createOrder = async () => {

                //setTimeout(async ()=>{

                    try {
 
                        const response = await axios.post(
                            `${endpoint}/order/create`,
                            _orderReadyObj,
                            { headers: 
                                {
                                    Authorization: `Bearer ${jwt}` 
                                }
                            }
                        )

                        setData(response.data);
                        setStatus('done');
                    }
                    catch(e) {
                        setError('Your order could not be created. Please try again later.');
                        setStatus('error');
                        throw "API ERROR – DATA COULD NOT BE UPLOADED"
                    }
                //}, 3000);

            };

            createOrder();

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }


    }, [_orderObj]);

    return { status, data, error };

};

export default useOrderCreate;