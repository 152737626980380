import React from 'react';
import './TypeAheadDropDown.scss'


const buttons = ['red-button','transparent'];

function TypeAheadDropDown(props) {

  const _suggestions_arr = props.arr
  const _selectAction = props.selectAction

  return (
   <div className={_suggestions_arr.length === 0 ? 'hide' : 'TypeAheadDropDown hide'} >
     <ul>
       {_suggestions_arr.map((option,index) => <li key={_suggestions_arr[index].Id} onClick={()=>_selectAction(_suggestions_arr[index].Id)}>{`${_suggestions_arr[index].Text}, ${_suggestions_arr[index].Description}`}</li>)}
     </ul>
   </div>
   );
 
}

export default TypeAheadDropDown