// Console reducer

import { 
  LOAD_ORDERS_TO_STATE,
  SET_TRACKING,
  SET_RETAILPRO,
  TOGGLE_ORDER_PAUSE,
  SET_STATUS,
} from './console.types';

const INITIAL_STATE = {

  orders: []

};

const consoleReducer = (state = INITIAL_STATE, action) => {

    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {

      case LOAD_ORDERS_TO_STATE:

            /***********
            // This is to fake order defaults!!!!
            ***********/

            let inboundOrders = action.payload

            //let modInboundOrders = inboundOrders.map((order)=>{
            //    //const dateStr = new Date(order.Updated).toISOString()
            //    const dateStr = new Date(order.Updated).toISOString().replace(/T/, ' ').replace(/\..+/, '')
            //    //const[d,m,y] = dateStr.split('.').map(Number)
            //    //const newDateStr = new Date(y, m - 1 /* month is 0-indexed */, d)
            //
            //    return {...order, 
            //        "Status": "INBOUND",
            //        "RetailPro": "",
            //        "Updated": dateStr,
            //        "Tracking": "",
            //        "Paused": false, }
            //})

            // TO DO.... CONFIRM This may have to check if p.BamboraPreAuth.Approved === '1' instead
            const approvedOrders = inboundOrders.filter(p => (p.BamboraPreAuth.ID != ''))
            approvedOrders.sort((a,b) => Date.parse(b.Updated) - Date.parse(a.Updated))

          return { ...updatedState, 
            orders: approvedOrders  };
      break;

      case SET_TRACKING:
        var newOrders = updatedState.orders.map(
            (order)=>{
                if (order.ID === action.payload.id) {
                    order.TrackingCode = action.payload.tracking
                    order.Status = 'SHIPPED'
                } 
                return order
            })
        return { ...updatedState, 
            orders: newOrders  };
      break;

      case SET_STATUS:
        var newOrders = updatedState.orders.map(
            (order)=>{
                if (order.ID === action.payload.id) {
                    order.Status = action.payload.status
                } 
                return order
            })
        return { ...updatedState, 
            orders: newOrders  };
      break;

      case SET_RETAILPRO:
        var newOrders = updatedState.orders.map(
            (order)=>{
                if (order.ID === action.payload.id) {
                    order.RetailPro = action.payload.RP_Rct
                    order.RetailProCustomer = action.payload.RP_Cust
                    order.Status = 'PRODUCTION'
                } 
                return order
            })
        return { ...updatedState, 
            orders: newOrders  };
      break;

      case TOGGLE_ORDER_PAUSE:
        var newOrders = updatedState.orders.map(
            (order)=>{
                if (order.ID === action.payload.id) {
                    order.Paused = !order.Paused
                    order.Pausenote = action.payload.note
                } 
                return order
            })
        return { ...updatedState, 
            orders: newOrders  };
      break;

      default: return state;
      
    }

};

export default consoleReducer;