import "./PageNotFound.scss"

function PageNotFound(props) {

    return (
      <div className='page-not-found'>
        <h1>404 Page</h1>
      </div>
    )

}

export default PageNotFound