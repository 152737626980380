import { connect, useDispatch   } from "react-redux"
import { useTranslation } from 'react-i18next'


const mapStateToProps = (state) => ({
  ship_company_name:      state.shipping.CompanyName,
  ship_first_name:        state.shipping.First,
  ship_last_name:         state.shipping.Last,
  ship_address_1:         state.shipping.Address1,
  ship_address_2:         state.shipping.Address2,
  ship_city:              state.shipping.City,
  ship_postal_code:       state.shipping.PostCode,
  ship_country:           state.shipping.Country,
  ship_province:          state.shipping.Province,
  ship_phone:             state.shipping.Phone,
})



function BillingAddress(props) {

  const { t, i18n } = useTranslation()

  return (
      <div className='order-summary address-box billing-info'>
        <div className="align-items-center d-flex justify-content-between">
          <h3>
            {t('Shipping')}
          </h3>
          <button className="edit text-green text-decoration-underline" onClick={()=>props.setSubStepToShow('CHECKOUT-SHIPPING')}>
            {t('Edit')}
          </button>
        </div>
        <div className="gray-box">
          <ul className="address-list p-0 m-0">
            <li key={0}>{props.ship_company_name}</li>
            <li key={1}>{props.ship_first_name} {props.ship_last_name}</li>
            <li key={2}>{props.ship_address_1}</li>
            <li key={3}>{props.ship_address_2}</li>
            <li key={4}>{props.ship_city}</li>
            <li key={5}>{props.ship_postal_code}</li>
            <li key={6}>{props.ship_country}</li>
            <li key={7}>{props.ship_province}</li>
            <li key={8}>{props.ship_phone}</li>
          </ul>
        </div>
      </div>
    )
}


export default connect(mapStateToProps)(BillingAddress)