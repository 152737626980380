import React, { createRef, useEffect, useState } from 'react'
import ballFront from './customization/ball-front.png'
import ballBack from './customization/ball-back.png'
import Button from './Buttons.js';
import BuildSummaryHead from './BuildSummaryHead/BuildSummaryHead';
import ChangeBallBox from './ChangeBallBox';
import CustomizationBoxes from './customization/CustomizationBoxes';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import StepThree from './StepThree/StepThree'
import useSkuLoader from '../hooks/useSkuLoader'
import { Spinner } from 'reactstrap';
import { orderResetForRepeat } from '../redux/Order/order.actions'
import { billingResetForRepeat } from '../redux/Billing/billing.actions'


/*
const mapStateToProps = (state) => ({
  currentItem:            state.order.product,
  allColors:              state.product.colors,
  order:                  state.order,
})
*/

function RepeatOrder(props) {

  const [loadStepThree, setLoadStepThree] = useState(false)

  const {  
    orderCompleted, events  } = props;

  const { 
    changeStep, 
    toggleproductChangeModal,
    setOrderCompleted,
    setOrderCompletedFalse,
    setTextEdit,
    setLogoUpload,
    toggleProductPriceModal } = events;

  const dispatch = useDispatch();

  const {skuStatus, data, error} = useSkuLoader()

  useEffect(()=>{

    setOrderCompletedFalse()
    dispatch(orderResetForRepeat())
    dispatch(billingResetForRepeat())
    toggleproductChangeModal()
    /*

    TO DO

    2) Zero out parts of props.order object that need to be zeroed out and load to state

      {
        billing: { //Delete all CC info
          Name: '',
          Number: '',
          Type: ,
          Month: ,
          Year: ,
          CVC: ,
          ID: '',
          LastFour: ''
        },
        order: { //Delete orderID, BamboraID
          ID: '',
          BamboraID: ''
        },
        app: {  //Set new JWT and cart token
          jwt: 'NEW',
          cart_token: 'NEW'
        }
      }
    */

  },[])



  if (skuStatus !== 'done') {
    return (<div style={{
              zIndex:99999,
              position:'fixed',
              padding:0,
              margin:0,
              top:0,
              left:0,
              width:'100%',
              height:'100%',
              background:'rgba(0,0,0,0.25)'
            }}><Spinner color="green" style={{
              position:'fixed',
              top:'50%',
              left:'50%',
              marginTop:'-10px',
              marginLeft:'-10px'
            }}/></div>)
  } else {
    return <StepThree
      events={{
        toggleProductPriceModal: toggleProductPriceModal,
        toggleproductChangeModal: toggleproductChangeModal,
        setOrderCompleted: setOrderCompleted,
        setTextEdit: setTextEdit,
        changeStep: changeStep,
        setLogoUpload: setLogoUpload,
      }}
      props={{
        orderCompleted: orderCompleted,
      }} />
  }

}

//export default connect(mapStateToProps)(RepeatOrder)
export default RepeatOrder