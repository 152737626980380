import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const useBillingUpload = ({billingObj: _billingObj, ID: _ID}) => {
    
    const [status, setStatus] = useState("idle");
    const [data, setData] = useState([]);
    const [error, setError] = useState("");

    const jwt = useSelector(state => state.app.jwt)

    useEffect(() => {

        if (Object.keys(_billingObj).length > 0) {

            const formattedObj = {
                    CompanyName: _billingObj.CompanyName,
                    Name: _billingObj.Name,
                    Number: _billingObj.Number,
                    CVC: _billingObj.CVC,
                    Month: _billingObj.Month,
                    Year: _billingObj.Year,
                    First: _billingObj.First,
                    Last: _billingObj.Last,
                    Address1: _billingObj.Address1,
                    Address2: _billingObj.Address2,
                    City: _billingObj.City,
                    Province: _billingObj.Province,
                    PostCode: _billingObj.PostCode,
                    Country: _billingObj.Country,
                    Phone: _billingObj.Phone,
                    Email: _billingObj.Email,
            }

            setStatus('loading')

            const uploadBilling = async () => {

                //setTimeout(async ()=>{

                try {

                    const response = await axios.post(
                        `${endpoint}/order/${_ID}/payment`,
                        formattedObj,
                        { headers: 
                            {
                                Authorization: `Bearer ${jwt}` 
                            }
                        }
                    )

                    setData(response.data);
                    setStatus('done');

                }
                catch(e) {
                    setError(e.response.data.message);
                    setStatus('error');
                    throw "API ERROR – DATA COULD NOT BE UPLOADED"
                }

                //}, 3000);

            };

            uploadBilling();

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }


    }, [_billingObj]);

    return { status, data, error };

};

export default useBillingUpload;