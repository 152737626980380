import "./PageTitle.scss"

function PageTitle({title: title, startover: startover}) {

    return (
      <div className='page-title-and-start-over'>
        <div className='row'>
          <div className='col-md-8'>
            <h3 className='customize-title mt-4 mb-4 text-center text-md-start font-weight-bold'>
              {title}
            </h3>
          </div>
          <div className='col-md-4 text-end d-none d-md-block'>
            <a className='start-over-link px-2' onClick={()=>window.location.reload()}>{startover}</a>
          </div>
        </div>
      </div>
    )

}

export default PageTitle