import React, { useState } from 'react'
import { connect, useDispatch   } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ModalProduct from '../Products/ModalProduct';
import './Modal.scss';
import {FaAngleUp,FaAngleDown} from 'react-icons/fa'
import { setCurrentProduct } from '../../redux/Order/order.actions';
import { useTranslation } from 'react-i18next'
import Sidebar from '../Sidebar/Sidebar';

const mapStateToProps = (state) => ({
  products:               state.product.balls,
})


const ProductChangeModal = (props) => {
    
    const { t, i18n } = useTranslation()
    const [filter, setFilter] = React.useState(0);
    const [triggerRerender, setTriggerRerender] = React.useState(0);

    const curLanguage = i18n.language.toUpperCase();

    const [filteredProds, setFilteredProds] = useState(props.products.filter(prod => prod.Live === true) );

    const {
        className,
        modal,
        events,
    } = props;

    const dispatch = useDispatch();

    function filterProducts(oemArr) {
        setFilteredProds( props.products.filter(prod => oemArr.includes(prod.BrandID) && prod.Live === true) );
    }

    const changeSelectedProduct = (_productObj) => {
        dispatch(setCurrentProduct(_productObj));
    }

    const brandsArr = props.products.map(item => item.BrandID).filter((value, index, self) => self.indexOf(value) === index).map( id=>props.products.find(obj => obj.BrandID === id) ).map(obj => {return {BrandID: obj.BrandID,BrandName:obj.BrandName}})


    function changeOemArr(checkbox) {

        if (checkbox != 'selectall') {
          document.getElementById('selectall').checked = false;
        }

        var newArr = [];

        newArr = brandsArr.map(item => {
            var elementName = `manufacturer${item.BrandID}`;
            if (document.getElementById(elementName).checked || document.getElementById("selectall").checked) {
              return item.BrandID;
            }
        })

        filterProducts(newArr);

    }

      function orderProducts(order) {
        // Need to sort all viewable products by price according to 'order' and alphabetically by name after that
        // `Name${curLanguage}`

        let sortedProducts = filteredProds.sort(
          (a, b) => {
              var textA = a[`Name${curLanguage}`].toUpperCase();
              var textB = b[`Name${curLanguage}`].toUpperCase();

              if (order === 'lowtohigh') {
                  if (a.Price === b.Price){
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                  } else {
                    return a.Price - b.Price
                  }
              } else {
                  if (a.Price === b.Price){
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                  } else {
                    return b.Price - a.Price
                  }
              }
            }
        )

        setFilteredProds(sortedProducts)

        setTriggerRerender(!triggerRerender)

      }



    const closeBtn = <button className="close" onClick={events.toggleproductChangeModal}>&times;</button>;

    return (
        <div>
            <Modal isOpen={modal} className={className}>
                <ModalHeader close={closeBtn}>
                    {t('SELECT A DIFFERENT BALL')}
                </ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-md-4'>
          <Sidebar filterProducts={filterProducts} orderProducts={orderProducts} />

                        </div>
                        <div className='col-md-8'>
                            <div className='row'>
                                {
                                    filteredProds.map(productObj => {
                                        return <ModalProduct changeSelectedProduct={changeSelectedProduct} key={productObj.ID} events={events} product={productObj} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default connect(mapStateToProps)(ProductChangeModal)