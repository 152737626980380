import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { setError, clearError, setLoading, clearLoading } from '../redux/App/app.actions'

//const axios = require('axios');
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT


const useOpenOrderLoader = (_id) => {

    const [status, setStatus] = useState('loading')
    const [data, setData] = useState({})
    const [error, setError] = useState("")

    const dispatch = useDispatch()

    useEffect(() => {

        const fetchOrder = async () => {

            dispatch(setLoading())

            try {

                const {data:response} = await axios.get(
                    `${endpoint}/preview/${_id}`
                )
                
                setData(response)
                setStatus('done')

            } catch {

                dispatch( setError( "Could not load order", 
                  ()=>{
                    // Error Callback
                    dispatch(clearError())
                  } ) )

            }

        }

        if (_id !== "") {
            fetchOrder()
            .then( dispatch(clearLoading()) )
        }

    }, [_id]);

    return { status, data, error }

};

export default useOpenOrderLoader