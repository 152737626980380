import { useSearchParams } from 'react-router-dom'
import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import { Button } from 'reactstrap'
import ReactToPrint from 'react-to-print'
import OrderPreview from './OrderPreview'
import { FaPrint } from 'react-icons/fa'
import useConsoleProductLoader from '../../hooks/useConsoleProductLoader'
import LoadingSpinner from '../../components/LoadingSpinner'
import { useTranslation } from 'react-i18next';
import './PreviewPrintContainer.scss'

function PreviewPrintLOCALContainer({obj: _obj, product: _product, context:_context}) {

	const [product, setProduct] = useState(_product)

	const [searchParams, setSearchParams] = useSearchParams()
	const colors = useSelector(state => state.product.colors)
	const products = useSelector(state => state.product.balls)

	const printAreaRef = useRef()

	const { t, i18n } = useTranslation()

	return (
	    <div>
			<ReactToPrint
				trigger={() => <Button className='print-button'><FaPrint /> {t('Print')}</Button>}
				content={() => printAreaRef.current}
			/>
			<OrderPreview ref={printAreaRef} obj={_obj} product={product} context={_context} />
	    </div>
	)

// return <div>{JSON.stringify(_obj)}</div>
}

export default PreviewPrintLOCALContainer