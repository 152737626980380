import { useState } from 'react';
import logo from './car.svg';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import GTNav from '../GTNav/GTNav';

import './Topbar.scss'

function Topbar() {
    const [showLang, setShowLang] = useState(0)
    const { t, i18n } = useTranslation();

    return <div className="py-lg-2 topbar text-center bg-green text-white">
        <div className="container">
            <img src={logo} />
            <span className='ps-2 font-secondary'>
                {t('FREE SHIPPING ON ORDERS OVER $99.')}
            </span>

            <div className="language position-absolute">
                <div className='d-sm-none ms-2'>
                    <FaBars onClick={e => {
                        const blurDOM = e.currentTarget.parentElement.parentElement.parentElement.parentElement.nextElementSibling;
                        blurDOM.classList.toggle('transparent-blur');
                        document.body.classList.toggle('overflow-hidden');
                        setShowLang(!showLang);
                    }} className={`${showLang && 'd-none'}`} />
                    <FaTimes onClick={e => {
                        const blurDOM = e.currentTarget.parentElement.parentElement.parentElement.parentElement.nextElementSibling;
                        blurDOM.classList.toggle('transparent-blur');
                        document.body.classList.toggle('overflow-hidden');
                        setShowLang(!showLang)

                    }} className={`${!showLang && 'd-none'}`} />
                </div>
                <div className={`d-sm-block ${!showLang && 'd-none'}`}>
                    <div>
                        <GTNav />
                    </div>
                    <a onClick={() => i18n.changeLanguage('en')} className={`text-white ${i18n.language == 'en' ? 'lang-selected' : null}`} href="#">EN</a>&nbsp; | &nbsp;
                    <a onClick={() => i18n.changeLanguage('fr')} className={`text-white ${i18n.language == 'fr' ? 'lang-selected' : null}`} href="#">FR</a>
                </div>
            </div>
        </div>
    </div>
}
export default Topbar;  