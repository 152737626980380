import './Contact.scss'
import { Link } from 'react-router-dom'

function Contact({context, contactObj}) {

    switch (context) {

		case 'PRODCONTACT':

			return (
				<>
					{contactObj.CompanyName ? <span className="company-name">{contactObj.CompanyName}<br /></span> : null}
					<b>{contactObj.First} {contactObj.Last}</b><br />
					(p) {contactObj.Phone}<br />
					(e) <Link to='#'
				            onClick={(e) => {
				                window.location.href = `mailto:${contactObj.Email}`;
				                e.preventDefault();
				            }}
				        >{contactObj.Email}</Link>
				</>
			)

		break

		default: return (
			<>
				{contactObj.CompanyName ? <span className="company-name">{contactObj.CompanyName}<br /></span> : null}
				<b>{contactObj.First} {contactObj.Last}</b><br />
				{contactObj.Address1}<br />
				{contactObj.Address2 !== "" 
					? <><br />{contactObj.Address2}<br /></>
					: null}
				{contactObj.City} {contactObj.Province}<br />
				{contactObj.PostCode}<br />
				(p) {contactObj.Phone}
			</>
		)

      
    }

}

export default Contact
