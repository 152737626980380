import { Table, InputGroup, Button, Input } from 'reactstrap';
import { useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import { FaSort, FaDownload } from "react-icons/fa";
import './Products.scss';


function Products(props) {

	const [retailProNums, setRetailProNums] = useState({})
	const [sortedProducts, setSortedProducts] = useState([])
	const [idSortForward, setIDSortForward] = useState(true)

	const products = useSelector(state => state.product.balls)


	const sortProducts = () => {
		setSortedProducts ( products.sort(function(a, b) {
		    return idSortForward ? a.ID-b.ID : b.ID-a.ID;
		}) )
	}

	useEffect(()=>{
		sortProducts()
	},[idSortForward, products.length])

	/*
	const orders = useSelector(state => state.console.orders)
				   .filter(p => p.Status === 'COMPLETED')
	*/


	return (
	    <div className="dashboard-products">

			<Table className="products-table" responsive striped bordered >
			  <thead>
			    <tr>
			      <th rowspan="2" onClick={()=>{setIDSortForward(!idSortForward)}}>
			        ID <FaSort className='icons' />
			      </th>
			      <th rowspan="2">
			        GT SKUID
			      </th>
			      <th rowspan="2">
			        Brand
			      </th>
			      <th rowspan="2" className="descriptionCol">
			        EN
			      </th>
			      <th rowspan="2" className="descriptionCol">
			        FR
			      </th>
			      <th rowspan="2">
			        EN/FR Boxes
			      </th>
			      <th rowspan="2">
			        Side Logo
			      </th>
			      <th rowspan="2">
			        Top Logo
			      </th>
			      <th rowspan="2">
			        Price
			      </th>
			      <th rowspan="2">
			        DigiFee
			      </th>
			      <th colspan="5">
			        Front Customization
			      </th>
			      <th colspan="5">
			        Back Customization
			      </th>
			    </tr>
			    <tr>
			      <th>
			        1-11
			      </th>
			      <th>
			        12-23
			      </th>
			      <th>
			        24-47
			      </th>
			      <th>
			        48-119
			      </th>
			      <th>
			        Over 120
			      </th>
			      <th>
			        1-11
			      </th>
			      <th>
			        12-23
			      </th>
			      <th>
			        24-47
			      </th>
			      <th>
			        48-119
			      </th>
			      <th>
			        Over 120
			      </th>
			    </tr>
			  </thead>

			  <tbody>
			  { products.map((ball) => {



		  		return ( 
					<tr key={ball.ID}>
						<td className={ball.Live ? 'live' : 'notlive'}>
							{ball.ID}
						</td>
						<td>
							{ball.GTSKU}
						</td>
						<td>
							{ball.BrandName}
						</td>
						<td>
							<b>{ball.NameEN}</b><br/>{ball.DescriptionEN}
						</td>
						<td>
							<b>{ball.NameFR}</b><br/>{ball.DescriptionFR}
						</td>
						<td>
							<img src={ball.BoxImageEN} className='boxart' /><br /><img src={ball.BoxImageFR} className='boxart' />
						</td>
						{/*
						<td>
							<img src={ball.FrontImage} className='boxart' />
						</td>
						<td>
							<img src={ball.BackImage} className='boxart' />
						</td>
						*/}
						<td>
							<img src={ball.LogoSide1Image} className='sideart' />
						</td>
						<td className="toplogoCol">
							<img src={ball.LogoTopImage} className='topart' />
						</td>
						<td className="moneyCol">
							{ball.Price}
						</td>
						<td className="moneyCol">
							{ball.DigitizationFee}
						</td>
						<td className="moneyCol">
							{ball.CustCostFront1to11}
						</td>
						<td className="moneyCol">
							{ball.CustCostFront12to23}
						</td>
						<td className="moneyCol">
							{ball.CustCostFront24to47}
						</td>
						<td className="moneyCol">
							{ball.CustCostFront48to119}
						</td>
						<td className="moneyCol">
							{ball.CustCostFrontOver120}
						</td>
						<td className="moneyCol">
							{ball.CustCostBack1to11}
						</td>
						<td className="moneyCol">
							{ball.CustCostBack12to23}
						</td>
						<td className="moneyCol">
							{ball.CustCostBack24to47}
						</td>
						<td className="moneyCol">
							{ball.CustCostBack48to119}
						</td>
						<td className="moneyCol">
							{ball.CustCostBackOver120}
						</td>
						{/*
						<td>
							{ JSON.stringify(ball) }
						</td>
						*/}
				    </tr>
				) })
			  }
			  </tbody>
			</Table>
		</div>
	)
}

export default Products