import {useState} from 'react';
import ReactSlider from "react-slider";
import './ImgSizeSlider.scss';

function ImgSizeSlider({changeImgScale}) {
  const [imgSizeCurrentVal, setImgSizeCurrentVal] = useState(50);

  function moveSlider(value) {

    //value >=100
    /*
    if (value < 50) {
      var calculatedValue = -1-(50-value)/(50);
    } else {
      var calculatedValue = ((value)/(100))/2;
    }
    if ((value > 50) && (value < 60)) {
      var calculatedValue = .1;
    }
    if ((value > 70) && (value < 80)) {
      var calculatedValue = .2;
    }
    */
    if (value > imgSizeCurrentVal) {
      var calculatedValue = .1;
    }
    if (value < imgSizeCurrentVal) {
      var calculatedValue = -.1;
    }
    setImgSizeCurrentVal(value);
    changeImgScale(calculatedValue);
  }

  return (
              <ReactSlider
                className="imgSizeSlider"
                trackClassName="imgSizeSlider-track"
                thumbClassName="imgSizeSlider-thumb"
                //markClassName="imgSizeSlider-mark"
                defaultValue={50}
                value={imgSizeCurrentVal}
                onChange={(value) => moveSlider(value)/* setImgSizeCurrentVal(value) */}
                //marks={10}
                min={1}
                max={100}
               />

    )

}

export default ImgSizeSlider



