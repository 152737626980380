import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux"
import { setBillingInfo } from '../../redux/Billing/billing.actions'
import { setError, clearError, setLoading, clearLoading } from '../../redux/App/app.actions'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../../helpers/currencyFormatter'
import useBillingUpload from '../../hooks/useBillingUpload'
import useCanPostSearch from '../../hooks/useCanPostSearch'
import useCanPostRetrieve from '../../hooks/useCanPostRetrieve'
import TypeAheadDropDown from '../TypeAheadDropDown'

const mapStateToProps = (state) => ({
  fullBillingObj: state.billing,
  Name:           state.billing.Name,
  Number:         state.billing.Number,
  Type:           state.billing.Type,
  Month:          state.billing.Month,
  Year:           state.billing.Year,
  CVC:            state.billing.CVC,
  CompanyName:    state.billing.CompanyName,
  First:          state.billing.First,
  Last:           state.billing.Last,
  Address1:       state.billing.Address1,
  Address2:       state.billing.Address2,
  City:           state.billing.City,
  PostCode:       state.billing.PostCode,
  Country:        state.billing.Country,
  Province:       state.billing.Province,
  Phone:          state.billing.Phone,
  Email:          state.billing.Email,
  orderID:        state.order.ID,
})



function BillingForm(props) {

  const { t, i18n } = useTranslation();
  const orderID = props.orderID;

  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

  const dispatch = useDispatch();

  const [billingObj, setBillingObj] = useState({billingObj: "", ID: ""});
  const {status: billingUploadStatus, data: billingEndpointReturn, error: billingUploadError} = useBillingUpload(billingObj)

  useEffect(()=>{

    switch (billingUploadStatus) {

      case 'loading':
        dispatch( setLoading() )
      break;

      case 'done':
        dispatch( clearLoading() )
        //dispatch(setBillingInfo({...billingEndpointReturn.Billing.BillingAddress,...billingEndpointReturn.Billing.CreditCard}));
        dispatch(setBillingInfo({...billingObj.billingObj, "ID": billingObj.ID, "LastFour":billingObj.billingObj.Number.slice(-4) }));
        props.setSubStepToShow('CHECKOUT-PLACEORDER');
      break;
      
      case 'error':
        dispatch( clearLoading() )
        dispatch( setError( `${t("We weren't able to complete your transaction.")} ${t(billingUploadError)}`, 
          ()=>{
            // Error Callback
            dispatch(clearError());
          } ) )
      break;

      default: 

    }

  },[billingUploadStatus])


  const [canPostSearchText, setCanPostSearchText] = useState("");
  const {status: canPostSearchStatus, data: canPostSearchReturn, error: canPostSearchError} = useCanPostSearch(canPostSearchText)
  const [addressArr, setAddressArr] = useState([]);

  useEffect(()=>{

    switch (canPostSearchStatus) {

      case 'loading':
          setAddressArr([])
      break;

      case 'done':
          setAddressArr(Object.values(canPostSearchReturn))

      break;
      
      case 'error':

      break;

      default: 

    }

  },[canPostSearchStatus])


  const [canPostRetrieveId, setCanPostRetrieveId] = useState(null);
  const {status: canPostRetrieveStatus, data: canPostRetrieveReturn, error: canPostRetrieveError} = useCanPostRetrieve(canPostRetrieveId)
  const [retreivedAddress, setRetreivedAddress] = useState({});

  useEffect(()=>{

    switch (canPostRetrieveStatus) {

      case 'loading':
          setAddressArr([])
      break;

      case 'done':
        const resetObj = {
          Address1: canPostRetrieveReturn[0].Line1,
          Address2: canPostRetrieveReturn[0].Line2,
          City: canPostRetrieveReturn[0].City,
          Country: canPostRetrieveReturn[0].CountryName,
          PostCode: canPostRetrieveReturn[0].PostalCode,
          Province: canPostRetrieveReturn[0].ProvinceCode,
        }
        reset(resetObj)
      break;
      
      case 'error':

      break;

      default: 

    }

  },[canPostRetrieveStatus])


  const onSubmit = data => {
    //dispatch(setBillingInfo(data));
    setBillingObj({billingObj: data, ID: orderID})
    reset({...props.fullBillingObj});
  };

  useEffect(()=>{
    reset({...props.fullBillingObj});
  },[props])


  return (
          <div className="billing mb-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`billing-address-part`}>
                <h4 className='mb-3'>
                  {t('Enter Billing Address')}
                </h4>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="CompanyName">
                          {t('Company Name')}
                      </label>
                      <input {...register("CompanyName", { required: false })} defaultValue={props.CompanyName} className='w-100' />
                      <span className="error">{errors.CompanyName?.type === 'required' && "Company name is required"}</span>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="First">
                          {t('First Name')}
                      </label>
                      <input {...register("First", { required: true })} defaultValue={props.First} className='w-100' />
                      <span className="error">{errors.First?.type === 'required' && "First name is required"}</span>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Last">
                          {t('Last Name')}
                      </label>
                      <input {...register("Last", { required: true })} defaultValue={props.Last} className='w-100' />
                      <span className="error">{errors.Last?.type === 'required' && "Last name is required"}</span>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Address1">
                          {t('Address Line 1')}
                      </label>
                      <input {...register("Address1", { 
                        onChange: (e) => {
                          setCanPostSearchText(e.target.value)
                        }, required: true })} defaultValue={props.Address1} className='w-100' autoComplete="off" />
                      <TypeAheadDropDown arr={addressArr} selectAction={(selectedId)=>{
                        setCanPostSearchText("")
                        setCanPostRetrieveId(selectedId)
                      }} />
                      <span className="error">{errors.Address1?.type === 'required' && "Address is required"}</span>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Address2">
                          {t('Address Line 2')}
                      </label>
                      <input {...register("Address2")} defaultValue={props.Address2} className='w-100' />
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="City">
                          {t('City')}
                      </label>
                      <input {...register("City", { required: true })} defaultValue={props.City} className='w-100' />
                      <span className="error">{errors.City?.type === 'required' && "City is required"}</span>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="PostCode">
                          {t('Postal Code')}
                      </label>
                      <input {...register("PostCode", { /*required: true*/})} defaultValue={props.PostCode} className='w-100' />
                      <span className="error">{errors.PostCode?.type === 'required' && "Postal Code is required"}</span>
                    </div>
                    <span className='example'>Example: 1A2B3C</span>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Country">
                          {t('Country')}
                      </label>
                      <input {...register("Country", { /*required: true*/})} defaultValue={props.Country} className='w-100' />
                      <span className="error">{errors.Country?.type === 'required' && "Country is required"}</span>
                  </div>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Province">
                        {t('PROVINCE')}
                      </label>
                      <select {...register("Province", { required: true })} className='w-100'>
                        <option hidden disabled selected  value=""></option>
                        <option value="AB">{t('Alberta')}</option>
                        <option value="BC">{t('British Columbia')}</option>
                        <option value="MB">{t('Manitoba')}</option>
                        <option value="NB">{t('New Brunswick')}</option>
                        <option value="NL">{t('Newfoundland and Labrador')}</option>
                        <option value="NT">{t('Northwest Territories')}</option>
                        <option value="NS">{t('Nova Scotia')}</option>
                        <option value="NU">{t('Nunavut')}</option>
                        <option value="ON">{t('Ontario')}</option>
                        <option value="PE">{t('Prince Edward Island')}</option>
                        <option value="QC">{t('Quebec')}</option>
                        <option value="SK">{t('Saskatchewan')}</option>
                        <option value="YT">{t('Yukon')}</option>
                      </select>
                      <span className="error">{errors.Province?.type === 'required' && "Province is required"}</span>
                    </div>
                  </div>
                  <div className='mb-3 d-flex flex-column'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Phone">
                          {t('Phone')}
                      </label>
                      <input {...register("Phone", { required: true,pattern: /[0-9]/ })} defaultValue={props.Phone} className='w-100' />
                      <span className="error">{errors.Phone?.type === 'required' && "Phone is required"}</span>
                    </div>
                    <span className='example'>{t('Example: 1112223333')}</span>
                  </div>
                  <div className='mb-4'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Email">
                          {t('Email')}
                      </label>
                      <input {...register("Email", { required: true, pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i })} defaultValue={props.Email} className='w-100' />
                      <span className="error">{errors.Email?.type === 'required' && "Email is required"}</span>
                      <span className="error">{errors.Email?.type === 'pattern' && "Invalid email"}</span>
                   </div>
                  </div>
                  <hr />
                </div>


                <div className={`billing-credit-part`}>
                  <h4 className={`${props.subStepToShow === 'CHECKOUT-BILLING-SAME-ADDRESS' ? '' : 'mt-4 pt-3'} mb-4 pb-lg-2`}>
                      {t('Enter Credit Card Information')}
                  </h4>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Name">
                          {t('NAME ON CARD')}
                      </label>
                      <input {...register("Name", { required: true })} defaultValue={props.Name} className='w-100' />
                      <span className="error">{errors.Name?.type === 'required' && "Name is required"}</span>
                    </div>
                  </div>

                  <input type="hidden" {...register("Type", {valueAsNumber: true})} defaultValue={0} />


                  {/*
                  <div className='mb-3'>
                    <div className="select-box input-box">
                      <label>
                        Type
                      </label>
                      <div>
                        <select {...register("card_type")}>
                          <option value="visa">Visa</option>
                          <option value="master">MasterCard</option>
                        </select>
                      </div>
                    </div>
                  </div>
                */}
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Number">
                          {t('NUMBER')}
                      </label>
                      <input {...register("Number", { required: true })} defaultValue={props.Number} className='w-100' />
                      <span className="error">{errors.Number?.type === 'required' && "Card number is required"}</span>
                    </div>
                    <span className="example">
                      {t('Example: 3333333333')}
                    </span>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Month">
                          {t('EXPIRATION MONTH')}
                      </label>

                      <select {...register("Month", {valueAsNumber: true, required: true })} className='w-100'>
                        <option value="1">{t('January')}</option>
                        <option value="2">{t('February')}</option>
                        <option value="3">{t('March')}</option>
                        <option value="4">{t('April')}</option>
                        <option value="5">{t('May')}</option>
                        <option value="6">{t('June')}</option>
                        <option value="7">{t('July')}</option>
                        <option value="8">{t('August')}</option>
                        <option value="9">{t('September')}</option>
                        <option value="10">{t('October')}</option>
                        <option value="11">{t('November')}</option>
                        <option value="12">{t('December')}</option>
                      </select>

                      {/*<input {...register("Month", {valueAsNumber: true, required: true, maxLength: 2, pattern: /^[0-9]*$/ })} defaultValue={props.Month} className='w-100' placeholder='MM' maxlength="2" />*/}
                      <span className="error">{errors.Month?.type === 'required' && "Expiration month is required"}</span>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="Year">
                          {t('EXPIRATION YEAR')}
                      </label>

                      <select {...register("Year", {valueAsNumber: true, required: true })} className='w-100'>
                        <option value="24">2024</option>
                        <option value="25">2025</option>
                        <option value="26">2026</option>
                        <option value="27">2027</option>
                        <option value="28">2028</option>
                        <option value="29">2029</option>
                        <option value="30">2030</option>
                        <option value="31">2031</option>
                        <option value="32">2032</option>
                        <option value="33">2033</option>
                        <option value="34">2034</option>
                        <option value="35">2035</option>
                      </select>

                      {/* <input {...register("Year", {valueAsNumber: true, required: true, maxLength: 2, pattern: /^[0-9]*$/ })} defaultValue={props.Year} className='w-100' placeholder='YY' maxlength="2" /> */}
                      <span className="error">{errors.Year?.type === 'required' && "Expiration year is required"}</span>
                    </div>
                  </div>
                  <div className='mb-4 pb-2'>
                    <div className={`input-box`}>
                      <label className='text-bold text-uppercase' htmlFor="CVC">
                          {t('SECURITY CODE')}
                      </label>
                      <input {...register("CVC", {valueAsNumber: true, required: true,pattern: /^[0-9]*$/ })} defaultValue={props.CVC} className='w-100' />
                      <span className="error">{errors.CVC?.type === 'required' && "Card security code is required"}</span>
                      <span className="error">{errors.CVC?.type === 'pattern' && "Invalid code"}</span>
                    </div>
                    {/*
                    <span className="text-green example">
                      What is this?
                    </span>
                  */}
                  </div>
                  <input type="submit" className='button red-button' value={t('Continue')}/>

              </div>
            </form>
          </div>
  )
}


export default connect(mapStateToProps)(BillingForm)