import React, { useRef, useState, useEffect, Suspense, useLayoutEffect } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import {
  useTexture,
  Text,
  Decal,
  RenderTexture,
  PerspectiveCamera,
  Sphere,
} from '@react-three/drei'
import CustomizedFaces from "./CustomizedFaces"
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'


function Ball(props) {

  const ballFront = props.ballFront
  const ballBack = props.ballBack
  const faceToShow = props.faceToShow
  const allColors = props.allColors
  const currentItem = props.currentItem

  const textRef = useRef()

  const bumpMap = useLoader(TextureLoader, 'images/3d/golfball.jpg')

  const top = useTexture(currentItem.LogoTopImage)
  const side = useTexture(currentItem.LogoSide1Image)
  const bottom = top
  const titleist = useTexture('images/3d/titleist-logo.png')
  const prov1 = useTexture('images/3d/titleist-prov1.png')

  const mesh = useRef()
  mesh.MultiplyBlending=true

  let timer

  useEffect(()=>{
    switch(props.faceToShow) {
      case 'Front':
        currentRotation = () => {}
        gotToFrontView()
        break;
      case 'Back':
        currentRotation = () => {}
        gotToBackView()
        break;
      case 'Circle':
        startRotation();
        break;
      case 'Rotate':
        startRotation()
        break;
      default:
        // code block
    }
  },[props.faceToShow])

  useFrame((state, delta) => ( currentRotation() ))
  useFrame((state, delta) => ( TWEEN.update() ))

  let currentRotation = () => {

  }

  function startRotation() {
    currentRotation = ()=>{
      //mesh.current.rotation.x += 0.01;
      if (mesh.current.rotation.y + .01 >= 3.14*3) {
        mesh.current.rotation.y = 3.14;
      }
      mesh.current.rotation.x = Math.sin(mesh.current.rotation.y)/2;
      mesh.current.rotation.y += 0.01;
      //mesh.current.rotation.z += 0.01;
    }
  }

  function gotToFrontView() {
    //var targetPosition = new THREE.Vector3( 0, 3.14, 0 );
    var tweenToFront = new TWEEN.Tween(mesh.current.rotation).to({ x: 0, y: 3.14, z: 0 }, 500).easing(TWEEN.Easing.Quadratic.InOut).start()
  }

  function gotToBackView() {
    var tweenToBack = new TWEEN.Tween(mesh.current.rotation).to({ x: 0, y: 0, z: 0 }, 500).easing(TWEEN.Easing.Quadratic.InOut).start()
  }

  //const onefaceTopRotation = [-1.57, 0, 0] // Top logo not rotated
  //const twofaceTopRotation = [-1.57, 0, 0] // Top logo rotated
  //const onefaceAimingPosition = [0,0,-3] // Aiming goes on back
  //const twofaceAimingPosition = [-3, 0, 0] // Aiming goes on left side
  const topRotation = ballBack.Type != 'NONE' ? [-1.57, 0, 3.14] :  [-1.57, 0, 1.57] // <-- Change last one for one face
  const bottomRotation = ballBack.Type != 'NONE' ? [-1.57, 3.14, 3.14] :  [-1.57, 3.14, -1.57] // <-- Change last one for one face
  const alignmentPosition = ballBack.Type != 'NONE' ? [-3, 0, 0] : [0,0,-3]
  const alignmentRotation = ballBack.Type != 'NONE' ? [0, -1.57, 0] : [0, 3.14, 0] // <-- Change last one for one face


  // Return view, these are regular three.js elements expressed in JSX
  return (
    <mesh
      position={[0, 0, -4]}
      ref={mesh}>
      <sphereGeometry args={[3, 64]} />
      <meshPhysicalMaterial 
        color={'white'}
        normalMap={bumpMap}
        roughness={0.15}
        clearcoat={0.01}
        clearcoatRoughness={0.8}
        transmission={.1}
      />
      <CustomizedFaces allColors={allColors} ballFront={ballFront} ballBack={ballBack} />
      <Decal BOTTOM position={[0, -3, 0]} rotation={bottomRotation} scale={4} map={bottom} map-anisotropy={32} />
      <Decal TOP position={[0, 3, 0]} rotation={topRotation} scale={4} map={top} map-anisotropy={32} />
      <Decal LEFT position={alignmentPosition} rotation={alignmentRotation} scale={3.5} map={side} map-anisotropy={32} />
      {/*<Decal RIGHT position={[3, 0, 0]} rotation={[0, 1.57, 0]} scale={3} map={side} map-anisotropy={32} />*/}
    </mesh>
  )
}


const ThreeDBall = (props) => {

  const mesh = useRef()

  const faceToShow = props.faceToShow

  const bumpMap = useLoader(TextureLoader, 'images/3d/golfball.jpg')

  const ballFront = props.ballFront
  const ballBack = props.ballBack
  const allColors = props.allColors
  const currentItem = props.currentItem


	return ( <div style={{ width: "100%", height: "40vh", backgroundColor:"#fff" }}>
    {/*<div style={{display:'none'}}><img id='kbImage' src='images/3d/kb.jpg' /></div>*/}
      <Canvas
      camera={{position: [0, 0, -15], fov: 35}}
      >
        <ambientLight intensity={.3} />
        <pointLight intensity={1} position={[60, 10, -40]} />
        <group>
          <Ball currentItem={currentItem} allColors={allColors} faceToShow={faceToShow} ballFront={ballFront} ballBack={ballBack} />
        </group>
      </Canvas>
    </div> )

}




export default ThreeDBall





