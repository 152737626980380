// Product actions

import { 
  LOAD_SKUS_TO_STATE,
} from './product.types';


export const loadSkusToState = (_skus) => {
	return {
	    type: LOAD_SKUS_TO_STATE,
	    payload: _skus
	}
}


