// Order Actions

import { 
  DELETE_FACE,
  SET_PRODUCT,
  SET_CURRENTLY_EDITING,
  SET_BALL_TEXTS,
  LOCK_IN_TEXT,
  SET_LOGO_AND_LOGO_NAME,
  RESET_LOGO_AND_LOGO_NAME_FOR_FACE,
  SET_COLOR,
  SET_CROPPED_LOGO_SRC,
  SET_SAME_AS_FRONT,
  SET_QUANTITY,
  SET_VECTOR_LOGO_DETAILS_FOR_FACE,
  RESET_VECTOR_LOGO_DETAILS_FOR_FACE,
  SET_ORDER_ID,
  SET_BAMBORA_ID,
  ORDER_RESET_FOR_REPEAT
} from './order.types';


export const deleteFace = (face) => {
	return {
    type: DELETE_FACE,
    payload: face
	}
}

export const setCurrentProduct = (productObj) => {
  return {
    type: SET_PRODUCT,
    payload: productObj
  }
}

export const setBallTexts = (e) => {
  return {
    type: SET_BALL_TEXTS,
    payload: e
  }
}

export const lockInText = () => {
  return {
    type: LOCK_IN_TEXT,
  }
}

export const setCurrentlyEditing = (face) => {
  if (face !='Front' && face !='Back') { }
  return {
    type: SET_CURRENTLY_EDITING,
    payload: face
  }
}

export const resetLogoAndLogoNameForFace = (face) => {
  if (face !='Front' && face !='Back') { }
  return {
    type: RESET_LOGO_AND_LOGO_NAME_FOR_FACE,
    payload: face
  }
}

export const setColor = (color) => {
  return {
    type: SET_COLOR,
    payload: color
  }
}

export const setLogoAndLogoName = (logo, logoName) => {
  return {
    type: SET_LOGO_AND_LOGO_NAME,
    payload: {
      logo,
      logoName
    }
  }
}


export const setCroppedLogoSrc = (src, notes) => {
  return {
    type: SET_CROPPED_LOGO_SRC,
    payload: {src: src, notes: notes}
  }
}

export const setSameAsFront = () => {
  return {
    type: SET_SAME_AS_FRONT,
  }
}

export const setQuantity = (quantity) => {
  return {
    type: SET_QUANTITY,
    payload: quantity
  }
}

export const setVectorLogoDetailsForFace = (logoObj) => {
  return {
    type: SET_VECTOR_LOGO_DETAILS_FOR_FACE,
    payload: { 
      ...logoObj
    }
  }
}


export const resetVectorLogoDetailsForFace = (face) => {
  if (face !='Front' && face !='Back') { }
  return {
    type: RESET_VECTOR_LOGO_DETAILS_FOR_FACE,
    payload: face
  }
}


export const setOrderID = (ID) => {
  return {
    type: SET_ORDER_ID,
    payload: ID
  }
}

export const setBamboraID = (ID) => {
  return {
    type: SET_BAMBORA_ID,
    payload: ID
  }
}

export const orderResetForRepeat = (ID) => {
  return {
    type: ORDER_RESET_FOR_REPEAT
  }
}
