import { Table, InputGroup, Button, Input, Container, Row, Col } from 'reactstrap';
import { useState, useRef, forwardRef } from 'react'
import { useSelector } from "react-redux"
import ViewFace from '../../components/ViewFace'
import Contact from './Contact'
import { currencyFormatter } from '../../helpers/currencyFormatter'
import { FaClipboard, FaDownload } from "react-icons/fa";
import { formatDate } from '../parts/helperFunctions'
import Logo from '../../components/Logo/Logo';
import './ProductionInstructions.scss';
import vectorIdentifier from '../../helpers/vectorIdentifier'

const endpoint = process.env.REACT_APP_API_ENDPOINT


const ProductionInstructions = forwardRef((props, ref) => {

	const _obj = props.obj
	const _product = props.product

	const colors = useSelector(state => state.product.colors)



	function downloadFile(urlToSend, fileName) {
		var req = new XMLHttpRequest();
		req.open("GET", urlToSend, true);
		req.responseType = "blob";
		req.onload = function (event) {
			var blob = req.response;
			var link=document.createElement('a');
			link.href=window.URL.createObjectURL(blob);
			link.download=fileName;
			link.click();
		};
		req.send();
	}



	return (
		<div ref={ref}>
		<Container className='production-instructions'>
		  <Row>
		  	<Col >
			  	<Logo />
			  	<h2>PRODUCTION DETAILS</h2>
		  	</Col>
		  </Row>
		  <Row md="2" sm="1" xs="1">
		    <Col>
		      <h3>Shipping Address</h3>
		      <Contact contactObj={_obj.Billing.ShippingAddress} context="PRODSHIPPING" />
		      <h3 className='billing'>Billing Contact</h3>
		      <Contact contactObj={_obj.Billing.ShippingAddress} context="PRODCONTACT" />
		    </Col>
		    <Col>
		      <h3>Order Status</h3>
		      <p><b>Order# {_obj.BamboraPreAuth.ID}</b><br />
		      <i>Placed on {formatDate(_obj.BamboraPreAuth.Created)}</i></p>
		      {_obj.TrackingCode !== '' ? <p><b>FedEx Tracking# {_obj.TrackingCode}</b><br /><i>Shipped on {formatDate(_obj.TrackingTimestamp)}</i></p> : null}
		      {_obj.RetailPro !== '' ? <p><b>RetailPro# {_obj.RetailPro}</b></p> : null}
		      {_obj.RetailProCustomer !== '' ? <p><b>RetailPro Customer# {_obj.RetailProCustomer}</b></p> : null}
		    </Col>
		  </Row>

		  <Row md="2" sm="1" xs="1" gx="5">
		    <Col>
		      <h3>Product Details</h3>
		      <p>
		      {_obj.Quantity} Dozen<br />
		      {_product.BrandName} {_product.NameEN}<br />
		      GTSKU: {_product.GTSKU}
		      </p>

				{_obj.Front.Type ==='LOGO' 
		      		? <div>
						<p>
							<b>Customization 1</b> –&nbsp;
							<a 
								onClick={()=>downloadFile(`${endpoint}/logos/${_obj.Front.Logo}/${_obj.Front.VectorName}`,_obj.Front.OriginalFileName)}
							> LOGO - {_obj.Front.OriginalFileName}&nbsp;<FaDownload className='icons' /></a>{!vectorIdentifier(_obj.Front.OriginalFileName) ? <span className="digitization-flag"><br />DIGITIZATION REQUIRED<br /></span> : null}
						</p> 
		      		</div>
		      		: null}
		      	{_obj.Front.Type === 'TEXT' 
		      		? <div>
						<p>
							<b>Customization 1</b> – <a onClick={() =>  navigator.clipboard.writeText(`${_obj.Front.Line1}\n${_obj.Front.Line2}\n${_obj.Front.Line3}`)}>TEXT <FaClipboard className='icons' /></a><br/>
							LINE 1: <b>{_obj.Front.Line1}</b><br/>
							LINE 2: <b>{_obj.Front.Line2}</b><br/>
							LINE 3: <b>{_obj.Front.Line3}</b>
						</p>
						<p>COLOUR: {_obj.Front.Color}</p>
		      		  </div> 
		      		: null}

				{_obj.Back.Type ==='LOGO' 
		      		? <div>
						<p>
							<b>Customization 2</b> –&nbsp;
							<a 
								onClick={()=>downloadFile(`${endpoint}/logos/${_obj.Back.Logo}/${_obj.Back.VectorName}`,_obj.Back.OriginalFileName)}
							> LOGO - {_obj.Back.OriginalFileName}&nbsp;<FaDownload className='icons' /></a>{!vectorIdentifier(_obj.Back.OriginalFileName) ? <span className="digitization-flag"><br />DIGITIZATION REQUIRED<br /></span> : null}
						</p> 
		      		</div>
		      		: null}
		      	{_obj.Back.Type === 'TEXT' 
		      		? <div>
						<p>
							<b>Customization 2</b> – <a onClick={() =>  navigator.clipboard.writeText(`${_obj.Back.Line1}\n${_obj.Back.Line2}\n${_obj.Back.Line3}`)}>TEXT <FaClipboard className='icons' /></a><br/>
							LINE 1: <b>{_obj.Back.Line1}</b><br/>
							LINE 2: <b>{_obj.Back.Line2}</b><br/>
							LINE 3: <b>{_obj.Back.Line3}</b>
						</p>
						<p>COLOUR: {_obj.Back.Color}</p>
		      		  </div> 
		      		: null}
		    </Col>
		    <Col>
		      <h3>Invoice</h3>
		      <table>
		      	<tbody>
		      	<tr>
		      		<td>{_obj.Quantity} Dozen Balls @ ${_obj.Cost.CostPer} per</td>
		      		<td className="money">{currencyFormatter('en').format( _obj.Cost.CostPer * _obj.Quantity )}</td>
		      	</tr>
		      	<tr>
		      		<td>Customization</td>
		      		<td className="money">{currencyFormatter('en').format( _obj.Cost.FrontCustomCost + _obj.Cost.BackCustomCost )}</td>
		      	</tr>
		      	{_obj.Cost.DigitizationFee ? 
		      	<tr>
		      		<td>Digitization</td>
		      		<td className="money">{currencyFormatter('en').format( _obj.Cost.DigitizationFee )}</td>
		      	</tr> : null}
		      	<tr className='lineabove'>
		      		<td>Subtotal</td>
		      		<td className="money">{currencyFormatter('en').format( _obj.Cost.SubTotal )}</td>
		      	</tr>
		      	<tr>
		      		<td>Taxes</td>
		      		<td className="money">{currencyFormatter('en').format( _obj.Cost.Taxes )}</td>
		      	</tr>
		      	<tr>
		      		<td>Shipping</td>
		      		<td className="money">{currencyFormatter('en').format( _obj.Cost.Shipping )}</td>
		      	</tr>
		      	<tr className='thicklineabove'>
		      		<td>Total</td>
		      		<td className="money">{currencyFormatter('en').format( _obj.Cost.Total )}</td>
		      	</tr>
		      	</tbody>
		      </table>
		    </Col>
		  </Row>

		  <Row md="2" sm="1" xs="1">
		    <Col>
		    	<h3>Preview - Front</h3>
			    <ViewFace context='PRODNOTES' obj={_obj} face='Front' product={_product} />
				{_obj.Front.Notes != "" ? <p>{_obj.Front.Notes}</p> : null}
		    </Col>
		    <Col>
		    	<h3>Preview - Back</h3>
				<ViewFace context='PRODNOTES' obj={_obj} face='Back' product={_product} />
				{_obj.Back.Notes != "" ? <p>{_obj.Back.Notes}</p> : null}
		    </Col>
		  </Row>
		</Container>
		</div>

)});

export default ProductionInstructions