import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useState } from 'react'
import './Pagination.scss';


function Paused(props) {

	return (
		<Pagination>
		  <PaginationItem>
		    <PaginationLink
		      first
		      href="#"
		    />
		  </PaginationItem>
		  <PaginationItem>
		    <PaginationLink
		      href="#"
		      previous
		    />
		  </PaginationItem>
		  {/*START LOOP*/}
		  <PaginationItem>
		    <PaginationLink href="#">
		      1
		    </PaginationLink>
		  </PaginationItem>
		  {/*END LOOP*/}
		  <PaginationItem>
		    <PaginationLink
		      href="#"
		      next
		    />
		  </PaginationItem>
		  <PaginationItem>
		    <PaginationLink
		      href="#"
		      last
		    />
		  </PaginationItem>
		</Pagination>

	)
}

export default Paused