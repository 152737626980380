import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//const axios = require('axios')
import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT

const usePlaceOrder = (ID) => {

    const [status, setStatus] = useState("idle")
    const [data, setData] = useState([])
    const [error, setError] = useState("")

    const jwt = useSelector(state => state.app.jwt)

    useEffect(() => {

        if (ID != "") {

            setStatus('loading')

            const placeOrder = async () => {

                //setTimeout(async ()=>{

                    try {
 
                        const response = await axios.post(
                            `${endpoint}/order/${ID}/place`,
                            {},
                            { headers: 
                                {
                                    Authorization: `Bearer ${jwt}` 
                                }
                            }
                        )

                        if (response.data.BamboraPreAuth.Approved == "1") {
                            setData(response.data)
                            setStatus('done')
                        } else {
                            throw "Transaction not approved"
                        }
                    }
                    catch(e) {
                        setError('ERROR: ' + JSON.stringify(e))
                        setStatus('error');
                        throw "API ERROR – DATA COULD NOT BE UPLOADED"
                    }
                //}, 3000)

            }

            placeOrder()

        } else {
            setStatus("idle")
            setData([])
            setError("")
        }


    }, [ID]);

    return { status, data, error };

};

export default usePlaceOrder