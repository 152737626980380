// Shipping Reducer

import { 
    SET_SHIPPING_INFO,
} from './shipping.types';

const INITIAL_STATE = {
};

const shippingReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case SET_SHIPPING_INFO:

            return {
                ...state, 
                ...action.payload
            };

        break;

        default: return state;

    }

};

export default shippingReducer;