import PrintContainer from './PrintContainer'
import { useDispatch } from "react-redux"
import { setContentModal } from '../../redux/App/app.actions'
import './CustomizationSummary.scss'

function CustomizationSummary({context, orderObj, product}) {

	const dispatch = useDispatch()

	return (
		<div className='console-customization-summary'>
			<p>
				<span className='pole-label'>Pole 1:</span> <b>{orderObj.Front.Type}</b><br />
				{orderObj.Back.Type != 'NONE'
				  ? <><span className='pole-label'>Pole 2:</span> <b>{orderObj.Back.Type}</b></>
				  : null }
			</p>
			<a onClick={()=>dispatch(setContentModal({body: <PrintContainer obj={orderObj} product={product} />, title: "Golfball Customizer"}))}>Production</a>
		</div>
	)
}

export default CustomizationSummary
