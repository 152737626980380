import React from 'react';
import { connect, useDispatch   } from "react-redux";
import './Products.scss';
import Product from './Product';  
import { setCurrentProduct } from '../../redux/Order/order.actions';

const mapStateToProps = (state) => ({
  productsObj: state.product.balls,
})


function Products(props) {
  const { events,products } = props;

  const dispatch = useDispatch();

  const changeSelectedProduct = (_productObj) => {
    dispatch(setCurrentProduct(_productObj));
  }

  return (
    <div className="row py-4 my-2">
        {
          props.products.map(productObj => {
            return productObj.Live === true ? <Product changeSelectedProduct={changeSelectedProduct} events={events} product={productObj} key={productObj.ID} /> : null
          })
        }
    </div>
  )
}

export default connect(mapStateToProps)(Products)