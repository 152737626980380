import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import ProductionInstructions from './ProductionInstructions'
import { FaPrint } from "react-icons/fa";
import './PrintContainer.scss'

function PrintContainer({obj: _obj, product: _product}) {

	const printAreaRef = useRef()

	return (
	    <div>
	      <ReactToPrint
	        trigger={() => <Button className='print-button'><FaPrint /> Print</Button>}
	        content={() => printAreaRef.current}
	      />
	      <ProductionInstructions ref={printAreaRef} obj={_obj} product={_product} />
	    </div>
	)
}

export default PrintContainer