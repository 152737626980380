import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next';

//const axios = require('axios')
import axios from 'axios';


const useCanPostSearch = (_searchText) => {

    const [status, setStatus] = useState('loading')
    const [data, setData] = useState([])
    const [error, setError] = useState("")

    const { t, i18n } = useTranslation();
    const language = i18n.language

    useEffect(() => {

        const search = async () => {
            if (_searchText.length > 2) {
                
                try {

                    //Make call to Can with text to get addresses array

                    const {data:response_search} = await axios.get(
                        `https://ws1.postescanada-canadapost.ca/addresscomplete/interactive/find/v2.10/json3.ws`,
                        { 
                            params: { 
                                provider: "AddressComplete",
                                package: "Interactive",
                                service: "Find",
                                version: "2.1",
                                endpoint: "json3.ws",
                                Key: "DW11-NY86-CJ12-WP84",
                                SearchTerm: _searchText,
                                LastId: "",
                                Country: "CAN",
                                LanguagePreference: language,
                                MaxSuggestions: 7
                            } 
                        }
                    )

                    setData({ ...response_search.Items })
                    setStatus('done');

                } catch {

                    setError("COULD NOT COMPLETE LOOKUP")
                    throw "API ERROR – COULD NOT LOOKUP ADDRESS"

                }
            }
        }


        setStatus('loading');
        search();

    }, [_searchText]);

    return { status, data, error }

};

export default useCanPostSearch