import { Table, InputGroup, Button, Input } from 'reactstrap';
import { useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import Pagination from './parts/Pagination'
import OrderSummary from './parts/OrderSummary'
import Contact from './parts/Contact'
import CustomizationSummary from './parts/CustomizationSummary'
import PauseControl from './parts/PauseControl'
import TrackingNumControl from './parts/TrackingNumControl'
import { formatDate } from './parts/helperFunctions'
import { FaSort } from "react-icons/fa";
import './Production.scss';

function Production(props) {

	const products = useSelector(state => state.product.balls)
	const orders = useSelector(state => state.console.orders)
				   .filter(p => (p.Status === 'PRODUCTION') && (p.Paused === false))

	const [retailProNums, setRetailProNums] = useState({})
	const [dateSortForward, setDateSortForward] = useState(true)
	const [sortedOrders, setSortedOrders] = useState([])

	const adminGroupIndex = useSelector(state => state.app.adminGroup)

	const sortOrders = () => {
		setSortedOrders ( orders.sort(function(a, b) {
		    var c = new Date(a.Updated);
		    var d = new Date(b.Updated);
		    return dateSortForward ? c-d : d-c;
		}) )
	}

	useEffect(()=>{
		sortOrders()
	},[dateSortForward, orders.length])

	return (
	    <div className="dashboard-inbound">

	    	<div className="tab-description mt-3">
	    		<h6>This tab is for use by Pad Print Extreme.</h6>
				<p>Enter and save the FedEx Tracking Number for each production item to mark the order as complete.</p>
			</div>

			<Table className="inbound-table" responsive striped >
			  <thead>
			    <tr>
			      <th className='sortable' onClick={()=>{setDateSortForward(!dateSortForward)}}>
			        Date <FaSort className='icons' />
			      </th>
			      <th>
			        Summary
			      </th>
			      <th>
			        Shipping
			      </th>
			      <th>
			        Product
			      </th>
			      <th>
			        Customizations
			      </th>
			      <th>
			        FedEx Tracking #
			      </th>
			    </tr>
			  </thead>

			  <tbody>
			  { sortedOrders.map((orderObj) => {

		  		const product = products.filter(product => product.ID === orderObj.ProductID)[0]

		  		return (
				    <tr key={orderObj.ID}>
				      <td>
				        {formatDate(orderObj.Updated)}
				      </td>
				      <td>
				        <OrderSummary orderObj={orderObj} context="PRODUCTION" />
				      </td>
				      <td>
				      	<Contact contactObj={orderObj.Billing.ShippingAddress} context="INBOUND" />
				      </td>
				      <td>
				        <p>
					        <b>{product.BrandName} {product.NameEN}</b>
						</p>
				      </td>
				      <td>
				      	<CustomizationSummary orderObj={orderObj} product={product} context="INBOUND" />
				      </td>
				      <td>
				      	{adminGroupIndex === 2 || adminGroupIndex === 0 ? <TrackingNumControl orderObj={orderObj} context='PRODUCTION' /> : <p><i>Only Production Partners can add FedEx #</i></p>}
				      	<div className='mt-2'>
							<PauseControl orderObj={orderObj} context='PRODUCTION' />
				      	</div>
				      </td>
				    </tr>

					) })
			  }



			  </tbody>
			</Table>
			<Pagination />
		</div>
	)
	
}

export default Production