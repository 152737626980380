import React from 'react';
import { connect } from "react-redux"
import CustomizationTextBox from './CustomizationTextBox';
import CustomizationLogoBox from './CustomizationLogoBox';
import './CustomizationBoxes.scss';
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state) => ({
  ballFront: state.order.Front,
  ballBack: state.order.Back,
})


function CustomizationBoxes(props) {

    const changeStep = props.changeStep;
    const ballFront = props.ballFront;
    const ballBack = props.ballBack;
    const { t, i18n } = useTranslation()

    var componentArr = [];

    if (ballFront.Type ==='NONE' && ballBack.Type ==='NONE') {

        return (

            <div className='text-center text-sm-start customization-box customization-boxes mt-2 no-customizations' >
                <div className='d-sm-flex align-items-top justify-content-between'>
                    <div>
                        <span className='text-bold text-uppercase'>
                            {t('NO CURRENT CUSTOMIZATIONS')}
                        </span>
                    </div>
                    <div>
                        <button className='button pt-2' onClick={()=>{
                            changeStep(2);
                        }}>
                            <label htmlFor='upload-logo'>
                                {t('Add Customization')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>

        )
    }

    switch (ballFront.Type) {
        case 'LOGO':
            componentArr.push(
                <div className="mt-2" key={1}>
                    <CustomizationLogoBox face='Front' changeStep={changeStep} faceDetails={ballFront} boxKey={0} />
                </div>
            );
            break;
        case 'TEXT':
            componentArr.push(
                <div className="mt-2" key={1}>
                    <CustomizationTextBox face='Front' changeStep={changeStep} faceDetails={ballFront} boxKey={0} />
                </div>
            );
            break;
        default:
            componentArr.push(
                <div className="mt-2" key={1} >
                </div>
            );
    }

    switch (ballBack.Type) {
        case 'LOGO':
            componentArr.push(
                <div className="mt-2" key={2}>
                    <CustomizationLogoBox face='Back' changeStep={changeStep} faceDetails={ballBack} />
                </div>
                );
            break;
        case 'TEXT':
            componentArr.push(
                <div className="mt-2" key={2}>
                    <CustomizationTextBox face='Back' changeStep={changeStep} faceDetails={ballBack} />
                </div>
            );
            break;
        default:
            componentArr.push(
            <div key={3} className='text-center text-sm-start customization-box customization-boxes mt-2 no-customizations' >
                <div className='d-sm-flex align-items-top justify-content-between'>
                    <div>
                        <span className='text-bold text-uppercase'>
                        </span>
                    </div>
                    <div>
                        <button className='button pt-2' onClick={()=>{
                            changeStep(2);
                        }}>
                            <label htmlFor='upload-logo'>
                                {t('Add Customization')}
                            </label>
                        </button>
                    </div>
                </div>
            </div>
            );
    }

    return (
        <div>
            {componentArr}
        </div>
    )

}

export default connect(mapStateToProps)(CustomizationBoxes)