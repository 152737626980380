import { connect, useDispatch } from "react-redux"
import useAuthenticator from '../hooks/useAuthenticator'
import { useState, useEffect } from 'react'
import { setError, clearError, setLoading, clearLoading } from '../redux/App/app.actions'
import ErrorModal from '../components/Modal/ErrorModal.js'
import ContentModal from '../components/Modal/ContentModal.js'
import LoadingSpinner from '../components/LoadingSpinner'
import { useForm } from 'react-hook-form';
import './Console.scss';
import Dashboard from './Dashboard'
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux"
import useConsoleProductLoader from '../hooks/useConsoleProductLoader'

function Console(props) {

	const {status: _prodStatus, data: _prodData, error: _prodError} = useConsoleProductLoader()

	return (
		<div className="console">
			<LoadingSpinner />
			<ErrorModal />
			<ContentModal />
			<Outlet />
		</div>
	)
	
}


export default Console