import i18n from "i18next";


/* ADD TO FILE TO USE

import { useTranslation } from 'react-i18next'
import { currencyFormatter } from '../helpers/currencyFormatter'

const { t, i18n } = useTranslation()

{currencyFormatter(i18n).format( 5.00 )}

i18n.language = 'en' || 'fr'

{t('TEXT')}


*/



i18n.init({
    resources : {
        en : {
            translations : {

                // Sidebars and Top Bar
                "TITLE PART 1" : "GOLF BALL",
                "TITLE PART 2" : "CUSTOMIZER",
                "Select Ball 1" : "Select",
                "Select Ball 2" : "Ball",
                "Customize Ball 1" : "Customize",
                "Customize Ball 2" : "Ball",
                "Complete Order 1" : "Complete",
                "Complete Order 2" : "Order",
                "FREE SHIPPING ON ORDERS OVER $99." : "FREE SHIPPING ON ORDERS OVER $99.",
                "Select Your Ball" : "Select Your Ball",
                "Filter by Brand" : "Filter by Brand",
                "SHOW ALL" : "SHOW ALL",
                "Sort by Price": "Sort by Price",
                "High to Low": "High to Low",
                "Low to High": "Low to High",

                // Customization & Pricing Table
                "Customization Pricing" : "Customization Pricing",
                "(per dozen)" : "(per dozen)",
                "CustomizationTableLeadin" : "Logo customization or text personalization orders can be placed in any combination of single or double-sided (please note; balls are manually placed prior to printing, positioning deviations may appear). Are you ordering more than 120+ dozen? You may qualify for free customization and/or special pricing.",
                "Contact us" : "Contact us",
                "for more information." : "for more information.",
                "Quantity" : "Quantity",
                "Quantity (p/dz)": "Quantity (p/dz)",
                "Front" : "Front",
                "Back" : "Back",
                "FRONT" : "FRONT",
                "BACK" : "BACK",
                "Savings" : "Savings",
                "FREE" : "FREE",
                "100%" : "100%",
                "50%" : "50%",

                //Products
                "View Description" : "View Description",
                "Customization" : "Customization",
                "Customize" : "Customize",

                //Product Change Modal
                "SELECT A DIFFERENT BALL" : "SELECT A DIFFERENT BALL",
                "Select Ball" : "Select Ball",

                //Step 2
                "CUSTOMIZE YOUR BALL" : "CUSTOMIZE YOUR BALL",
                "Choose Customization" : "Choose Customization",
                "PAGE2-BODY-1" : "Customize one or two sides of your ball with any combination of text and logos. Minimum quantity 12 dozen.",
                "Add Text" : "Add Text",
                "TEXT-DESCRIPTION-1" : "Add up to three lines of text in a variety of colours.",
                "Add Logo" : "Add Logo",
                "LOGO-DESCRIPTION-1" : "File requirements: Vector PDF, EPS, or AI. Maximum 6 colours.",
                "SAMPLE-TEXT-1" : "Your",
                "SAMPLE-TEXT-2" : "Personalized",
                "SAMPLE-TEXT-3" : "Text Here!",
                "Select Colour" : "Select Colour",
                "Add Line 1" : "Add Line 1",
                "Add Line 2" : "Add Line 2",
                "Add Line 3" : "Add Line 3",
                "Continue" : "Continue",
                "OPTIONAL – Customize the Back Side" : "OPTIONAL – Customize the Back Side",
                "OPTIONAL-DESCRIPTION-1" : "Add another customization to the other side of your ball.",
                "OPTIONAL-DESCRIPTION-2" : "Note: Additional charges apply.",
                "Same As Front" : "Same As Front",
                "No further customizations required" : "No further customizations required",
                "Adjust Logo" : "Adjust Logo",
                "FIT-DESCRIPTION-1" : "Size your logo to fit in the circular area.",
                "VERIFY-OWNERSHIP-1" : "I verify that I own the copyright and/or have permission to use the uploaded image.",
                "COLOR-VERIFY-1" : "In the case that the provided logo does not contain PMS colors, I agree to allow the production team to select the closest appropriate colour match.",
                "Select Logo File" : "Select Logo File",
                "VECTOR-SELECT-DESCRIPTION" : "Select the logo you would like to use, Vector format (AI, PDF, SVG, or EPS) is preferred. Files supplied in other formats will be subject to a $65 digitization fee. Need help? Email us at <a href='mailto:gtpromotions@golftown.com' style='color:var(--green)'>gtpromotions@golftown.com</a>.",
                "Production Notes:": "Production Notes & Pantone Colours:",
                "PantoneWarning": "Please specify your logo's Pantone Colour(s) and enter any production notes here. Please note, artwork and orders without specified Pantone colours may be delayed.",
                "VECTOR-EXPLANATION-HEAD": "What is a Vector File and Why Do I Need it?",
                "VECTOR-EXPLANATION-BODY": "Vector files reproduce perfectly no matter what size they are scaled to, ensuring the highest quality possible. Small details in bitmap/raster (PNG, JPG, etc.) logos can appear rough, or disappear completely.",
                "Vector": "Vector",
                "Bitmap": "Bitmap",
                "PANTONE MODAL" : "<h2>PANTONE COLOURS</h2><p>All colours in your logo need to be designated Pantone colours in order for us to print your logo colours accurately. Be sure to provide your Pantone colours to avoid delays. Need help? Email us at <a href='mailto:gtpromotions@golftown.com' style='color:green;'>gtpromotions@golftown.com</a></p>",

                //Summary View
                "COMPLETE YOUR ORDER" : "COMPLETE YOUR ORDER",
                "SELECT QUANTITY" : "SELECT QUANTITY",
                "VIEW BACK" : "VIEW BACK",
                "VIEW FRONT" : "VIEW FRONT",
                "Illustration used for example purposes only. Actual production may vary." : "Illustration used for example purposes only. Actual production may vary.",
                "dozen" : "dozen",
                "Dozen" : "Dozen",
                "ITEM" : "ITEM",
                "COST" : "COST",
                "Price does not include shipping & applicable taxes." : "Price does not include shipping & applicable taxes.",
                "TOTAL" : "TOTAL",
                "SECURE CHECKOUT" : "SECURE CHECKOUT",
                "FRONT CUSTOMIZATION" : "FRONT CUSTOMIZATION",
                "BACK CUSTOMIZATION" : "BACK CUSTOMIZATION",
                "Front Customization" : "Front Customization",
                "Back Customization" : "Back Customization",
                "TEXT" : "TEXT",
                "LOGO" : "LOGO",
                "dozen balls" : "dozen balls",

                //Customization Boxes
                "Cancel" : "Cancel",
                "Change" : "Change",
                "DELETE" : "DELETE",
                "Add Customization" : "Add Customization",
                "NO CURRENT CUSTOMIZATIONS" : "NO CURRENT CUSTOMIZATIONS",

                //Checkout
                "CHECKOUT" : "CHECKOUT",
                "STEP 1 - Shipping" : "STEP 1 - Shipping",
                "step 1:" : "STEP 1:",
                "Shipping" : "Shipping",
                "STEP 2 - Billing" : "STEP 2 - Billing",
                "step 2:" : "STEP 2:",
                "Billing" : "Billing",
                "STEP 3 - Place Order" : "STEP 3 - Place Order",
                "step 3:" : "STEP 3:",
                "Place Order" : "Place Order",
                "Enter Shipping Address" : "Enter Shipping Address",
                "We only ship within Canada" : "We only ship within Canada",
                "FIRST NAME" : "FIRST NAME",
                "LAST NAME" : "LAST NAME",
                "ADDRESS LINE 1" : "ADDRESS LINE 1",
                "ADDRESS LINE 2" : "ADDRESS LINE 2",
                "CITY" : "CITY",
                "POSTAL CODE" : "POSTAL CODE",
                "Example: 1A23BC" : "Example: 1A23BC",
                "COUNTRY" : "COUNTRY",
                "PROVINCE" : "PROVINCE",
                "PHONE" : "PHONE",
                "EMAIL" : "EMAIL",
                "USE THIS ADDRESS FOR BILLING" : "USE THIS ADDRESS FOR BILLING",
                "Company Name" : "Company Name",

                //Billing & Credit
                "ORDER SUMMARY" : "ORDER SUMMARY",
                "Edit" : "Edit",
                "Enter Billing Address" : "Enter Billing Address",
                "Enter Credit Card Information" : "Enter Credit Card Information",
                "NAME ON CARD" : "NAME ON CARD",
                "TYPE" : "TYPE",
                "NUMBER" : "NUMBER",
                "EXPIRATION MONTH" : "EXPIRATION MONTH",
                "EXPIRATION YEAR" : "EXPIRATION YEAR",
                "Expiration and colon" : "Expiration:",
                "SECURITY CODE" : "SECURITY CODE",
                "What is this?" : "What is this?",
                "PLACE ORDER" : "PLACE ORDER",
                "THANK YOU!" : "THANK YOU!",
                "Subtotal" : "Subtotal",
                "Shipping" : "Shipping",
                "Sales Tax" : "Sales Tax",
                "Order Total" : "Order Total",
                "Billing Information" : "Billing Information",
                "Printable Summary" : "Printable Summary",
                "Print" : "Print",
                "BUILD SUMMARY": "BUILD SUMMARY",
                "Start Over": "Start Over",

                //Thank You
                "Your Order Has Been Placed" : "Your Order Has Been Placed",
                "THANK-TOY-MESSAGE-1" : "Thank you, your custom ball order has been received. We’ve just sent a receipt to your inbox. Please note: Production and delivery typically takes 7-10 business days. We’ll send you an email once your order ships. If you have any questions about your order, please contact us at gtpromotions@golftown.com. Please print this page for your records.",
                "Print this page" : "Print this page",
                "CLOSE & RETURN TO GOLF TOWN" : "CLOSE & RETURN TO GOLF TOWN",

                //Re-Order
                "REPEAT-ORDER-HEAD": "Want to Re-Order With Different Balls?",
                "REPEAT-ORDER-BODY": "Use the Order More button to return to the Order Summary page with all of your customizations conveniently pre-loaded, where you can select a different ball and complete another order.",
                "REPEAT-ORDER-BUTTON": "ORDER MORE",

                //Errors
                "OOPS, SOMETHING WENT WRONG": "OOPS, SOMETHING WENT WRONG",
                "BILLING DATA NOT ACCEPTED" : "BILLING DATA NOT ACCEPTED",
                "Your order could not be created. Please try again later." : "Your order could not be created. Please try again later.",
                "We weren't able to complete your transaction." : "We weren't able to complete your transaction.",
                "bambora preauth failed" : "Payment declined, please contact your card issuer.",
                "cc number is invalid" : "The payment information you submitted is not valid. Please re-enter payment information.",
                "File must be a vector file in AI, PDF, SVG or EPS format." : "FILE FORMAT NOT SUPPORTED. Need help? Please contact us at gtpromotions@golftown.com for more information.",
                "cc expiry date invalid" : "The payment information you submitted is not valid. Please re-enter payment information.",
                "Minimum quantity 12 dozen." : "Minimum quantity 12 dozen.",

                //Months
                "January" : "January",
                "February" : "February",
                "March" : "March",
                "April" : "April",
                "May" : "May",
                "June" : "June",
                "July" : "July",
                "August" : "August",
                "September" : "September",
                "October" : "October",
                "November" : "November",
                "December" : "December",

                //Provinces
                "Alberta" : "Alberta",
                "British Columbia" : "British Columbia",
                "Manitoba" : "Manitoba",
                "New Brunswick" : "New Brunswick",
                "Newfoundland and Labrador" : "Newfoundland and Labrador",
                "Northwest Territories" : "Northwest Territories",
                "Nova Scotia" : "Nova Scotia",
                "Nunavut" : "Nunavut",
                "Ontario" : "Ontario",
                "Prince Edward Island" : "Prince Edward Island",
                "Quebec" : "Quebec",
                "Saskatchewan" : "Saskatchewan",
                "Yukon" : "Yukon",

                //Launch Page
                "CUSTOMIZED GOLF BALLS" : "CUSTOMIZED GOLF BALLS",
                "Add your logo, tagline or text to your golf balls for a personal touch!" : "Add your logo, tagline or text to your golf balls for a personal touch!",
                "Customize your golf balls for any occasion!" : "Customize your golf balls for any occasion!",
                "Customize with your logo or with text" : "Customize with your logo or with text",
                "Minimum 12 dozen per order" : "Minimum 12 dozen per order",
                "No set up fees" : "No set up fees",
                "Single or double sided customization options" : "Single or double sided customization options",
                "Free shipping on your order*" : "Free shipping on your order*",
                "LAUNCH SHIPPING LEGAL" : "* Free shipping offer applies to ground shipping only and is excluded from the following territories: NT, YT & NU. We cannot deliver to P.O boxes.",
                "CUSTOM OPTIONS" : "CUSTOM OPTIONS",
                "Choose your ball, colour, text or logo to make a customized golf ball for your tournament, gifting or other occasion." : "Choose your ball, colour, text or logo to make a customized golf ball for your tournament, gifting or other occasion.",
                "PERSONALIZED TEXT" : "PERSONALIZED TEXT",
                "Make a statement with your company tagline, corporate hashtag, best golf pun or heartfelt message." : "Make a statement with your company tagline, corporate hashtag, best golf pun or heartfelt message.",
                "Order Now" : "Order Now",
                "LOGO\'D GOLF BALLS" : "LOGO'D GOLF BALLS",
                "Want to provide cool swag for customers, partners or employees? Upload and print your logo on your favourite golf balls." : "Want to provide cool swag for customers, partners or employees? Upload and print your logo on your favourite golf balls.",
                "NEED HELP? WE'RE HERE TO ASSIST!" : "NEED HELP? WE'RE HERE TO ASSIST!",
                "LAUNCH ASSISTANCE TEXT" : "Do you have a large corporate order or special request? Email us at <a href='mailto:gtpromotions@golftown.com'>gtpromotions@golftown.com</a> and a team member will be in touch in 1-2 business days.",
                "LAUNCH FURTHER ASSISTANCE" : "Looking for more personalized gifting? Our corporate sales team has a wide assortment or merchandise available for personalization to highlight your brand.",
                "LEARN MORE" : "LEARN MORE",


            }
        },
        fr : {
            translations : {

                // Sidebars and Top Bar
                "TITLE PART 1" : "PERSONNALISATION",
                "TITLE PART 2" : "DE BALLES",
                "Select Ball 1" : "Choisir",
                "Select Ball 2" : "une balle",
                "Customize Ball 1" : "Personnaliser",
                "Customize Ball 2" : "une balle",
                "Complete Order 1" : "Compléter",
                "Complete Order 2" : "la commande",
                "FREE SHIPPING ON ORDERS OVER $99." : "LIVRAISON GRATUITE SUR LES COMMANDES DE + DE 99 $",
                "Select Your Ball" : "CHOISIR UNE BALLE",
                "Filter by Brand" : "Filtrer par marque",
                "SHOW ALL" : "TOUT AFFICHER",
                "Sort by Price": "Trier par prix",
                "High to Low": "ÉLEVÉ À BAS",
                "Low to High": "BAS À ÉLEVÉ",

                // Customization & Pricing Table
                "Customization Pricing" : "PRIX DE LA PERSONNALISATION",
                "(per dozen)" : "(par dz)",
                "CustomizationTableLeadin" : "Les logos ou textes personnalisés peuvent être affichés sur un côté ou sur deux côtés (notez que les balles seront placés de façon manuelle avant l'impression et que des déviations peuvent apparaitre). Commandez-vous plus de 120 dz? Vous pourriez profiter d'une personnalisation gratuite ou d'un prix spécial.",
                "Contact us" : "Contactez-nous",
                "for more information." : "pour plus de détails.",
                "Quantity" : "Quantité",
                "Quantity (p/dz)": "Quantité (p/dz)",
                "Front" : "AVANT",
                "Back" : "ARRIÈRE",
                "FRONT" : "AVANT",
                "BACK" : "ARRIÈRE",
                "Savings" : "ÉCONOMIES",
                "FREE" : "GRATIS",
                "100%" : "100 %",
                "50%" : "50 %",

                //Products
                "View Description" : "VOIR LA DESCRIPTION",
                "Customization" : "Personnalisation",
                "Customize" : "Personnaliser une balle",

                //Product Change Modal
                "SELECT A DIFFERENT BALL" : "CHOISIR UNE AUTRE BALLE",
                "Select Ball" : "Choisir une balle",

                //Step 2
                "CUSTOMIZE YOUR BALL" : "PERSONNALISER VOTRE BALLE",
                "Choose Customization" : "Choisir la personnalisation",
                "PAGE2-BODY-1" : "Personnalisez votre balle sur un ou deux côtés avec du texte ou des logos. Minimum de 12 dz.",
                "Add Text" : "Ajouter du texte",
                "TEXT-DESCRIPTION-1" : "Ajoutez jusqu'à trois lignes avec des couleurs variées.",
                "Add Logo" : "AJOUTEZ UN LOGO",
                "LOGO-DESCRIPTION-1" : "Fichiers requis: PDF vectoriel, EPS ou AI. Maximum de 6 couleurs.",
                "SAMPLE-TEXT-1" : "VOTRE",
                "SAMPLE-TEXT-2" : "TEXTE",
                "SAMPLE-TEXT-3" : "ICI!",
                "Select Colour" : "Choisir une couleur ",
                "Add Line 1" : "Ajouter ligne 1",
                "Add Line 2" : "Ajouter ligne 2",
                "Add Line 3" : "Ajouter ligne 3",
                "Continue" : "Continuer",
                "OPTIONAL – Customize the Back Side" : "OPTIONNEL – Personnaliser l'arrière",
                "OPTIONAL-DESCRIPTION-1" : "Ajouter une autre personnalisation sur l'autre côté de la balle. ",
                "OPTIONAL-DESCRIPTION-2" : "Note: Des frais supplémentaires s'appliquent. ",
                "Same As Front" : "MÊME CHOSE QUE SUR L'AVANT",
                "No further customizations required" : "Aucune personnalisation supplémentaire requise",
                "Adjust Logo" : "Ajustez le logo",
                "FIT-DESCRIPTION-1" : "Ajustez le logo de sorte qu'il rentre dans le cercle. ",
                "VERIFY-OWNERSHIP-1" : "Je certifie que les droits d'auteur de cette image m'appartiennent et/ou j'ai la permission d'utiliser cette image. ",
                "COLOR-VERIFY-1" : "In the case that the provided logo does not contain PMS colors, I agree to allow the production team to select the closest appropriate colour match. [FR]",
                "Select Logo File" : "Choisir le fichier du logo",
                "VECTOR-SELECT-DESCRIPTION" : "Sélectionnez le logo que vous souhaitez utilisers. Il est préférable d'utiliser un format Vectoriel (AI, PDF, SVG ou EPS). Les fichiers soumis sous un autre format seront admissibles à un frais de numérisation de 65$. Besoin d'aide? Écrivez-nous à <a href='mailto:gtpromotions@golftown.com' style='color:var(--green)'>gtpromotions@golftown.com</a>.",
                "Production Notes:": "Notes de production et couleurs Pantone :",
                "PantoneWarning": "Veuillez spécifier les couleurs Pantone de votre logo et inscrivez toute note de production ici. Veuillez prendre note que les commandes sans couleurs Pantone spécifiées pourraient être soumises à certains délais.",
                "VECTOR-EXPLANATION-HEAD": "Qu'est-ce qu'un fichier vectoriel, et pourquoi en ai-je besoin?",
                "VECTOR-EXPLANATION-BODY": "Un fichier vectoriel reproduit parfaitement l'image, peu importe l'échelle ou la taille, ce qui assure une qualité supérieure. Les détails des logos bitmap/matriciels (PNG, JPG, etc.) peuvent avoir une apparence irrégulière ou disparaître complètement.",
                "Vector": "Vecteur",
                "Bitmap": "Bitmap",
                "PANTONE MODAL" : "<h2>COULEURS PANTONE</h2><p>Tous les couleurs de votre logo doivent adopter les couleurs Pantone afin que nous puissions imprimer votre logo avec les couleurs adéquates. Assurez-vous de nous fournir vos couleurs Pantone pour éviter des délais. Besoin d'aide? Écrivez-nous à <a href='mailto:gtpromotions@golftown.com' style='color:green;'>gtpromotions@golftown.com</a></p>",

                //Summary View
                "COMPLETE YOUR ORDER" : "COMPLÉTEZ VOTRE COMMANDE",
                "SELECT QUANTITY" : "CHOSIR LA QUANTITÉ",
                "VIEW BACK" : "VOIR L'ARRIÈRE",
                "VIEW FRONT" : "VOIR L'AVANT",
                "Illustration used for example purposes only. Actual production may vary." : "Illustration utilisée en guise d'exemple. Les résultats peuvent varier.",
                "dozen" : "dz",
                "Dozen" : "dz.",
                "ITEM" : "ARTICLE",
                "COST" : "COÛT",
                "Price does not include shipping & applicable taxes." : "Le prix exclut les frais de livraison et les taxes applicables",
                "TOTAL" : "TOTAL",
                "SECURE CHECKOUT" : "PAIEMENT SÉCURISÉ",
                "FRONT CUSTOMIZATION" : "PERSONNALISATION DE L'AVANT",
                "BACK CUSTOMIZATION" : "PERSONNALISATION DE L'ARRIÈRE",
                "Front Customization" : "Personnalisation de l'avant",
                "Back Customization" : "Personnalisation de l'arrière",
                "TEXT" : "TEXTE",
                "LOGO" : "LOGO",
                "dozen balls" : "dz balles",

                //Customization Boxes
                "Cancel" : "Annuler",
                "Change" : "CHANGER",
                "DELETE" : "EFFACER",
                "Add Customization" : "PERSONNALISER",
                "NO CURRENT CUSTOMIZATIONS" : "PAS DE PERSONNALISATION",

                //Checkout
                "CHECKOUT" : "PAIEMENT",
                "STEP 1 - Shipping" : "ÉTAPE 1 - Expédition",
                "step 1:" : "ÉTAPE 1 :",
                "Shipping" : "Expédition",
                "STEP 2 - Billing" : "ÉTAPE 2 - Facturation",
                "step 2:" : "ÉTAPE 2 :",
                "Billing" : "Facturation",
                "STEP 3 - Place Order" : "ÉTAPE 3 - Placement de la commande",
                "step 3:" : "ÉTAPE 3 :",
                "Place Order" : "Placement de la commande",
                "Enter Shipping Address" : "Entrez votre adresse",
                "We only ship within Canada" : "Expédition offerte uniquement au Canada",
                "FIRST NAME" : "PRÉNOM",
                "LAST NAME" : "NOM",
                "ADDRESS LINE 1" : "ADRESSE 1",
                "ADDRESS LINE 2" : "ADRESSE 2",
                "CITY" : "VILLE",
                "POSTAL CODE" : "CODE POSTALE",
                "Example: 1A23BC" : "Exemple: 1A23BC",
                "COUNTRY" : "PAYS",
                "PROVINCE" : "PROVINCE",
                "PHONE" : "TÉLÉPHONE",
                "EMAIL" : "COURRIEL",
                "USE THIS ADDRESS FOR BILLING" : "UTILISER CETTE ADRESSE POUR LA FACTURATION",
                "Company Name" : "Nom de l'entreprise",

                //Billing & Credit
                "Order Summary" : "Résumé de la commande",
                "Edit" : "Modifier",
                "Enter Billing Address" : "Entrez l'adresse de facturation",
                "Enter Credit Card Information" : "Entrez votre carte de crédit",
                "NAME ON CARD" : "NOM SUR LA CARTE",
                "TYPE" : "TYPE",
                "NUMBER" : "NUMÉRO",
                "EXPIRATION MONTH" : "DATE D'EXPIRATION",
                "EXPIRATION YEAR" : "ANNÉE D'EXPIRATION",
                "Expiration and colon" : "Expiration :",
                "SECURITY CODE" : "CODE DE SÉCURITÉ",
                "What is this?" : "Qu'est-ce que c'est?",
                "PLACE ORDER" : "PLACER LA COMMANDE",
                "THANK YOU!" : "MERCI!",
                "Subtotal" : "Sous-total",
                "Shipping" : "Expédition",
                "Sales Tax" : "Taxes",
                "Order Total" : "Total",
                "Billing Information" : "Renseignements de facturation",
                "Printable Summary" : "Sommaire à imprimer",
                "Print" : "Imprimer",
                "BUILD SUMMARY": "SOMMAIRE DE LA PERSONNALISATION",
                "Start Over": "Recommencer",

                //Thank You
                "Your Order Has Been Placed" : "Votre commande a été placée.",
                "THANK-TOY-MESSAGE-1" : "Merci! Votre commande a été reçue. Nous vous avons envoyé votre facture par courriel. La production et la livraison prennent généralement 7 à 10 jours ouvrables. Nous vous enverrons un courriel dès que votre commande sera expédiée. Pour toute question, veuillez écrire à gtpromotions@golftown.com. Veuillez imprimer cette page en guise de référence. ",
                "Print this page" : "Imprimer cette page",
                "CLOSE & RETURN TO GOLF TOWN" : "FERMER ET RETOURNER À GOLF TOWN ",

                //Re-Order
                "REPEAT-ORDER-HEAD": "Souhaitez-vous passer une autre commande avec des balles différentes?",
                "REPEAT-ORDER-BODY": "Utilisez le bouton 'Commander Plus' pour retourner à la page du sommaire avec toutes vos personnalisations déjà chargées, où vous pourrez choisir une autre balle et compléter une autre commande.",
                "REPEAT-ORDER-BUTTON": "COMMANDER PLUS",

                //Errors
                "OOPS, SOMETHING WENT WRONG": "OOPS, QUELQUE CHOSE N'A PAS FONCTIONNÉ",
                "BILLING DATA NOT ACCEPTED" : "BILLING DATA NOT ACCEPTED [FR]",
                "Your order could not be created. Please try again later." : "Your order could not be created. Please try again later. [FR]",
                "We weren't able to complete your transaction." : "Nous n'avons pas été enmesure de compléter votre transaction.",
                "bambora preauth failed" : "Paiement refusé. Veuillez prendre contact avec votre compagnie de carte de crédit.",
                "cc number is invalid" : "L'information de paiement inscrite n'est pas valdie. Veuillez réinscrire votre information de paiement.",
                "File must be a vector file in AI, PDF, SVG or EPS format." : "LE FORMAT DE FICIHER NE PEUT ÊTRE PRIS EN CHARGE. Si vous avez besoin d'aide, veuillez nous contacter à gtpromotions@golftown.com pour plus de renseignements.",
                "cc expiry date invalid" : "L'information de paiement inscrite n'est pas valdie. Veuillez réinscrire votre information de paiement.",
                "Minimum quantity 12 dozen." : "Minimum de 12 dz.",

                //Months
                "January" : "janvier",
                "February" : "février",
                "March" : "mars",
                "April" : "avril",
                "May" : "mai",
                "June" : "juin",
                "July" : "juillet",
                "August" : "août",
                "September" : "septembre",
                "October" : "octobre",
                "November" : "novembre",
                "December" : "décembre",

                //Provinces
                "Alberta" : "Alberta",
                "British Columbia" : "Colombie-Britannique",
                "Manitoba" : "Manitoba",
                "New Brunswick" : "Nouveau-Brunswick",
                "Newfoundland and Labrador" : "Terre-Neuve-et-Labrador",
                "Northwest Territories" : "Territoires du Nord-Ouest",
                "Nova Scotia" : "Nouvelle-Écosse",
                "Nunavut" : "Nunavut",
                "Ontario" : "Ontario",
                "Prince Edward Island" : "Île-du-Prince-Édouard",
                "Quebec" : "Québec",
                "Saskatchewan" : "Saskatchewan",
                "Yukon" : "Yukon",

                //Launch Page
                "CUSTOMIZED GOLF BALLS" : "BALLES PERSONNALISÉES",
                "Add your logo, tagline or text to your golf balls for a personal touch!" : "Ajoutez votre logo, slogan ou texte à vos balles pour une touche personnalisée!",
                "Customize your golf balls for any occasion!" : "Personnalisez vos balles pour toutes les occasions!",
                "Customize with your logo or with text" : "Ajoutez-y votre logo ou du texte",
                "Minimum 12 dozen per order" : "Minimum de 12 douzaines par commande",
                "No set up fees" : "Aucuns frais de mise en train",
                "Single or double sided customization options" : "Options à un ou deux pôle",
                "Free shipping on your order*" : "Livraison gratuite sur votre commande*",
                "START CUSTOMIZING NOW!" : "DÉBUTEZ VOTRE PERSONNALISATION!",
                "LAUNCH SHIPPING LEGAL" : "* La livraison gratuite s'applique uniquement à la livraison terrestre et ne comprend pas les territoires qui suivent: TN, YT et NU. Nous ne pouvons livrer à une boîte postale.",
                "CUSTOM OPTIONS" : "OPTIONS DE PERSONNALISATION",
                "Choose your ball, colour, text or logo to make a customized golf ball for your tournament, gifting or other occasion." : "Choisissez votre balle, la couleur, le texte ou logo et créez votre balle personnalisée pour un tournoi, un cadeau ou toute autre occasion!",
                "PERSONALIZED TEXT" : "TEXTE PERSONNALISÉ",
                "Make a statement with your company tagline, corporate hashtag, best golf pun or heartfelt message." : "Faites tout un impact avec votre slogan de compagnie, votre mot-clid, ou un message significatif.",
                "Order Now" : "Commandez Maintenant",
                "LOGO\'D GOLF BALLS" : "BALLES DE GOLF AVEC LOGO",
                "Want to provide cool swag for customers, partners or employees? Upload and print your logo on your favourite golf balls." : "Vous souhaitez offrir quelque chose de spécial à vos clients, partenaires ou employés? Téléversez et imprimez votre logo sur vos balles de golf favorites!",
                "NEED HELP? WE'RE HERE TO ASSIST!" : "BESOIN D'AIDE? NOUS SOMMES LÀ!",
                "LAUNCH ASSISTANCE TEXT" : "Avez-vous une grande commande à passer pour votre compagnie ou une demande spéciale? Écrivez-nous à <a href='mailto:gtpromotions@golftown.com'>gtpromotions@golftown.com</a> un membre de notre équipe prendra contact avec vous dans un délai d'un à deux jours ouvrables.",
                "LAUNCH FURTHER ASSISTANCE" : "Souhaitez-vous offrir des cadeaux personnalisés? Notre équipe des ventes corporatives a un vaste choix de produits personnalisables pour mettre votre marque en valeur!",
                "LEARN MORE" : "PLUS D'INFO",







            }
        }
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ",",
        format: (value, format, lng) => {
          // ...
          if (format === "date") {
            return new Intl.DateTimeFormat(lng).format(value);
          }
          if (format === "currency") {
            return new Intl.NumberFormat(lng, {
              style: "currency",
              currency: "CAD",
            }).format(value);
          }
        },
    },


    react: {
        //wait: true
        useSuspense: true,
    }
})

export default i18n;
