// Product reducer

import { LOAD_SKUS_TO_STATE } from './product.types'

const INITIAL_STATE = {

};

const productReducer = (state = INITIAL_STATE, action) => {

    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {

      case LOAD_SKUS_TO_STATE:
          return { ...updatedState, 
            balls: action.payload.SKUS,
            colors: action.payload.Colors  };
      break;

      default: return state;
      
    }

};

export default productReducer;