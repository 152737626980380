import { useSelector, useDispatch } from "react-redux"
import { setAuthenticated, setJWT } from '../../redux/App/app.actions'
import './Account.scss'

function Account(props) {

	const adminGroupIndex = useSelector(state => state.app.adminGroup)

	const dispatch = useDispatch()

	const adminGroups = {
		0: 'Admin',
		1: 'Golf Town Promotions',
		2: 'Production Partner'
	}

	const handleLogout = () => {
		dispatch(setAuthenticated(false, "", ""))
	}



	return (<div className='account-controller'>
			<p>{adminGroups[adminGroupIndex]}&nbsp;&nbsp;|&nbsp;&nbsp;<a onClick={()=>handleLogout()}>Logout</a></p>
		</div>
	)

}

export default Account
