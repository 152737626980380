// THIS IS THE DYNAMIC ORDER SUMMARY DURING CHECKOUT

import './OrderSummary.scss';
import { useTranslation } from 'react-i18next'
import { currencyFormatter } from '../helpers/currencyFormatter'
import { connect, useDispatch   } from "react-redux"
import { orderLineItems } from '../helpers/orderLineItems'

const mapStateToProps = (state) => ({
  currentItem: state.order.product,
  currentOrder: state.order,
  shipping: state.shipping,
})


function OrderSummary(props) {

    const { 
    showExtra, 
    hideEdit,
    subStepToShow,
    setSubStepToShow
    } = props

    const currentItem = props.currentItem
    const currentOrder = props.currentOrder
    const currentShipping = props.shipping

    const orderLineItemsObj = orderLineItems(currentItem, currentOrder, currentShipping)

    const { t, i18n } = useTranslation()
    const curLanguage = i18n.language.toUpperCase();

    return (
        <div className='order-summary'>
            <div className={`align-items-center d-flex justify-content-between ${hideEdit ? 'd-none' : ''}`}>
                <h3>
                    {t('Order Summary')}{currentOrder.BamboraID ? ` - #${currentOrder.BamboraID}`:null}
                </h3>
                    <button className="edit text-green text-decoration-underline" onClick={()=>{setSubStepToShow('COMPLETE-ORDER')}}>
                        {t('Edit')}
                    </button>

            </div>
            <div className="gray-box">
                <table className="total-price-table">
                    <tbody>
                        <tr>
                          <td>
                            <span style={{ textTransform: 'uppercase' }}>{currentItem.BrandName} {currentItem[`Name${curLanguage}`]}</span> – {currentOrder.Quantity} {t('dozen balls')}
                          </td>
                          <td>
                            {currencyFormatter(i18n).format( orderLineItemsObj.Balls.SubTotal )}
                          </td>
                        </tr>
                        { currentOrder.Front.Type != 'NONE' ?
                        <tr>
                          <td>
                            {t('Front Customization')} – <span style={{ textTransform: 'uppercase'}}>{currentOrder.Front.Type}</span>
                          </td>
                          <td>
                            {currencyFormatter(i18n).format( orderLineItemsObj.Front.SubTotal )}
                          </td>
                        </tr> : null
                        }
                        { currentOrder.Back.Type != 'NONE' ?
                        <tr>
                          <td>
                            {t('Back Customization')} – <span style={{ textTransform: 'uppercase'}}>{currentOrder.Back.Type}</span>
                          </td>
                          <td>
                            {currencyFormatter(i18n).format( orderLineItemsObj.Back.SubTotal )}
                          </td>
                        </tr> : null
                        }
                        { orderLineItemsObj.DigitizationFee > 0 ?
                          <tr>
                            <td>
                              {t('Digitization Fee')}
                            </td>
                            <td>
                              {currencyFormatter(i18n).format( orderLineItemsObj.DigitizationFee )}
                            </td>
                          </tr> 
                        : null }
                        <tr className="subTotal">
                            <td className="text-end">
                                <strong>
                                    {t('Subtotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </strong>
                            </td>
                            <td className='text-bold'>
                                {currencyFormatter(i18n).format( orderLineItemsObj.BeforeShippingAndTaxes )}
                            </td>
                        </tr>
                        {subStepToShow === "CHECKOUT-SHIPPING" ? 
                            <tr className="disclaimer">
                                <td className='text-start' colSpan="2">
                                    {t('Price does not include shipping & applicable taxes.')}
                                </td>
                            </tr> 
                        : null }
                        { subStepToShow === "CHECKOUT-BILLING" || subStepToShow === "CHECKOUT-PLACEORDER" || subStepToShow === "CHECKOUT-BILLING-SAME-ADDRESS" ? <>

                                <tr>
                                    <td className='text-end'>
                                        {t('Shipping')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </td>
                                    <td>
                                        {currencyFormatter(i18n).format( orderLineItemsObj.Shipping )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-end'>
                                        {t('Sales Tax')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </td>
                                    <td>
                                        {currencyFormatter(i18n).format( orderLineItemsObj.Taxes )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-bold text-end pt-2'>
                                        {t('Order Total')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </td>
                                    <td className='text-bold pt-2'>
                                        {currencyFormatter(i18n).format( orderLineItemsObj.Total )}
                                    </td>
                                </tr>
                            </> : ''
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(OrderSummary)